import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import uniqueId from 'lodash.uniqueid'
import { Select, ToggleGroup, Tab } from '@framework'
import getByPath from 'lodash.get'
import './Tabular.scss'

export class Tabular extends Component {

  constructor(props) {
    super(props);

    const { dynamicColumns } = props
    this.state = {
      selectedField: (dynamicColumns && dynamicColumns.length > 0) ? dynamicColumns[0].accessor : null
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if(nextState.selectedField !== nextProps.dynamicColumns[0].accessor){
      this.setState({ selectedField: nextProps.dynamicColumns[0].accessor})
    }
   
  }

  changeToggleField (data) {
    this.setState({
      selectedField: data
    })
  }

  changeSelectField (event) {
    this.setState({
      selectedField: event.target.value
    })
  }

  handleRow(row){
    console.log(`click at row ${JSON.stringify(row)}`)
  }

  renderToggleGroupOptions () {
    const { dynamicColumns } = this.props
    return dynamicColumns.map(col => <Tab key={col.accessor} value={col.accessor}>{col.Header}</Tab>)
  }

  renderDropdownOptions () {
    const { dynamicColumns } = this.props
    return dynamicColumns.map(col => <option key={col.accessor} value={col.accessor}>{col.Header}</option>)
  }

  renderSelector() {
    const { selector } = this.props
    switch (selector) {
      case 'toggleGroup':
        return (
          <ToggleGroup label='Display by' onChange={this.changeToggleField.bind(this)} selected={this.state.selectedField}>
            {this.renderToggleGroupOptions()}
          </ToggleGroup>
        )
      case 'dropdown':
      default:
        return (
          <Select label='Display by' onChange={this.changeSelectField.bind(this)} value={this.state.selectedField}>
            {this.renderDropdownOptions()}
          </Select>
        )
    }
  }

  renderRows () {
    const { staticColumns, route, data, bold } = this.props
    const { selectedField } = this.state
    if (route) {
      const toReplace = route.match(/({{)(\w|\.)+(?=}})/g)
      return data.map(row => {
        const path = toReplace.reduce(
          (res, attr) => res.replace(new RegExp(`({{${attr.replace('{{', '')}}})`, 'g'), getByPath(row, attr)),
          route
        );
          return <Link to={path} className='row' key={uniqueId()}>
            <div className="static-cell">
              <p className='header'>{getByPath(row, staticColumns[0].headerAccessor)}</p>
              <p className='subheader'>{getByPath(row, staticColumns[0].subheaderAccessor)}</p>
            </div>
            <div className="dynamic-cell">
              {getByPath(row, selectedField)}
            </div>
            <div className="arrow-sign">&gt;</div>
          </Link>
      })
    }
    return data.map(row => {
      const { triggerRotate = false } = row
        return <div className={triggerRotate ? 'row rotate': 'row'} key={uniqueId()} onClick={() => this.handleRow(row)}>
          <div className="static-cell">
            {
              staticColumns[0].Cell
                ? <div>{staticColumns[0].Cell({
                  headerValue: getByPath(row, staticColumns[0].headerAccessor),
                  subheaderValue: getByPath(row, staticColumns[0].subheaderAccessor),
                  value: row
                })}</div>
                : [
                  <TH1>{getByPath(row, staticColumns[0].headerAccessor)}</TH1>,
                  <TH2>{getByPath(row, staticColumns[0].subheaderAccessor)}</TH2>
                ]
            }
            
          </div>
          <TD bold={bold}>
            {getByPath(row, selectedField)}
          </TD>
        </div>
    })

  }

  render () {
    const { branded, data, className = '', dynamicColumns } = this.props
    if (!data) {
      return (
        <div className='af-tabular --no-data'>No data available</div>
      )
    }

    return (
      <div className={classNames(
        'af-tabular',
        { '--branded': branded },
        ...className.split(' ')
      )}>
        {(dynamicColumns && dynamicColumns.length > 1) && this.renderSelector()}
        <div className='list-container'>
          {this.renderRows()}
        </div>
      </div>
    )
  }
}

export const TH1 = (props) => {
  return (
    <p className='header'>
      {props.children}
    </p>
  )
}

export const TH2 = (props) => {
  return (
    <p className='subheader'>
      {props.children}
    </p>
  )
}

export const TD = (props) => {
  const { bold } = props
  return (
    <div className={`dynamic-cell ${bold ? '--bold' : ''}`}>
      {props.children}
    </div>
  )
}
