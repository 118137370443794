import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Table, Submit, Form, Input  } from '@framework'
import { getSliceData } from '@helpers/table-data-manipulator'
import moment from 'moment'
import { listApprovedCashDepositRequests } from '@store/actions/transaction.action'

import Viewer from 'react-viewer';
import 'react-viewer/dist/index.css';

class ApprovedCashDepositRequestsTable extends Component {

  constructor(props){
    super(props)
    this.state = {
      startDate: moment(new Date()).startOf('day').toDate(),
      endDate: moment(new Date()).endOf('day').toDate(),
      imgVisibleList: { }
    }
  }

  columns () {
    return [
      {
        Header: "Deposit Info",
        columns: [
          {
            Header: "Reference ID",
            accessor: "referenceId" 
          },
          {
            Header: "Deposit Bank",
            accessor: "bank"
          },
          {
            Header: "Deposit Date",
            accessor: "depositedDate",
            Cell: row => (
              <div>{moment(new Date(row.value)).format('DD/MM/YYYY h:mm:ss a')}</div>
            )
          },
          {
            Header: "Image",
            accessor: "imageLink",
            Cell: row => (
              <div>
                  <img width={100} height={100} mode='fit' src={row.value} alt="receipt"
                  onClick={() => { 
                    this.setState({ 
                      imgVisibleList: {
                        ...this.state.imgVisibleList,
                        [row.value]: true 
                      }
                    });
                  }}/>
              
                <Viewer
                  visible={this.state.imgVisibleList[row.value]}
                  noToolbar
                  onClose={() => {
                    this.setState({ 
                      imgVisibleList: {
                        ...this.state.imgVisibleList,
                        [row.value]: false 
                      }
                    });
                  }}
                  images={[{src: row.value, alt: 'receipt'}]}
                />
              </div>
            )
          },

        ]
      },
      {
        Header: "Amount",
        columns: [
          {
            Header: "Amount",
            accessor: "score"
          },
          {
            Header: "Bonus",
            accessor: "bonus"
          },
          {
            Header: "Limit",
            accessor: "withdrawalLimit"
          }
        ]
      },
      {
        Header: "Admin",
        accessor: "adminUsername"
      }
    ]
  }

  handlePageChange (newPage) {
    const { startDate, endDate } = this.props
    this.props.listApprovedCashDepositRequests({ requestPage: newPage, startDate, endDate })
  }

  handleInputChange (state, event) {
    this.setState({ [state]: event.target.value})
  }

  listApprovedCashDepositRequests() {
    const { startDate, endDate } = this.state;
    this.props.listApprovedCashDepositRequests({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    })
  }

  render () {
    const { data, currentPage, pageSize, isLastPage } = this.props
    return (
      <div>
        <Form onSubmit={this.listApprovedCashDepositRequests.bind(this)}>
            <Input 
              label='Start Date'
              type='datepicker'
              value={this.state.startDate}
              onChange={this.handleInputChange.bind(this, 'startDate')}/>

            <Input 
              label='End Date'
              type='datepicker'
              value={this.state.endDate}
              onChange={this.handleInputChange.bind(this, 'endDate')}/>
            <Submit color='primary'> Submit </Submit>
        </Form>
        <Table data={data}
          centeredContent
          disableNextButton={isLastPage}
          currentPage={currentPage}
          defaultPageSize={pageSize}
          showPageSizeOptions={false}
          minRows={pageSize}
          onPageChange={(newPage) => this.handlePageChange(newPage)}
          columns={this.columns()} />
      </div>
    )
  }
}

export default connect(
  ({ transaction:
    { approvedCashDepositRequests: {
      data, currentPage, pageSize, isLastPage, startDate, endDate
    } }
  }) => ({
    currentPage,
    pageSize,
    isLastPage,
    startDate,
    endDate,
    data: getSliceData({ data, currentPage, pageSize })
  }), 
  dispatch => bindActionCreators({
    listApprovedCashDepositRequests
  }, dispatch)
)(ApprovedCashDepositRequestsTable)
