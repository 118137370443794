import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Helmet } from 'react-helmet'

import { login } from '@store/actions/auth.action'
import { Page, Form, Submit, H1, P, Input } from '@framework'

import './Login.scss'

class LoginPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      username: '',
      password: ''
    }
  }

  handleInputChange (state, event) {
    this.setState({ [state]: event.target.value })
  }

  render () {

    return (
      <div className='page-login'>
        <Helmet title='OPS: Register / Login' />
        <header className='header'>
          <h1>Welcome to OPS</h1>
        </header>
        <Page paperWhite fluid>
          <H1>Register / Login</H1>
          <P>Howdy! Please key in your phone number to register / login</P>
          <Form onSubmit={() => this.props.login(this.state)}>
            <Input label='Username'
              onChange={this.handleInputChange.bind(this, 'username')} />
            <Input label='Password'
              type='password'
              onChange={this.handleInputChange.bind(this, 'password')} />
            <Submit color='primary'>
              Login
            </Submit>
          </Form>
        </Page>
      </div>
    )
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ login }, dispatch)
}

export default connect(null, mapDispatchToProps)(LoginPage)
