import React, { Component } from 'react'
import { Transition } from 'react-transition-group'
import { Link } from 'react-router-dom'

import './MenuBar.scss'

const sidebarTransition = {
  exited: { transform: 'translateX(-88vw)' },
  exiting: { transform: 'translateX(-88vw)' },
  entering: { transform: 'translateX(-88vw)' },
  entered: { transform: 'translateX(0)' }
}

const overlayTransition = {
  exited: { opacity: 0 },
  exiting: { opacity: 0 },
  entering: { opacity: 0 },
  entered: { opacity: 1 }
}

export class MenuBar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false
    }

    this.toggleMenu = this.toggleMenu.bind(this)
  }

  toggleMenu (boolVal) {
    this.setState({ isOpen: boolVal })
  }

  render () {
    const { header, navbar, children, showBadge, username } = this.props
    return (
      <div className='component-menu-bar'>
        <section className='menu-bar'>
          <button className='burger-icon' onClick={() => this.toggleMenu(true)}>
            <i className='fa fa-bars' aria-hidden='true'>

            {showBadge &&  <span className='badge abs'></span>}
            </i>
          </button>
          { navbar ? navbar : undefined }
        </section>
        <Transition in={this.state.isOpen} timeout={this.state.isOpen ? 100 : 400} appear unmountOnExit>
          {(state) => (
            <section className='the-menu-container'>
              <Transition in={this.state.isOpen} timeout={this.state.isOpen ? 100 : 400} appear unmountOnExit>
                {(state) => (
                  <aside style={{ ...sidebarTransition[state] }}>
                    <button className='close'
                      onClick={() => this.toggleMenu(false)}>x</button>
                    {
                      header
                        ? header
                        : <header>
                          <h1>Welcome {username}</h1>
                        </header>
                    }
                    <ul>
                      {children}
                    </ul>
                  </aside>
                )}
              </Transition>
              <Transition in={this.state.isOpen} timeout={100} appear unmountOnExit>
                {(state) => (
                  <div className='overlay'
                    onClick={() => this.toggleMenu(false)}
                    style={{ ...overlayTransition[state] }} />
                )}
              </Transition>
            </section>
          )}
        </Transition>
      </div>
    )
  }
}

export const MenuItem = (props) => {
  const { icon, to, children, count, ...attr } = props

  if (to) {
    return (
      <li {...attr}>
        <Link to={to} className='btn-menu'>
          <i className={`fas fa-${icon}`} aria-hidden='true' />
          <span>
            {children}
          </span>
          { (count && count > 0) ? <span className='badge'>{count}</span> : '' }

        </Link>
      </li>
    )
  }

  return (
    <li {...attr}>
      <div className='btn-menu'>
        <i className={`fas fa-${icon}`} aria-hidden='true' />
        <span>{children}</span>
      </div>
    </li>
  )
}
