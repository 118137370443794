import { authInterceptedAxios } from './interceptors'

export function smsAllPlayer ({ content }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/agent-sms/admin-send-all-players-sms',
    data: { content },
    loadKey: 'Please wait awhile sending sms to all players',
    isInterruptive: true,
    successPrompt: {
      header: 'Send Sms Success',
      message: 'Sending sms to player success.'
    }
  })
}

export function smsToPlayers ({ phoneNumber, phoneNumbers, content } = {}) {
  return authInterceptedAxios({
    method: 'post',
    url: '/messaging/admin-send-sms',
    loadKey: 'Please wait awhile sending sms',
    data: { phoneNumber, phoneNumbers, content },
    isInterruptive: true,
    successPrompt: {
      header: 'Send Sms Success',
      message: 'Sending sms to player success.'
    }
  })
}

export function publishAnnouncement ({ announcement } = {}) {
  return authInterceptedAxios({
    method: 'post',
    url: '/messaging/admin-publish-announcement',
    loadKey: 'Publishing announcement',
    data: { message: announcement },
    isInterruptive: true,
    successPrompt: {
      header: 'Publish announcement Success',
      message: 'published announcement.'
    }
  })
}

export function deleteAnnouncement () {
  return authInterceptedAxios({
    method: 'post',
    url: '/messaging/admin-delete-announcement',
    loadKey: 'Deleting announcement',
    isInterruptive: true,
    successPrompt: {
      header: 'Deleting announcement Success',
      message: 'deleted announcement.'
    }
  })
}

export function getAnnouncement () {
  return authInterceptedAxios({
    method: 'get',
    url: '/messaging/admin-get-announcement',
    loadKey: 'Fetching announcement',
    isInterruptive: true
  })
}

export function publishDownloadLink ({ downloadLink } = {}) {
  return authInterceptedAxios({
    method: 'post',
    url: '/messaging/admin-publish-downloadlink',
    loadKey: 'Publishing download link',
    data: { downloadLink },
    isInterruptive: true,
    successPrompt: {
      header: 'Publish download link Success',
      message: 'published download link.'
    }
  })
}

export function smsToBirthdatePlayers ({ content } = {}) {
  return authInterceptedAxios({
    method: 'post',
    url: '/agent-sms/admin-send-birthdate-players-sms',
    loadKey: 'Please wait awhile sending birthdate sms',
    data: { content },
    successPrompt: {
      header: 'Send Sms Success',
      message: 'Sending sms to birhtdate player success.'
    }
  })
}
