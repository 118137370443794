import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Page, Breadcrumb, Crumb } from '@framework'
import { ReferralReportTable } from '@components/Tables'

class ReferralOverviewPage extends Component {
  render () {
    return (
      <Page fluid paperWhite className='scope-user-overview'>
        <Helmet title='OPS Admin: User Overview' />
        <Breadcrumb>
          <Crumb to='/manage/workspace'>Home</Crumb>
          <Crumb>Referral Report</Crumb>
        </Breadcrumb>
        <ReferralReportTable/>
      </Page>
    )
  }
}

export default ReferralOverviewPage
