import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Page, Input, Breadcrumb, Crumb, Button, Submit } from '@framework'
import { sendAllPlayerSms, sendPlayerSms, sendBirthdatePlayerSms } from '@store/actions/messaging.action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as XLSX from 'xlsx';

class SmsPage extends Component {

  constructor(props){
    super(props)
    this.state = {
      phoneNumbers: '',
      content: '',
      isAuto: true 
    }
  }

  toggleManualAuto () {
    this.setState({
      isAuto: !this.state.isAuto
    })
  }

  sendSms () {
    const { isAuto, content, phoneNumbers: phoneNumberList }  = this.state;
    const { sendAllPlayerSms, sendPlayerSms } = this.props;
    if(isAuto){
      sendAllPlayerSms({ content });
    }else{
      if(this.fileInputTarget) {
        this.fileInputTarget.value = null;
      }
      sendPlayerSms({ phoneNumberList, content });
    }
  }

  handleInputChange (state, event) {
    this.setState({ [state]: event.target.value })
  }

  handleReadFile(event) {
    this.fileInputTarget = event.target;
    const file = event.target.files[0];


    const reader = new FileReader();

    reader.onload = (event) => {
      this.result = event.target.result;
      const wb = XLSX.read(this.result, {type:'binary'});
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const records = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const phoneNumbers = [...new Set([].concat(...records))].join(',');
      this.setState({
        ...this.state,
        phoneNumbers
      })
    }

    reader.readAsBinaryString(file);
  }

  renderToggleManualButton () {
    const AUTO  = this.state.isAuto;
    return (
      <div>
        {AUTO ? null :
          <div>
            <Input type='file'
              accept='.csv,.xlsx,.ods'
              onChange={this.handleReadFile.bind(this)}/>

            <Input label='Input Player phone Number'
              placeholder='eg. 012345678,012876543'
              onChange={this.handleInputChange.bind(this, 'phoneNumbers')}/>
          </div>
        }
        <Button
          color={AUTO ? 'danger' : 'primary'}
          onClick={this.toggleManualAuto.bind(this)}>
            {AUTO ? 'Manual' : 'Auto'}
        </Button>
      </div>
    )
  }

  renderSMSCharacterCount() {
    return `${this.state.content.length <= 160 ? 
      Math.ceil(this.state.content.length/160) :
      Math.ceil((this.state.content.length) /153)
    }`
  }

  render () {
    return (
      <Page fluid paperWhite className='scope-user-overview'>
        <Helmet title='OPS Admin: User Overview' />
        <Breadcrumb>
          <Crumb to='/manage/workspace'>Home</Crumb>
          <Crumb>Send Sms</Crumb>
        </Breadcrumb>
          {this.renderToggleManualButton()}
          <Input label={`Content Characters: ${this.state.content.length} (${this.renderSMSCharacterCount()} SMS)`} type='textarea' rows='4'
            onChange={this.handleInputChange.bind(this, 'content')} />
          <Submit color='primary' onClick={this.sendSms.bind(this)}>Send</Submit>
          <Submit color='success' onClick={() => this.props.sendBirthdatePlayerSms(this.state)}>Send Birthdate Player</Submit>
      </Page>
    )
  }
}

export default connect(null,
  dispatch => bindActionCreators({
    sendAllPlayerSms,
    sendPlayerSms,
    sendBirthdatePlayerSms
  }, dispatch)
)(SmsPage)