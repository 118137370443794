import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Page, Input, Breadcrumb, Crumb, Submit, Form, P } from '@framework'
import { changePassword } from '@store/actions/auth.action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const pStyle = {
  color: 'red',
  fontSize: '15px',
};

class ProfilePage extends Component {

  constructor(props){
    super(props)
    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      isConfirm: true
    }
  }

  handleInputChange (state, event) {
    this.setState({ [state]: event.target.value })
  }

  changePassword() {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    
    const isConfirm = newPassword === confirmPassword;
    this.setState({ isConfirm})
    if(isConfirm){
      this.props.changePassword({ oldPassword, newPassword });
      this.setState( {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      })
    } 

  }

  render () {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    return (
      <Page fluid paperWhite className='scope-user-overview'>
        <Helmet title='OPS Admin: User Overview' />
        <Breadcrumb>
          <Crumb to='/manage/workspace'>Home</Crumb>
          <Crumb>Profile</Crumb>
        </Breadcrumb>
        <Form onSubmit={this.changePassword.bind(this)}>
            <Input label='Old Password'
              value={oldPassword}
              type='password'
              onChange={this.handleInputChange.bind(this, 'oldPassword')} />
            <Input label='New Password'
              type='password'
              value={newPassword}
              onChange={this.handleInputChange.bind(this, 'newPassword')} />
             <Input label='Confirm Password'
              type='password'
              value={confirmPassword}
              onChange={this.handleInputChange.bind(this, 'confirmPassword')} />
              { !this.state.isConfirm ? <P style={pStyle}> New and Confirm Password not matching </P> : '' }  
            <Submit disabled={!oldPassword || !newPassword || !confirmPassword} color='primary'> Change Password</Submit>
        </Form>
      </Page>
    )
  }
}

export default connect( () => ({ }),
  dispatch => bindActionCreators({
    changePassword
  }, dispatch)
  )(ProfilePage)