import React, { Component } from 'react'
import { Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { agentWorkspaceRoutes, masterWorkSpaceRoutes } from '@routes'
import { renderRoutes } from '@helpers/routes'
import { MenuBar, MenuItem } from '@framework'
import AgentScoreBalance from '@components/AgentScoreBalance'
import { logoutAdmin } from '@store/actions/auth.action';

import './Workspace.scss'

class Workspace extends Component {

  render () {
    const { withdrawalRequestCount, cashDepositRequestCount, retryCount, username, isMaster } = this.props;
    const showBadge = withdrawalRequestCount > 0 || cashDepositRequestCount > 0 || retryCount > 0;
    const workspaceRoutes = isMaster ? masterWorkSpaceRoutes : agentWorkspaceRoutes
    return (
      <div className='scope-workspace'>
        <MenuBar navbar={<AgentScoreBalance/>} username={username} showBadge={showBadge}>
          <MenuItem to='/manage/workspace' icon='tachometer-alt'>Dashboard</MenuItem>
          <MenuItem to='/manage/player' icon='users'>Players</MenuItem>
          <MenuItem to='/manage/withdrawal' icon='hand-holding-usd'  count={withdrawalRequestCount}>Withdrawal</MenuItem>
          <MenuItem to='/manage/cash-deposit' icon='far fa-money-bill-alt' count={cashDepositRequestCount}>Cash Deposit</MenuItem>
          <MenuItem to='/manage/all-user-transactions' icon='fas fa-table'>All User Transactions</MenuItem>
          <MenuItem to='/manage/win-report' icon='fas fa-chart-bar'>Win Report</MenuItem>
          <MenuItem to='/manage/announcement' icon='fas fa-bullhorn'>Announcement</MenuItem>
          <MenuItem to='/manage/sms' icon='far fa-envelope'>Send Sms</MenuItem>
          <MenuItem to='/manage/retry' icon='fas fa-redo' count={retryCount}>Retry</MenuItem>
          {isMaster && <MenuItem to='/manage/profile' icon='fas fa-user'>Profile</MenuItem>}
          {isMaster && <MenuItem to='/manage/credential' icon='fas fa-key'>Crendential</MenuItem>}
          
          <MenuItem to='/manage/referral-report' icon='fa fa-file'>Referral Report</MenuItem>
          <MenuItem to='/manage/rebate-report' icon='fa fa-file'>Rebate Report</MenuItem>
          <MenuItem onClick={this.props.logoutAdmin.bind(this)} icon='fas fa-sign-out-alt'>Log Out</MenuItem>
        </MenuBar>
        
        <Switch>
          {renderRoutes(workspaceRoutes)}
        </Switch>
      </div>
    )
  }
}

export default connect(
  ({ 
    auth: {
      username,
      isMaster
    },
    transaction:{
      pendingCashDepositRequests,
      pendingWithdrawalRequests
    },
    retry: {
      retrySetScoreList
    }
  }) => ({
    cashDepositRequestCount: pendingCashDepositRequests.count,
    withdrawalRequestCount: pendingWithdrawalRequests.count,
    retryCount: retrySetScoreList.count,
    username,
    isMaster
  }),
  dispatch => bindActionCreators({
    logoutAdmin
  }, dispatch)
)(Workspace)