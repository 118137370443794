import React, { Component } from 'react'
import { Button, Image } from '@framework'

import './Carousel.scss'

export class Carousel extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 0
    }
  }

  onPrev () {
    const { children } = this.props
    const { currentPage } = this.state
    const IS_AT_FIRST_PAGE = currentPage === 0
    const LAST_PAGE = children.length - 1

    if (children.length) {
      if (IS_AT_FIRST_PAGE) {
        this.setState({ currentPage: LAST_PAGE })
      } else {
        this.setState({ currentPage: currentPage - 1 })
      }
    }
  }

  onNext () {
    const { children } = this.props
    const { currentPage } = this.state
    const IS_AT_LAST_PAGE = currentPage === children.length - 1
    const FIRST_PAGE = 0

    if (children.length) {
      if (IS_AT_LAST_PAGE) {
        this.setState({ currentPage: FIRST_PAGE })
      } else {
        this.setState({ currentPage: currentPage + 1 })
      }
    }
  }

  renderSinglePage () {
    const { children } = this.props
    const { currentPage } = this.state
    return  Array.isArray(children) ?
      children[currentPage] :
      children
  }

  renderControl () {
    const { children } = this.props
    const { currentPage } = this.state
    const childrenElement = children[currentPage];
    const gameNamePic = childrenElement ? childrenElement.props.game.gameNamePic: 'Game';

    return (
      <div className='__control'>
        <Button className='reverse plain'
          onClick={this.onPrev.bind(this)}>
          &#10154;
        </Button>
        <Image src={gameNamePic} />
        <Button className='plain'
          onClick={this.onNext.bind(this)}>
          &#10154;
        </Button>
      </div>
    )
  }

  render () {
    return (
      <div className='af-carousel'>
        {this.renderControl()}
        {this.renderSinglePage()}
      </div>
    )
  }
}
