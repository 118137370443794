import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import { history, store } from './store'

import './index.css'
import '@framework/reset.css'

import App from './App'
// Uncomment the following line for PWA
// import registerServiceWorker from './registerServiceWorker'

ReactDOM.render(
  <Provider store={store} >
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)
// registerServiceWorker()
