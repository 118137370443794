import React from 'react'
import ReactTable from 'react-table'
import classNames from 'classnames'

import { Button } from '@framework'
import './Table.scss'

export const Table = (props) => {
  const {
    onPageChange,
    currentPage,
    disableNextButton,
    disablePrevButton,
    showNavigation = true,
    centeredContent,
    onRefresh,
    ...rest
  } = props

  const handlePageChange = (modifier) => {
    onPageChange && onPageChange(currentPage + modifier)
  }

  const handleOnRefresh = () => {
    onRefresh && onRefresh();
  }

  return (

    <div className={classNames(
      'af-table',
      '--centered-content'
    )}>

      <div className='control-group'>
      {
        onRefresh && 
        <Button onClick={() => handleOnRefresh()} 
          color='primary' className='refresh-button' ><i className="fas fa-sync" />
        </Button>
      }
       </div>
      <ReactTable
        {...rest}
        showPagination={false} />
        {
          showNavigation &&
          <div className='pagination'>
            <Button onClick={() => handlePageChange(-1)}
              disabled={currentPage === 1 || disablePrevButton}>
              Previous
            </Button>
            <div>Page {currentPage}</div>
            <Button onClick={() => handlePageChange(1)}
              disabled={disableNextButton}>
              Next
            </Button>
          </div>
        }
      
    </div>
  )
}
