import * as paymentService from '../services/payment.api'
import * as agentService from '../services/agent.api'
import {
    INIT_BANK_BALANCE_REPORT,
    INIT_PRODUCT_BALANCE_REPORT,
    UPDATE_BANK_BALANCE,
    UPDATE_PRODUCT_BALANCE,
    INIT_REFERRAL_REPORT,
    PAGE_CHANGE_REFERRAL_REPORT,
    SET_DETAIL_PAGE_PLAYER_REFERRAL,
    INIT_PLAYER_REFERRAL_TRANSACTION_HISTORY,
    PAGE_CHANGE_REFERRAL_TRNASACTION_HISTORY,
    INIT_REBATE_REPORT,
    PAGE_CHANGE_REBATE_REPORT,
    SET_DETAIL_PAGE_PLAYER_REBATE,
    INIT_PLAYER_REBATE_TRANSACTION_HISTORY,
    PAGE_CHANGE_REBATE_TRNASACTION_HISTORY
} from '../reducers/report.reducer'

export function listBalanceReport({ banks, products, startDate, endDate } = {}) {
  return async (dispatch) => {

    const { data: bankData } = await paymentService.listBankTallyReport({ banks, startDate, endDate })
    const { data: productData } = await agentService.listProductTallyReport({ products, startDate, endDate })

    dispatch({
      type: INIT_BANK_BALANCE_REPORT,
      payload: {
        data: bankData
      }
    })
        
    dispatch({
      type: INIT_PRODUCT_BALANCE_REPORT,
      payload: {
        data: productData
      }
    })
  }
}

export function updateProductBalance ({ productCode, balance, remark }) {
  return async (dispatch) => {

    await agentService.updateProductBalance({ productCode, balance, remark})
    dispatch({ type: UPDATE_PRODUCT_BALANCE })
  }
}

export function updateBankBalance ({ bankCode, balance, remark }) {
  return async (dispatch, getState) => {

    await paymentService.updateBankBalance({ bankCode, balance, remark })
    dispatch({ type: UPDATE_BANK_BALANCE })
  }
}

export function listReferralReport({ startDate, endDate }) {
  return async (dispatch, getState) => {
    const { data: referralData } = await agentService.listReferralReport({ startDate, endDate })
    dispatch({
      type: INIT_REFERRAL_REPORT,
      payload: {
        data: referralData,
        startDate,
        endDate
      }
    })
  }
}

export function pageChangeReferralReport({ requestPage }) {
  return (dispatch, getState) => {
    dispatch({
      type: PAGE_CHANGE_REFERRAL_REPORT,
      payload: {
        currentPage: requestPage,
        lastUpdatedPage: requestPage,
      }
    })
  }
}

export function pageChangeReferralTransaction({ requestPage }) {
  return (dispatch, getState) => {
    dispatch({
      type: PAGE_CHANGE_REFERRAL_TRNASACTION_HISTORY,
      payload: {
        currentPage: requestPage,
        lastUpdatedPage: requestPage,
      }
    })
  }
}

export function viewSinglePlayerReferral ({ playerId }) {
  return (dispatch, getState) => {
    const { data } = getState().report.referralReport.list

    dispatch({
      type: SET_DETAIL_PAGE_PLAYER_REFERRAL,
      payload: {
        listDataIndex: data.findIndex(player => player.cognitoId === playerId)
      }
    })
  }
}

export function listReferralTransacitonsInfo({ txIds }) {
  return async (dispatch, getState) => {

    const { data: referralTransactions } = await agentService.listTransactionsInfo({ txIds })
    dispatch({
      type: INIT_PLAYER_REFERRAL_TRANSACTION_HISTORY,
      payload: {
        data: referralTransactions
      }
    })
  }
}


export function listRebateReport({ startDate, endDate }) {
  return async (dispatch, getState) => {
    const { data: referralData } = await agentService.listRebateReport({ startDate, endDate })
    dispatch({
      type: INIT_REBATE_REPORT,
      payload: {
        data: referralData,
        startDate,
        endDate
      }
    })
  }
}


export function pageChangeRebateReport({ requestPage }) {
  return (dispatch, getState) => {
    dispatch({
      type: PAGE_CHANGE_REBATE_REPORT,
      payload: {
        currentPage: requestPage,
        lastUpdatedPage: requestPage,
      }
    })
  }
}

export function pageChangeRebateTransaction({ requestPage }) {
  return (dispatch, getState) => {
    dispatch({
      type: PAGE_CHANGE_REBATE_TRNASACTION_HISTORY,
      payload: {
        currentPage: requestPage,
        lastUpdatedPage: requestPage,
      }
    })
  }
}

export function viewSinglePlayerRebate ({ playerId }) {
  return (dispatch, getState) => {
    const { data } = getState().report.rebateReport.list

    dispatch({
      type: SET_DETAIL_PAGE_PLAYER_REBATE,
      payload: {
        listDataIndex: data.findIndex(player => player.cognitoId === playerId)
      }
    })
  }
}

export function listRebateTransacitonsInfo({ txIds }) {
  return async (dispatch, getState) => {

    const { data: rebateTransactions } = await agentService.listTransactionsInfo({ txIds })
    dispatch({
      type: INIT_PLAYER_REBATE_TRANSACTION_HISTORY,
      payload: {
        data: rebateTransactions
      }
    })
  }
}