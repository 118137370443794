import * as devConfig from './dev.config'
import * as testConfig from './test.config'
import * as prodConfig from './prod.config'

let config
switch (process.env.NODE_ENV) {
  case 'production':
    config = prodConfig
    break
  case 'test':
    config = testConfig
    break
  case 'development':
  default:
    config = devConfig
}

export const API_BASEURL = config.API_BASEURL
export const SOCKET_BASEURL = config.SOCKET_BASEURL
export const CONTENTFUL_SPACEID = config.CONTENTFUL_SPACEID
export const CONTENTFUL_ACCESS_TOKEN = config.CONTENTFUL_ACCESS_TOKEN