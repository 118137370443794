import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Table, Submit, Form, Input, Flex, P } from '@framework'
import { getSliceData } from '@helpers/table-data-manipulator'
import moment from 'moment'
import { appendAllUserTransactionHistory, initAllUserTransaction } from '@store/actions/transaction.action'
import { maskPhoneNumber } from '@helpers/utils';

class AllUserTransactionTable extends Component {

  constructor(props){
    super(props)
    this.state = {
      startDate: moment(new Date()).startOf('day').toDate(),
      endDate: moment(new Date()).endOf('day').toDate(),
      filter: { }
    }
  }

  componentDidMount() {
    this.initAllUserTransaction();
  }

  columns () {
    const { isMaster } = this.props;
    return [
      {
        Header: 'Username',
        accessor: 'phoneNumber',
        Cell: row => ( <div>{isMaster ? row.value : maskPhoneNumber(row.value)}</div>)
      },
      {
        Header: 'Timestamp',
        accessor: 'createdAt',
        Cell: row => (<div>{moment(new Date(row.value)).format('DD/MM/YYYY h:mm:ss a')}</div>)
      },
      {
        Header: 'Remark',
        accessor: 'remark'
      },
      {
        Header: 'Method',
        accessor: 'method'
      },
      {
        Header: 'Score',
        accessor: 'score', 
      },
      {
        Header: 'Bonus',
        accessor: 'bonus'
      }
    ]
    }

  handlePageChange (newPage) {
    const { startDate, endDate } = this.props
    this.props.appendAllUserTransactionHistory({
      requestPage: newPage,
      startDate,
      endDate
    })
  }

  handleInputChange (state, event) {
    this.setState({ [state]: event.target.value })
  }

  handleFilterChange(state, event) {
    this.setState({
      ...this.state,
      filter: {
        ...this.state.filter,
        [state]: event.target.value
      }
    })
    
  }

  initAllUserTransaction() {
    const { startDate, endDate, filter } = this.state;

    this.props.initAllUserTransaction({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      filter
    })
  }

  appendAllUserTransactionHistory() {
    const { startDate, endDate } = this.state;
    this.props.appendAllUserTransactionHistory({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    })
  }

  getColor(remark, method) {
    let color = '';

    switch(remark) {
      case 'withdrawal':
        color = 'red';
        break;
      case 'deposit':
        color = 'green';
        break;
      case 'withdraw-bonus':
      case 'claim-bonus':
        color = '';
        break;
      default:
        break;
    }


    switch(method) {
      case 'admin-set-point':
      case 'admin-set-score':
        color = 'orange';
          break;

      default:
        break;
    }

    return color
  }

  getTrProps (state, rowInfo, instance) {
    if (rowInfo) {
      return {
        style: {
          background: this.getColor(rowInfo.row.remark, rowInfo.row.method),
          color: 'black'
        }
      }
    }
    return {};
  }

  render () {
    const { data, total, currentPage, pageSize, isLastPage } = this.props
    const { totalDeposit, totalWithdrawal, totalBonus } = total
    
    return (
      <div>
        <Flex>
          <Flex>
            <Form onSubmit={this.initAllUserTransaction.bind(this)}>
              <Flex row>
                <Flex column>
                  <Input 
                    label='Start Date'
                    type='datepicker'
                    value={this.state.startDate}
                    onChange={this.handleInputChange.bind(this, 'startDate')}/>

                  <Input 
                    label='End Date'
                    type='datepicker'
                    value={this.state.endDate}
                    onChange={this.handleInputChange.bind(this, 'endDate')}/>
                </Flex>

                 <Flex column>
                  <Input 
                    label='Remark'
                    value={this.state.filter.remark}
                    onChange={this.handleFilterChange.bind(this, 'remark')}/>

                  <Input 
                    label='Method'
                    value={this.state.filter.method}
                    onChange={this.handleFilterChange.bind(this, 'method')}/>
                </Flex>
                
              </Flex>
              <Submit color='primary'>Submit</Submit>
            </Form>
          </Flex>

          <Flex column>
            <P style={{ padding: '4px 8px', background: '#00ff00', borderRadius: '10px' }}>Total Deposit: {totalDeposit}</P>
            <P style={{ padding: '4px 8px', background: 'red', borderRadius: '10px' }}>Total Withdrawal: {totalWithdrawal}</P>
            <P style={{ padding: '4px 8px', background: 'orange', borderRadius: '10px' }}>Total Bonus: {totalBonus}</P>
          </Flex>
          
        </Flex>
        
        <Table data={data}
          disableNextButton={isLastPage}
          currentPage={currentPage}
          defaultPageSize={pageSize}
          showPageSizeOptions={false}
          minRows={pageSize}
          getTrProps={this.getTrProps.bind(this)}
          onRefresh={this.initAllUserTransaction.bind(this)}
          onPageChange={(newPage) => this.handlePageChange(newPage)}
          columns={this.columns()} />

      </div>
    )
  }
}

export default connect(
  ({
    auth: {
      isMaster
    },
    transaction:{ 
      allUserHistory: {
        data, total, currentPage, pageSize, isLastPage, startDate, endDate
      }
    }
  }) => ({
    currentPage,
    pageSize,
    isLastPage,
    startDate,
    endDate,
    data: getSliceData({ data, currentPage, pageSize }),
    total,
    isMaster
  }), 
  dispatch => bindActionCreators({
    appendAllUserTransactionHistory,
    initAllUserTransaction
  }, dispatch)
)(AllUserTransactionTable)
