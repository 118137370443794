import { INIT } from './app.reducer'
import { LOGOUT_ADMIN } from './auth.reducer'

export const INIT_BANK_BALANCE_REPORT = '[REPORT] Init Bank Balance Report'
export const INIT_PRODUCT_BALANCE_REPORT = '[REPORT] Init Product Balance Report'
export const UPDATE_BANK_BALANCE = '[REPORT] Update Bank Balance'
export const UPDATE_PRODUCT_BALANCE = '[REPORT] Update Product Balance'

export const INIT_REFERRAL_REPORT = '[REPORT] Init Referral Report'
export const PAGE_CHANGE_REFERRAL_REPORT = '[REPORT] Page Change Referral Report'

export const SET_DETAIL_PAGE_PLAYER_REFERRAL = '[REPORT] Set Detail Page Player Referral'
export const INIT_PLAYER_REFERRAL_TRANSACTION_HISTORY = '[REPORT] Init Player Referral Transaction History'
export const PAGE_CHANGE_REFERRAL_TRNASACTION_HISTORY = '[REPORT] Page Change Referral Transaction History'


export const INIT_REBATE_REPORT = '[REPORT] Init rebate Report'
export const PAGE_CHANGE_REBATE_REPORT = '[REPORT] Page Change rebate Report'

export const SET_DETAIL_PAGE_PLAYER_REBATE = '[REPORT] Set Detail Page Player rebate'
export const INIT_PLAYER_REBATE_TRANSACTION_HISTORY = '[REPORT] Init Player rebate Transaction History'
export const PAGE_CHANGE_REBATE_TRNASACTION_HISTORY = '[REPORT] Page Change rebate Transaction History'



const initialState = {
  bankReport: {
    data: [],
  },
  productReport: {
    data: [],
  },

  referralReport: {
    list : {
      currentPage: 1,
      pageSize: 10,
      lastUpdatedPage: 0,
      isLastPage: true,
      startDate: new Date(),
      endDate: new Date(),
      data: [],
      maxPage: 1,
    },

    view: {
      currentPage: 1,
      pageSize: 10,
      lastUpdatedPage: 0,
      isLastPage: true,
      listDataIndex: null,
      data: [],
      maxPage: 1,
    }
  },

  rebateReport: {
    list : {
      currentPage: 1,
      pageSize: 10,
      lastUpdatedPage: 0,
      isLastPage: true,
      startDate: new Date(),
      endDate: new Date(),
      data: [],
      maxPage: 1,
    },

    view: {
      currentPage: 1,
      pageSize: 10,
      lastUpdatedPage: 0,
      isLastPage: true,
      listDataIndex: null,
      data: [],
      maxPage: 1,
    }
  }

}

export default function (state = initialState, { payload = {}, type , report: init }) {
  const {
    data = [],
    startDate,
    endDate,
    lastUpdatedPage,
    currentPage,
    listDataIndex
  } = payload

  switch (type) {

    case INIT_BANK_BALANCE_REPORT: {
      const {
        data: initialData,
      } = initialState.bankReport

      return {
        ...state,
        bankReport: {
          ...initialState.bankReport,
          data: data ? data : initialData,
        }
      }
    }

    case INIT_PRODUCT_BALANCE_REPORT: {
      const {
        data: initialData,
      } = initialState.productReport

      return {
        ...state,
        productReport: {
          ...initialState.productReport,
          data: data ? data : initialData,
        }
      }
    }

    case INIT_REFERRAL_REPORT: {
      const {
        data: initialData,
        startDate: initialStartDate,
        endDate: initialEndDate,
        lastUpdatedPage: initialLastUpdatedPage,
        currentPage: initialCurrentPage,
        pageSize
      } = initialState.referralReport.list

      const maxPage = Math.ceil(data.length / pageSize);

      return {
        ...state,
        referralReport: {
          ...state.referralReport,
          list: {
            ...initialState.referralReport.list,
            data: data ? data : initialData,
            startDate: startDate ? startDate : initialStartDate,
            endDate: endDate ? endDate : initialEndDate,
            lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : initialLastUpdatedPage,
            currentPage: currentPage ? currentPage : initialCurrentPage,
            maxPage: maxPage,
            isLastPage: initialCurrentPage >= maxPage
          }
        }
      }
    }

    case PAGE_CHANGE_REFERRAL_REPORT: {

      const { maxPage } = state.referralReport.list;
      return {
        ...state,
        referralReport: {
          ...state.referralReport,
          list: {
            ...state.referralReport.list,
            lastUpdatedPage: lastUpdatedPage,
            currentPage: currentPage,
            isLastPage: currentPage >= maxPage
          }
        }
      }
    }

    case SET_DETAIL_PAGE_PLAYER_REFERRAL:

      return {
        ...state,
        referralReport: {
          ...state.referralReport,
          view: {
            ...state.referralReport.view,
            listDataIndex
          }}
      }

    case INIT_PLAYER_REFERRAL_TRANSACTION_HISTORY: {
      const {
        data: initialData,
        lastUpdatedPage: initialLastUpdatedPage,
        currentPage: initialCurrentPage,
        pageSize
      } = initialState.referralReport.view

      const maxPage = Math.ceil(data.length / pageSize);
      return {
        ...state,
        referralReport: {
          ...state.referralReport,
          view: {
            ...state.referralReport.view,
            data: data ? data : initialData,
            lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : initialLastUpdatedPage,
            currentPage: currentPage ? currentPage : initialCurrentPage,
            maxPage,
            isLastPage: initialCurrentPage >= maxPage
          }
        }
      }
    }

    case PAGE_CHANGE_REFERRAL_TRNASACTION_HISTORY: {
      const { maxPage } = state.referralReport.view;
      return {
        ...state,
        referralReport: {
          ...state.referralReport,
          view: {
            ...state.referralReport.view,
            lastUpdatedPage: lastUpdatedPage,
            currentPage: currentPage,
            isLastPage: currentPage >= maxPage
          }
        }
      }
    }

    case INIT_REBATE_REPORT: {
      const {
        data: initialData,
        startDate: initialStartDate,
        endDate: initialEndDate,
        lastUpdatedPage: initialLastUpdatedPage,
        currentPage: initialCurrentPage,
        pageSize
      } = initialState.rebateReport.list

      const maxPage = Math.ceil(data.length / pageSize);

      return {
        ...state,
        rebateReport: {
          ...state.rebateReport,
          list: {
            ...initialState.rebateReport.list,
            data: data ? data : initialData,
            startDate: startDate ? startDate : initialStartDate,
            endDate: endDate ? endDate : initialEndDate,
            lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : initialLastUpdatedPage,
            currentPage: currentPage ? currentPage : initialCurrentPage,
            maxPage: maxPage,
            isLastPage: initialCurrentPage >= maxPage
          }
        }
      }
    }

    case PAGE_CHANGE_REBATE_REPORT: {

      const { maxPage } = state.rebateReport.list;
      return {
        ...state,
        rebateReport: {
          ...state.rebateReport,
          list: {
            ...state.rebateReport.list,
            lastUpdatedPage: lastUpdatedPage,
            currentPage: currentPage,
            isLastPage: currentPage >= maxPage
          }
        }
      }
    }

    case SET_DETAIL_PAGE_PLAYER_REBATE:

      return {
        ...state,
        rebateReport: {
          ...state.rebateReport,
          view: {
            ...state.rebateReport.view,
            listDataIndex
          }}
      }

    case INIT_PLAYER_REBATE_TRANSACTION_HISTORY: {
      const {
        data: initialData,
        lastUpdatedPage: initialLastUpdatedPage,
        currentPage: initialCurrentPage,
        pageSize
      } = initialState.rebateReport.view

      const maxPage = Math.ceil(data.length / pageSize);
      return {
        ...state,
        rebateReport: {
          ...state.rebateReport,
          view: {
            ...state.rebateReport.view,
            data: data ? data : initialData,
            lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : initialLastUpdatedPage,
            currentPage: currentPage ? currentPage : initialCurrentPage,
            maxPage,
            isLastPage: initialCurrentPage >= maxPage
          }
        }
      }
    }

    case PAGE_CHANGE_REBATE_TRNASACTION_HISTORY: {
      const { maxPage } = state.rebateReport.view;
      return {
        ...state,
        rebateReport: {
          ...state.rebateReport,
          view: {
            ...state.rebateReport.view,
            lastUpdatedPage: lastUpdatedPage,
            currentPage: currentPage,
            isLastPage: currentPage >= maxPage
          }
        }
      }
    }

    case LOGOUT_ADMIN:
      return initialState

    case INIT: {
      return {
        ...state,
        ...init
      }
    }

    default:
      return state
  }
}