import { appInterceptedAxios, authInterceptedAxios } from './interceptors'
import Cookies from 'js-cookie'
import { ADMIN_ACCESS_TOKEN } from '@constants/cookies';

export function login ({ username, password }) {
  return appInterceptedAxios({
    method: 'post',
    url: '/admin/sign-in',
    data: { username, password },
    loadKey: 'Signing In',
    isInterruptive: true
  })
}

export function refreshToken () {
  return appInterceptedAxios({
    method: 'post',
    url: '/admin/refresh-token',
    loadKey: 'Refreshing Token',
    withCredentials: true
  })
}

export function changePassword ({ oldPassword, newPassword }) {

  const accessToken = Cookies.get(ADMIN_ACCESS_TOKEN);
  return authInterceptedAxios({
    method: 'post',
    url: '/admin/admin-change-password',
    data: { accessToken, oldPassword, newPassword },
    loadKey: 'Changing Password',
    isInterruptive: true,
    successPrompt: {
      header: 'Success',
      message: 'Change password successfully'
    }
  })
}

export function createAdmin ({ username, password }) {

  return authInterceptedAxios({
    method: 'post',
    url: '/admin/create-admin',
    data: { username, password },
    loadKey: 'Creating Admin',
    isInterruptive: true,
    successPrompt: {
      header: 'Success',
      message: 'Create admin account success'
    }
  })
}

export function deleteAdmin ({ username }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/admin/delete-admin',
    data: { username },
    loadKey: 'Deleting Admin',
    isInterruptive: true,
    successPrompt: {
      header: 'Success',
      message: 'Delete admin account success'
    }
  })
}

export function listAdmin ({ pageSize, nextKey }) {
  return authInterceptedAxios({
    method: 'get',
    url: '/admin/list-admin',
    loadKey: 'Listing Admin',
    params: {
      limit: pageSize,
      nextKey
    },
    isInterruptive: true,
  })
}

export function logoutAdmin () {
  return authInterceptedAxios({
    method: 'get',
    url: '/admin/logout',
    loadKey: 'Admin Sign out',
    isInterruptive: true,
    successPrompt: {
      header: 'Success',
      message: 'Log out success'
    }
  })
}

export function adminGetUserOtp ({ phoneNumber }) {
  return authInterceptedAxios({
    method: 'post',
    url: 'auth/admin-get-user-otp',
    data: { phoneNumber },
    isInterruptive: false
  })
}