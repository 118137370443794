import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import { Table, Button } from '@framework'
import { getSliceData } from '@helpers/table-data-manipulator'
import { maskPhoneNumber } from '@helpers/utils';
import { retrySetScore, refreshRetryList, listRetrySetScore, pageChangeRetry } from '@store/actions/retry.action'

class RetrySetScoreTable extends Component {

  componentDidMount () {
      this.props.listRetrySetScore()
  }

  columns () {

    const { retrySetScore, isMaster } = this.props;

    return [
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
        Cell: row => (
          <div>{isMaster ? row.value : maskPhoneNumber(row.value)}</div>
        )
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        Cell: row => (
          <div>{moment(new Date(row.value)).format('DD/MM/YYYY h:mm:ss a')}</div>
        )
      },
      {
        Header: "Amount",
        accessor: "score"
      },
      {
        Header: "Bonus",
        accessor: "bonus"
      },
      {
        Header: "Turn Over",
        accessor: "withdrawalLimit"
      },
      {
        Header: "Method",
        accessor: "method"
      },
      {
        Header: "Remark",
        accessor: "remark"
      },
      {
        id: 'retry',
        accessor: acc => ({
          id: acc.retryId,
          status: acc.status
        }),
        Cell: row => row.value.status === 'retry' ? (
          <div>
            <span>Retry</span>
            <i className="fas fa-check-circle" />
          </div>
        ): (
            <Button color='primary' onClick={() => retrySetScore({ retryId: row.value.id} )}>Retry</Button>
        )
      }
    ]
  }

  handlePageChange (newPage) {
    this.props.pageChangeRetry({ requestPage: newPage })
  }

  render () {
    const { data, currentPage, pageSize, isLastPage } = this.props

    return (
      <div>
        <Table data={data}
          centeredContent
          disableNextButton={isLastPage}
          currentPage={currentPage}
          defaultPageSize={pageSize}
          showPageSizeOptions={false}
          minRows={pageSize}
          onRefresh={this.props.refreshRetryList.bind(this)}
          onPageChange={(newPage) => this.handlePageChange(newPage)}
          columns={this.columns()} />
      </div>
    )
  }
}

export default connect(
  ({
    auth: {
      isMaster
    },
    retry:{
      retrySetScoreList: {
        data, currentPage, pageSize, isLastPage
      }
    }
  }) => ({
    isMaster,
    currentPage,
    pageSize,
    isLastPage,
    data: getSliceData({ data, currentPage, pageSize })
  }), 
  dispatch => bindActionCreators({
    retrySetScore,
    refreshRetryList,
    listRetrySetScore,
    pageChangeRetry
  }, dispatch)
)(RetrySetScoreTable)
