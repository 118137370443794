import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { H3, ModalHeader, ModalBody, ModalFooter, Submit, Button, Form, Input } from '@framework'
import { rejectCashDeposit } from '@store/actions/transaction.action'

class RejectCashDepositModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      reason: '',
      id: props.requestId
    }
  }

  handleInputChange (state, event) {
    this.setState({ [state]: event.target.value })
  }

  render () {
    const { rejectCashDeposit, modal } = this.props
    const { reason } = this.state

    return (
      <Form onSubmit={rejectCashDeposit.bind(this, this.state)}>
        <ModalHeader color='danger'>
          <H3>Give a Reason</H3>
        </ModalHeader>
        <ModalBody>
          <Input label='Reason' value={reason}
            onChange={this.handleInputChange.bind(this, 'reason')} />
        </ModalBody>
        <ModalFooter>
          <Submit color='danger' disabled={!reason}>Reject</Submit>
          <Button onClick={() => modal.hide()}>Cancel</Button>
        </ModalFooter>
      </Form>
    )
  }
}

export default connect(
  ({ transaction:
    { pendingCashDepositRequests: {
      requestId
    } },
  }) => ({
    requestId
  }), 
  dispatch => bindActionCreators({
    rejectCashDeposit
  }, dispatch)
  )(RejectCashDepositModal)
