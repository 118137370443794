export const BANKS = [
  {
    label: '---',
    code: 0
  },
  {
    label: 'Maybank',
    code: 'MBB'
  },
  {
    label: 'CIMB Bank',
    code: 'CIMB'
  },
  {
    label: 'Hong Leong Bank',
    code: 'HLB'
  },
  {
    label: 'Public Bank',
    code: 'PBB'
  },
  {
    label: 'Am Bank',
    code: 'AMB'
  },
  {
    label: 'RHB Bank',
    code: 'RHB'
  },
  {
    label: 'Bank Islam Malaysia',
    code: 'BIMB'
  },
  {
    label: 'Argo Bank',
    code: 'ARGO'
  },
  {
    label: 'Affin Bank',
    code: 'AFFIN'
  },
  {
    label: 'Bank Simpanan Nasional',
    code: 'BSN'
  }
]

export const HELP2PAY_BANKS = [
  {
    label: '---',
    code: 0
  },
  {
    label: 'Maybank',
    code: 'MBB'
  },
  {
    label: 'CIMB Bank',
    code: 'CIMB'
  },
  {
    label: 'Hong Leong Bank',
    code: 'HLB'
  },
  {
    label: 'Public Bank',
    code: 'PBB'
  },
  {
    label: 'Am Bank',
    code: 'AMB'
  },
  {
    label: 'RHB Bank',
    code: 'RHB'
  },
  {
    label: 'Bank Islam Malaysia',
    code: 'BIMB'
  }
]

export const CASH_BANKS = [
  {
    label: '---',
    code: 0
  },
  {
    label: 'AM Bank',
    code: 'AMB'
  },
  {
    label: 'RHB Bank',
    code: 'RHB'
  },
  {
    label: 'Maybank',
    code: 'MBB'
  },
  {
    label: 'Bank Simpanan Nasional',
    code: 'BSN'
  },
  {
    label: 'Hong Leong Bank',
    code: 'HLB'
  },
]
  

export const ADMIN_BANKS = [
  {
    label: 'Maybank',
    code: 'MBB'
  },
  { 
    label: 'Hong Leong Bank',
    code: 'HLB'
  },
  {
    label: 'CIMB Bank',
    code: 'CIMB'
  },
  {
    label: 'Public Bank',
    code: 'PBB'
  },
  {
    label: 'RHB Bank',
    code: 'RHB'
  },
  {
    label: 'Bank Simpanan National',
    code: 'BSN'
  },
  {
    label: 'AM Bank',
    code: 'AMB'
  },
  {
    label: 'Help2pay',
    code: 'help2pay'
  }
]

