import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Page, ToggleGroup, Tab, Breadcrumb, Crumb } from '@framework'
import { ApprovedWithdrawalRequestsTable, PendingWithdrawalRequestsTable, RejectedWithdrawalRequestsTable, ProcessingWithdrawalRequestsTable } from '@components/Tables'

const TABLE_REJECTED = 'rejected'
const TABLE_PENDING = 'pending'
const TABLE_APPROVED = 'approved'
const TABLE_PROCESSING = 'processing'

class TransactionOverviewPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      page: TABLE_PENDING
    }
  }

  handleToggle (selected) {
    this.setState({
      page: selected
    })
  }

  renderSelectedPage () {
    switch(this.state.page) {
      case TABLE_PENDING:
        return <PendingWithdrawalRequestsTable />
      case TABLE_PROCESSING:
        return <ProcessingWithdrawalRequestsTable /> 
      case TABLE_APPROVED:
        return <ApprovedWithdrawalRequestsTable />
      case TABLE_REJECTED:
        return <RejectedWithdrawalRequestsTable />
      default:
    }
  }

  render () {
    return (
      <Page fluid paperWhite className='scope-withdrawal-overview'>
        <Helmet title='OPS Admin: Withdrawal Overview' />
        <Breadcrumb>
          <Crumb to='/manage/workspace'>Home</Crumb>
          <Crumb>Withdrawal Overview</Crumb>
        </Breadcrumb>
        <ToggleGroup
          selected={this.state.page}
          onChange={(selected) => this.handleToggle(selected)}>
          <Tab value={TABLE_PENDING}>Pending Withdrawal</Tab>
          <Tab value={TABLE_PROCESSING}>Processing Withdrawal</Tab>
          <Tab value={TABLE_APPROVED}>Approved Withdrawal</Tab>
          <Tab value={TABLE_REJECTED}>Rejected Withdrawal</Tab>

        </ToggleGroup>
        {this.renderSelectedPage()}
      </Page>
    )
  }
}

export default TransactionOverviewPage