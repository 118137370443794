import React, { Component } from 'react'
import InfiniteCalendar, { withRange, Calendar } from 'react-infinite-calendar'
import moment from 'moment'
import { Input } from '@framework'

import 'react-infinite-calendar/styles.css'
import './InfiniteDatePicker.scss'

export class InfiniteDatePicker extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedDate: new Date(),
      startDate: moment(new Date()).startOf('month').toDate(),
      endDate: moment(new Date()).endOf('month').toDate(),
      isCalendarOpen: false
    }
  }

  handleDateChange (date) {
    const { onSelect } = this.props

    this.setState({
      selectedDate: date,
      isCalendarOpen: false
    })

    onSelect && onSelect(date)
  }

  handleDateRangeChange (e) {
    const { onSelect } = this.props
    const SECOND_DATE_SELECTED = e.eventType === 3 

    if (SECOND_DATE_SELECTED) {
      this.setState({
        startDate: e.start,
        endDate: e.end,
        isCalendarOpen: false
      })

      onSelect && onSelect({
        startDate: e.start,
        endDate: e.end
      })
    }
  }

  openCalendar () {
    this.setState({
      isCalendarOpen: true
    })
  }

  closeCalendar () {
    this.setState({
      isCalendarOpen: false
    })
  }

  renderDatePicker () {
    const { selectedDate, startDate, endDate } = this.state
    const { isSingleDateSelect } = this.props

    return (
      <div className='date-picker-overlay-container'>
        <div className="date-picker-wrapper">
          <div className='overlay' onClick={this.closeCalendar.bind(this)} />
          <div className="date-picker-container">
            <InfiniteCalendar
              Component={isSingleDateSelect ? undefined : withRange(Calendar)}
              width={300}
              height={300}
              onSelect={isSingleDateSelect ? this.handleDateChange.bind(this) : this.handleDateRangeChange.bind(this)}
              selected={isSingleDateSelect ? selectedDate : {
                start: startDate,
                end: endDate
              }}
              locale={{
                headerFormat: 'MMM Do',
              }} />
          </div>
        </div>
      </div>
    )
  }
  
  render () {
    const { startDate, endDate, selectedDate, isCalendarOpen } = this.state
    const { isSingleDateSelect, label } = this.props

    if (isSingleDateSelect) {
      return (
        <div className='af-infinite-date-picker'>
          <Input label={label}
            value={selectedDate.toLocaleDateString()}
            onClick={this.openCalendar.bind(this)}
            readOnly />
          { isCalendarOpen && this.renderDatePicker() }
        </div>
      )
    }

    return (
      <div className='af-infinite-date-picker'>
        <Input label='Start Date'
          value={startDate.toLocaleDateString()}
          onClick={this.openCalendar.bind(this)}/>
        <Input label='End Date'
          value={endDate.toLocaleDateString()}
          onClick={this.openCalendar.bind(this)} />
        { isCalendarOpen && this.renderDatePicker() }
      </div>
    )
  }
}