import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Table, Input, Form, Submit } from '@framework'
import { getSliceData } from '@helpers/table-data-manipulator'
import moment from 'moment'
import { listWinReport, pageChangeUsersWinReport } from '@store/actions/transaction.action'
import { maskPhoneNumber } from '@helpers/utils';

class WinReportTable extends Component {

  constructor (props) {
    super(props)
    this.state = {
      winAmount: 0,
      startDate: moment(new Date()).startOf('day').toDate(),
      endDate: moment(new Date()).endOf('day').toDate()
    }
  }

  columns () {
    const { isMaster } = this.props;

    return [
        {
            Header: 'Username',
            accessor: 'phoneNumber',
            Cell: row => (
              <div>{isMaster ? row.value : maskPhoneNumber(row.value)}</div>
            )
        },
        {
            Header: 'Win',
            accessor: 'totalWin'
        }
    ]
  }

  handleInputChange (state, event) {
    this.setState({ [state]: event.target.value })
  }

  handlePageChange (newPage) {
    this.props.pageChangeUsersWinReport({
      requestPage: newPage,
    })
  }

  listWinReport() {
    const { startDate, endDate, winAmount } = this.state;
    this.props.listWinReport({
      winAmount,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    })
  }


  render () {
    const { data, currentPage, pageSize, isLastPage } = this.props
    const { winAmount } = this.state
    return (
      <div>
        <Form onSubmit={this.listWinReport.bind(this)}>
          <Input value={winAmount} type='number'
            label='Win Amount' placeholder='Enter Amount'
            onChange={this.handleInputChange.bind(this, 'winAmount')} />
          <Input 
            label='Start Date'
            type='datepicker'
            value={this.state.startDate}
            onChange={this.handleInputChange.bind(this, 'startDate')}/>

          <Input 
            label='End Date'
            type='datepicker'
            value={this.state.endDate}
            onChange={this.handleInputChange.bind(this, 'endDate')}/>
          <Submit color='primary'> Submit </Submit>
        </Form>
        <Table data={data}
          disableNextButton={isLastPage}
          currentPage={currentPage}
          defaultPageSize={pageSize}
          showPageSizeOptions={false}
          minRows={pageSize}
          onRefresh={this.listWinReport.bind(this)}
          onPageChange={(newPage) => this.handlePageChange(newPage)}
          columns={this.columns()} />
      </div>
    )
  }
}

export default connect(
  ({ 
    auth: {
      isMaster
    },
    transaction:{
      usersWinReport: {
        data,
        winAmount,
        startDate,
        endDate,
        currentPage,
        pageSize,
        isLastPage
      }
    }
  }) => ({ 
    data: getSliceData({ data, currentPage, pageSize }),
    currentPage, pageSize, isLastPage,
    winAmount,
    startDate,
    endDate,
    isMaster
  }), 
    dispatch => bindActionCreators({
      listWinReport,
      pageChangeUsersWinReport
    }, dispatch)
)(WinReportTable)
