import React, { Component } from 'react'
import { H3, ModalHeader, ModalBody, ModalFooter, Button, Input, Form, Submit } from '@framework'
import { sendPlayerSms } from '@store/actions/messaging.action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

class SmsModal extends Component {

  constructor (props) {
    super(props)
    this.state = {
      content: '',
      phoneNumber: props.playerDetail.phone_number
    }
  }

  handleInputChange (state, event) {
    this.setState({ [state]: event.target.value })
  }

  render () {
    const { sendPlayerSms, modal } = this.props
    return (
      <div>
        <Form onSubmit={sendPlayerSms.bind(this, this.state)}>
          <ModalHeader color='primary'>
            <H3>Send Sms</H3>
          </ModalHeader>
          <ModalBody>
            <Input label='Content' type='textarea' rows='4'
              onChange={this.handleInputChange.bind(this, 'content')}/>
          </ModalBody>
          <ModalFooter>
            <Submit color='success' onClick={() => modal.hide()}>Send</Submit>
            <Button onClick={() => modal.hide()}>Close</Button>
          </ModalFooter>
        </Form>
       
      </div>
    )
  }
}


export default connect(
  ({ player:
    { 
      list: { data },
      view: { listDataIndex }
    }
  }) => ({
    playerDetail: data[listDataIndex] ? data[listDataIndex] : {}
  }), 
  dispatch => bindActionCreators({
    sendPlayerSms
  }, dispatch)
  )(SmsModal)