import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'

import { Helmet } from 'react-helmet'
import { Page, Section, P, Breadcrumb, Crumb, Flex } from '@framework'
import { ReferralTransactionTable } from '@components/Tables'
import { viewSinglePlayerReferral } from '@store/actions/report.action'
import moment from 'moment';

class PlayerReferralDetailPage extends Component {

  componentDidMount () {
    const { viewSinglePlayerReferral, match } = this.props
    viewSinglePlayerReferral({ playerId: match.params.id })
  }


  render () {
    const { data, playerDetail, startDate, endDate } = this.props
    const { phoneNumber, totalReferral, txId } = playerDetail
    if (!data.length) {
      return <Redirect to='/manage/referral-report' />
    }

    return (
      <Page fluid paperWhite className='scope-user-detail'>
        <Helmet title='OPS Admin: User Detail' />
        <Breadcrumb>
          <Crumb to='/manage/workspace'>Home</Crumb>
          <Crumb to='/manage/referral-report'>Referral Transaction Detail</Crumb>
          <Crumb>Detail</Crumb>
        </Breadcrumb>
        <Section>
          <Flex>
            <Flex column>
              <P>Phone: {phoneNumber}</P>
              <P>Referral Bonus: {totalReferral}</P>
              <P>Start Date: {moment(startDate).format('DD/MM/YYYY h:mm:ss a')}</P>
              <P>End date: {moment(endDate).format('DD/MM/YYYY h:mm:ss a')}</P>

            </Flex>
   
          </Flex>
          {txId && <ReferralTransactionTable txIds={txId} />}
      
        </Section>
    
      </Page>
    )
  }
}

export default connect(
  ({ report:
    { 
      referralReport : {
        list: { data, startDate, endDate },
        view: { listDataIndex }
      }
    }
  }) => ({
    data,
    playerDetail: data[listDataIndex] ? data[listDataIndex] : {},
    startDate,
    endDate 
  }), 
  dispatch => bindActionCreators({
    viewSinglePlayerReferral,
  }, dispatch)
)(PlayerReferralDetailPage)
