import React from 'react'

import './Image.scss'

export const Image = (props) => {
  const { className, src, alt = '', ...attr } = props

  const renderClassName = () => {
    return `af-image ${className}`.trim()
  }

  return (
    <div className={renderClassName()}>
      <img src={src} alt={alt} {...attr} />
    </div>
  )
}
