import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Table } from '@framework'
import { getSliceData } from '@helpers/table-data-manipulator'

import {
  listRebateTransacitonsInfo,
  pageChangeRebateTransaction
} from '@store/actions/report.action'

class RebateTransactionTable extends Component {

  componentDidMount () {
    const { listRebateTransacitonsInfo, txIds } = this.props
    listRebateTransacitonsInfo({ txIds })
  }

  columns () {
    return [
      {
        Header: 'Timestamp',
        accessor: 'createdAt',
        Cell: row => (
          <div>{moment(new Date(row.value)).format('DD/MM/YYYY h:mm:ss a')}</div>
        )
      },{
        Header: 'Username',
        accessor: 'phoneNumber'
      },
      {
        Header: 'Score',
        accessor: 'score'
      },
      {
        Header: 'Bonus',
        accessor: 'bonus' 
      }
    ]
  }

  handlePageChange (newPage) {
    this.props.pageChangeRebateTransaction({ requestPage : newPage })

  }

  listTransactionHistory() {
    const { playerId } = this.props;
    const { startDate, endDate } = this.state;
    this.props.listTransactionHistory({
      playerId,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    })
  }

  render () {
    const { data, currentPage, pageSize, isLastPage } = this.props
    return (
      <Table data={data}
        centeredContent
        disableNextButton={isLastPage}
        currentPage={currentPage}
        defaultPageSize={pageSize}
        showPageSizeOptions={false}
        minRows={pageSize}
        onPageChange={(newPage) => this.handlePageChange(newPage)}
        columns={this.columns()} />
    )
  }
}

export default connect(
  ({ report:
    { rebateReport: {
      view : {
        data, currentPage, pageSize, isLastPage
      }
    } }
  }) => ({
    currentPage,
    pageSize,
    isLastPage,
    data: getSliceData({ data, currentPage, pageSize })
  }), 
  dispatch => bindActionCreators({
    listRebateTransacitonsInfo,
    pageChangeRebateTransaction
  }, dispatch)
)(RebateTransactionTable)
