import { INIT } from './app.reducer'
import { socketActionTypes } from '../sockets'

export const KEEP_ALIVE_REQUEST = '[WEBSOCKET] Keep Alive Request'
export const KEEP_ALIVE_RESPONSE = '[WEBSOCKET] Keep Alive Response'

export const APPPEND_LIST = '[WEBSOCKET] Append List'


const initialState = {
  isConnect: false,
  isResponse: false,
  notReponseCounter: 0,
  ws: null,
  list: []
}

export default function (state = initialState, { payload, type, annoucement: init }) {
  switch (type) {
    case socketActionTypes.SOCKET_OPEN:
      const { ws } = payload;
      ws.send(JSON.stringify({ action: 'keepalive' }))
      return {
        ...state,
        ws,
        isConnect: true
      }

    case socketActionTypes.SOCKET_CLOSE:
      console.log('socket close')
      console.log(payload)
      console.log(payload.message)

      return {
        ...state
      }

    case socketActionTypes.SOCKET_ERROR:
      console.log('socket error')
      console.log(payload)
      console.log(payload.message)

      return {
        ...state
      }
  

    case KEEP_ALIVE_REQUEST:
      const notReponseCounter = state.isResponse ? 0 : state.notReponseCounter + 1;
      return {
        ...state,
        isResponse: false,
        notReponseCounter
      }

    case KEEP_ALIVE_RESPONSE:
      return {
        ...state,
        isResponse: true,
        notReponseCounter: 0
      }

    case APPPEND_LIST:
      return {
        ...state,
        list: [ payload.data, ...state.list ]
      }
    
    case INIT:
      return {
        ...state,
        ...init
      }

    default:
      return state
  }
}