import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { StyleRoot } from 'radium'

import cookie from 'cookie'
import { ADMIN_ID_TOKEN } from '@constants/cookies';
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { connectWS } from '@store/actions/websocket.action';
import { setUser } from '@store/actions/auth.action';

import {
  getGameTipVisibility,
  getLuckyWheelVisibility
} from '@store/actions/app.action';
import { renderRoutes } from '@helpers/routes'
import { appRoutes } from '@routes'
import LoadWrapper from '@components/LoadWrapper'
import InterruptiveLoadWrapper from '@components/InterruptiveLoadWrapper'
import ModalWrapper from '@components/ModalWrapper'

import '@fortawesome/fontawesome-free/css/all.min.css'
import './App.scss'

class App extends Component {

  componentDidMount() {
    const hasAccessToken = !!cookie.parse(document.cookie)[ADMIN_ID_TOKEN];
    if(hasAccessToken) {
      this.props.setUser();
      this.props.connectWS();
      this.props.getGameTipVisibility();
      this.props.getLuckyWheelVisibility();
    }
  }

  render () {
    return (
      <StyleRoot>
        <div className='scope-app'>
          <ModalWrapper />
          <LoadWrapper />
          <InterruptiveLoadWrapper />
          <Helmet title='Online Payment System' />
          <Switch>
            <Route path='/' exact
              render={() => (
                <Redirect to='/auth/login' />
            )} />
            {renderRoutes(appRoutes)}
          </Switch>
        </div>
      </StyleRoot>
    )
  }
}

// withRouter is needed
export default compose(
  withRouter,
  connect(
    null, 
    dispatch => bindActionCreators({
      connectWS,
      setUser,
      getGameTipVisibility,
      getLuckyWheelVisibility
    }, dispatch)
  )
)(App);