import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'

import { Helmet } from 'react-helmet'
import { Page, Section, P, Button, Breadcrumb, Crumb, ToggleGroup, Tab, Flex } from '@framework'
import { TransactionHistoryTable, OrderHistoryTable } from '@components/Tables'
import { viewSinglePlayer, toggleDisablePlayer, adminGetUserOtp, toggleBonusPlayer, renewPlayerGameId } from '@store/actions/player.action'
import { openModal } from '@store/actions/app.action'
import { SET_POINT_MODAL, SET_SCORE_MODAL, SEND_SMS_MODAL, SET_NOTE_MODAL } from '@components/ModalWrapper'
import { maskPhoneNumber } from '@helpers/utils';

const TABLE_TRANSACTION = 'transaction'
const TABLE_ORDER = 'order'


class PlayerDetailPage extends Component {

  constructor (props) {
    super(props)
    this.state = {
      page: TABLE_TRANSACTION
    }
  }

  componentDidMount () {
    const { adminGetUserOtp, viewSinglePlayer, match, data } = this.props
    viewSinglePlayer({ playerId: match.params.id })
    const user = data.find(user => user.cognitoId === match.params.id)
    if(user) {
      adminGetUserOtp({ phoneNumber: user.phoneNumber })
    }

  }

  toggleDisablePlayer () {
    const { playerDetail, toggleDisablePlayer } = this.props
    toggleDisablePlayer({ playerId: playerDetail.Username })
  }

  toggleBonusPlayer () {
    const { playerDetail, toggleBonusPlayer } = this.props
    toggleBonusPlayer({ playerId: playerDetail.Username })
  }

  regenerateNewPlayerGameId () {
    const { playerDetail, renewPlayerGameId } = this.props
    console.log(playerDetail)
    renewPlayerGameId({ playerId: playerDetail.Username, phoneNumber: playerDetail.phoneNumber })
  }


  handleToggle (selected) {
    this.setState({
      page: selected
    })
  }

  renderToggleDisableButton () {
    const { playerDetail } = this.props
    const PLAYER_DISABLED = playerDetail.agentStatus === 'disabled'

    return  <Button
      color={PLAYER_DISABLED ? 'success' : 'danger'}
      onClick={this.toggleDisablePlayer.bind(this)}>
        {PLAYER_DISABLED? 'Enable' : 'Disable'} User
      </Button>
  }

  renderToggleBonusButton () {
    const { playerDetail } = this.props
    const PLAYER_BONUS_DISABLED = playerDetail.bonusState === 'disabled'

    return  <Button
      color={PLAYER_BONUS_DISABLED ? 'success' : 'danger'}
      onClick={this.toggleBonusPlayer.bind(this)}>
        {PLAYER_BONUS_DISABLED? 'Enable' : 'Disable'} User Bonus
      </Button>
  }

  renderSelectedPage () {
    const { params } = this.props.match
    switch(this.state.page) {
      case TABLE_TRANSACTION:
        return <TransactionHistoryTable playerId={params.id} />
      case TABLE_ORDER:
        return <OrderHistoryTable playerId={params.id} />
      default:
    }
  }

  renderBankTable(){
    const { playerDetail } = this.props;
    const bankAccountNames = playerDetail.banks.map(item => item.bankAccountName).join(', ');
    const bankAccountNumber = playerDetail.banks.map(item => item.bankAccountNumber).join(', ');
    const bankCode = playerDetail.banks.map(item => item.bankCode).join(', ');

    return(
        <Flex column>
          <P>Bank Account Name: {bankAccountNames}</P>
          <P>Bank Account Number: {bankAccountNumber}</P>
          <P>Bank Code: {bankCode}</P>
        </Flex>
    )
  }

  render () {
    const { data, playerDetail, openModal, isMaster } = this.props
    const { phoneNumber, cognitoId, balance = 0, totalBetAmount = 0, point = 0, agentStatus, playerUsername, banks, note, otp } = playerDetail
    if (!data.length) {
      return <Redirect to='/manage/player' />
    }

    return (
      <Page fluid paperWhite className='scope-user-detail'>
        <Helmet title='OPS Admin: User Detail' />
        <Breadcrumb>
          <Crumb to='/manage/workspace'>Home</Crumb>
          <Crumb to='/manage/player'>Player Overview</Crumb>
          <Crumb>Detail</Crumb>
        </Breadcrumb>
        <Section>
          <Flex>
            {
                phoneNumber ?  <Flex column>
                <P>Phone: {isMaster ? phoneNumber : maskPhoneNumber(phoneNumber)}</P>
                <P>Game ID: {playerUsername}</P>
                <P>Customer ID: {cognitoId}</P>

                <P>Mega Balance: {balance}</P>
                <P>Lucky Wheel Point: {point}</P>
                <P>Status: {agentStatus}</P>
                <P>Total Bet Amount: {totalBetAmount}</P>
                <P>Note: {note}</P>
                <P>OTP: {otp}</P>
  
              </Flex> :''
            }
           
            {banks ? this.renderBankTable(): '' }
          </Flex>

          <Flex row>
            {isMaster && <Button color='primary' onClick={openModal.bind(this, { modal: SET_SCORE_MODAL })}>Update Score</Button>} 
            {isMaster && <Button color='primary' onClick={openModal.bind(this, { modal: SET_POINT_MODAL })}>Update Point</Button>} 
          </Flex>
 
          <Flex>
            <Button color='success' onClick={openModal.bind(this, { modal: SEND_SMS_MODAL })}>Send Sms</Button>
            <Button color='primary' onClick={this.regenerateNewPlayerGameId.bind(this)}>Renew Player Game ID</Button>
            {this.renderToggleDisableButton()}
            {this.renderToggleBonusButton()}
            <Button onClick={openModal.bind(this, { modal: SET_NOTE_MODAL })}>Add Note</Button>
          </Flex>
  
        </Section>
        <ToggleGroup
          selected={this.state.page}
          onChange={(selected) => this.handleToggle(selected)}>
          <Tab value={TABLE_TRANSACTION}>Transaction</Tab>
          <Tab value={TABLE_ORDER}>Order</Tab>
        </ToggleGroup>
        {this.renderSelectedPage()}
      </Page>
    )
  }
}

export default connect(
  ({ 
    auth: {
      isMaster
    },
    player: { 
      list: { data },
      view: { listDataIndex }
    }
  }) => ({
    data,
    playerDetail: data[listDataIndex] ? data[listDataIndex] : {},
    isMaster
  }), 
  dispatch => bindActionCreators({
    viewSinglePlayer,
    toggleDisablePlayer,
    adminGetUserOtp,
    openModal,
    toggleBonusPlayer,
    renewPlayerGameId
  }, dispatch)
)(PlayerDetailPage)
