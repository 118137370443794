import { authInterceptedAxios } from './interceptors'
import { mapFilterObjectToArray } from '@helpers/utils';

export function setScore ({ playerId, score, remark }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/agent/admin-set-score',
    data: {
      cognitoId: playerId,
      score,
      remark
    },
    loadKey: 'Updating Score',
    isInterruptive: true
  })
}

export function setPoint ({ playerId, point, remark }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/agent/admin-set-point',
    data: { 
      cognitoId: playerId,
      point,
      remark
    },
    loadKey: 'Updating Point',
    isInterruptive: true
  })
}

export function listUserAccountInfo({ playerIds }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/agent/admin-list-game-account-info',
    data: { cognitoIds: playerIds },
    loadKey: 'Fetching Players Account Info',
    isInterruptive: false
  })
}
export function listTransactionsInfo({ txIds }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/agent-report/admin-list-transactions-info',
    data: { txIds },
    loadKey: 'Fetching Transactions Info',
    isInterruptive: false
  })
}

export function listTransactions ({ playerId, pageSize, nextKey, startDate, endDate }) {
  return authInterceptedAxios({
    method: 'get',
    url: `/agent/admin-list-transactions/${playerId}`,
    params: {
      limit: pageSize,
      nextKey,
      startDate,
      endDate
    },
    loadKey: 'Fetching Player Transactions',
    isInterruptive: false
  })
}

export function toggleDisablePlayer ({ playerId }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/agent/admin-toggle-disable-user',
    data: { cognitoId: playerId },
    loadKey: 'Toggle Enable/Disable Player',
    isInterruptive: true
  })
}

export function getAgentScoreBalance () {
  return authInterceptedAxios({
    method: 'get',
    url: '/agent/admin-get-agent-score-balance',
    loadKey: 'Fetching Agent Balance',
    isInterruptive: true
  })
}

export function listAllUserTransactions ({ pageSize, nextKey, startDate, endDate, filter = {} }) {
  const filterArray = mapFilterObjectToArray(filter)

  return authInterceptedAxios({
    method: 'get',
    url: '/agent/admin-list-all-user-transactions',
    params: {
      limit: pageSize,
      nextKey,
      startDate,
      endDate,
      ...filterArray && {
        filter: JSON.stringify(filterArray)
      }
    },
    loadKey: 'Fetching All Player Transactions',
    isInterruptive: false
  })
}

export function getTotalDepositWithdrawal ({ startDate, endDate }) {
  return authInterceptedAxios({
    method: 'get',
    url: '/agent/admin-get-total-success-transactions',
    params: {
      startDate,
      endDate
    },
    loadKey: 'Fetching All Player Total Transactions Amount',
    isInterruptive: false
  })
}

export function listUsersWinReport ({ winAmount, startDate, endDate }) {
  return authInterceptedAxios({
    method: 'get',
    url: '/agent/admin-list-users-win-report',
    params: {
      winAmount,
      startDate,
      endDate
    },
    loadKey: 'Fetching Player Win Report',
    isInterruptive: false
  })
}

export function updateUser ({ playerId, note }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/agent/admin-update-user-info',
    data: {
      cognitoId: playerId,
      note
    },
    loadKey: 'Updating Player Inform',
    isInterruptive: false,
    successPrompt: {
      header: 'Success',
      message: 'Update User Info Success'
    }
  })
}

export function listProductTallyReport ({ products, startDate, endDate }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/agent-report/admin-product-balance-report',
    params: { startDate, endDate },
    data: { products },
    loadKey: 'Listing Product Report',
    isInterruptive: true
  })
}

export function updateProductBalance ({ productCode, balance, remark }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/agent-report/admin-update-balance',
    data: { productCode, balance, remark },
    loadKey: 'Update Product Balance',
    isInterruptive: true,
    successPrompt: {
      header: 'Success',
      message: 'Update Product Balance Success'
    }
  })
}


export function listReferralReport ({ startDate, endDate }) {
  return authInterceptedAxios({
    method: 'get',
    url: '/agent-report/admin-referral-report',
    params: {
      startDate,
      endDate
    },
    loadKey: 'Fetching Player Referral Report',
    isInterruptive: false
  })
}

export function listRebateReport ({ startDate, endDate }) {
  return authInterceptedAxios({
    method: 'get',
    url: '/agent-report/admin-rebate-report',
    params: {
      startDate,
      endDate
    },
    loadKey: 'Fetching Player Rebate Report',
    isInterruptive: false
  })
}

export function toggleGameTips ({ type, loadKey }) {
  return authInterceptedAxios({
    method: 'post',
    url: 'game/admin-toggle-games-tip',
    data: { type },
    loadKey,
    isInterruptive: false
  })
}

export function toggleLuckyWheel ({ type, loadKey }) {
  return authInterceptedAxios({
    method: 'post',
    url: 'game/admin-toggle-disable-games',
    data: { type },
    loadKey,
    isInterruptive: false
  })
}

export function togglePlayerBonus ({ playerId }) {
  return authInterceptedAxios({
    method: 'post',
    url: 'agent-user/admin-toggle-user-bonus-availability',
    data: { cognitoId: playerId },
    loadKey: 'Toggle Enable/Disable Bonus Player',
    isInterruptive: true
  })
}

export function batchSetScore({ score, remark }) {
  return authInterceptedAxios({
    method: 'post',
    url: 'agent-user/batch-set-score',
    data: { score, remark },
    loadKey: 'Trigger Batch Set Score Process',
    isInterruptive: true
  })
}


export function getBatchSetScoreStatus() {
  return authInterceptedAxios({
    method: 'get',
    url: 'agent-user/get-batch-set-score-status',
    loadKey: 'Fetching Batch Set Score Status',
    isInterruptive: true
  })
}

export function resetBatchSetScoreStatus() {
  return authInterceptedAxios({
    method: 'get',
    url: 'agent-user/reset-batch-set-score',
    loadKey: 'Resetting Batch Set Score Status',
    isInterruptive: true,
    successPrompt: {
      header: 'Success',
      message: 'Reset batch set score success'
    }
  })
}

export function renewPlayerGameId({ playerId, phoneNumber}) {
  return authInterceptedAxios({
    method: 'post',
    url: 'agent-user/regenerate-user-id',
    loadKey: 'Generating New User ID',
    data: { cognitoId: playerId, phoneNumber },

    isInterruptive: true,
    successPrompt: {
      header: 'Success',
      message: 'Generate New User ID success'
    }
  })
}