import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Page, Input, Button, Breadcrumb, Crumb, Form, Submit, H3, P } from '@framework'
import { publishAnnouncement, deleteAnnouncement, getAnnouncement, publishDownloadLink } from '@store/actions/messaging.action'

class AnnouncementOverviewPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      prevAnnouncement: 'Test memssage',
      announcement: '',
      downloadLink: ''
    }
  }

  componentDidMount(){
    this.props.getAnnouncement();
  }

  handleInputChange(state, event){
    this.setState({ [state]: event.target.value })
  }

  renderAnnouncementPublisher() {
    return(
      <Form onSubmit={() => this.props.publishAnnouncement(this.state)}>
        <Input label='Announcement' type='textarea' row='4'
            onChange={this.handleInputChange.bind(this, 'announcement')}/>
        <Submit color='primary'>
          Publish
        </Submit>
        <Button color='danger'
          onClick={() => this.props.deleteAnnouncement()}> 
          Clear Announcement 
        </Button>
      </Form>
    )
  }

  renderDownloadLinkPublisher(){
    return (
      <Form onSubmit={() => this.props.publishDownloadLink(this.state)}>
        <Input label='Download Link' type='text'
            onChange={this.handleInputChange.bind(this, 'downloadLink')}/>
        <Submit color='primary'>
          Publish
        </Submit>
      </Form>

    )
  }

  render () {
    const { announcement: prevAnnouncement } = this.props;
    return (
      <Page paperWhite className='scope-announcement-overview'>
        <Helmet title='OPS Admin: Announcement Overview' />
        <Breadcrumb>
          <Crumb to='/manage/workspace'>Home</Crumb>
          <Crumb>Announcement Overview</Crumb>
        </Breadcrumb>
        <H3> Previous Announcement:</H3><P> {prevAnnouncement} </P>
        {this.renderAnnouncementPublisher()}
        <br/>
        <br/>

        {this.renderDownloadLinkPublisher()}

      </Page>
    )
  }
}

export default connect(
({ 
  message: {
    announcement
  }}) => ({
  announcement
}), 
  dispatch => bindActionCreators({
    publishAnnouncement,
    deleteAnnouncement,
    getAnnouncement,
    publishDownloadLink
  }, dispatch)
)(AnnouncementOverviewPage)


