import KitchenSinkPage from '@pages/Kitchen/Sink'
import { BasePage } from '@pages/Auth'
import Workspace from '@pages/Workspace'
import Error404 from '@pages/Error404'

export const appRoutes = [
  {
    path: '/kitchen/sink',
    component: KitchenSinkPage
  }, {
    path: '/auth',
    component: BasePage
  }, {
    path: '/manage',
    component: Workspace
  }, {
    component: Error404
  }
]
