import React, { Component } from 'react'

import './FlyingText.scss'

export class FlyingText extends Component {
  constructor (props) {
    super(props)
    this.ref = React.createRef()
  }

  render () {
    const { column, noGrow, children, ...attr } = this.props

    return (
      <div className="af-flying-text">
        <div ref={this.ref}
          className="flying-content"
          {...attr}>
          {children}
        </div>
      </div>
    )
  }
}
