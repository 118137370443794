import Cookies from 'js-cookie'

import { history } from '@store';
import appInterceptedAxios from './app.interceptor'
import { parseJwtPayload } from '@helpers/parse-jwt-payload'
import { refreshToken } from '../../services/auth.api'
import { ADMIN_ID_TOKEN } from '@constants/cookies';

export default async function authRequestInterceptor (request) {
  let idToken = Cookies.get(ADMIN_ID_TOKEN)
  if (!idToken) {
    history.push('/')
    return Promise.reject({ message: 'not logged in' })
  }

  const isTokenExpired = new Date() > new Date(parseJwtPayload(idToken).exp * 1000)

  if (isTokenExpired) {
    try {
      await refreshToken()
      idToken = Cookies.get(ADMIN_ID_TOKEN)
    } catch (err) {
      history.push('/')
      return Promise.reject({ message: 'cannot refresh token' });
    }
  }

  request.headers = { Authorization: `Bearer ${idToken}` }

  try { 
    const result = await appInterceptedAxios(request)
    return Promise.resolve(result)
  } catch (err) {
    const IS_NOT_LOGGED_IN = err.status === 401

    if (IS_NOT_LOGGED_IN) {
      history.push('/')
    }

    return Promise.reject(err)
  }
}
