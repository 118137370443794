import { INIT } from './app.reducer'
import { LOGOUT_ADMIN } from './auth.reducer'
export const INIT_RETRY_SET_SCORE = '[RETRY] Init Retry Set Score'
export const PAGE_CHANGE_RETRY = '[RETRY] Page Change Retry'
export const RETRY_SET_SCORE = '[RETRY] Approve Retry'


const initialState = {
  retrySetScoreList: {
    currentPage: 1,
    pageSize: 10,
    lastUpdatedPage: 0,
    nextKey: null,
    isLastPage: false,
    data: [],
    count: 0,
    maxPage: 1,
  }
}

export default function (state = initialState, { payload = {}, type , transaction: init }) {
  const {
    data = [],
    nextKey,
    lastUpdatedPage,
    currentPage,
  } = payload

  switch (type) {
    case INIT_RETRY_SET_SCORE: {
      const {
        data: initialData,
        lastUpdatedPage: initialLastUpdatedPage,
        currentPage: initialCurrentPage,
        nextKey: initialNextKey,
        pageSize
      } = initialState.retrySetScoreList

      const maxPage = Math.ceil(data.length / pageSize);

      return {
        ...state,
        retrySetScoreList: {
          ...initialState.retrySetScoreList,
          data: data ? data : initialData,
          count : data ? data.length : initialData.length,
          nextKey: nextKey ? nextKey : initialNextKey,
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : initialLastUpdatedPage,
          currentPage: currentPage ? currentPage : initialCurrentPage,
          maxPage: maxPage,
          isLastPage: initialCurrentPage >= maxPage
        }
      }
    }

    case PAGE_CHANGE_RETRY: {

      const { maxPage } = state.retrySetScoreList;
      return {
        ...state,
        retrySetScoreList: {
          ...state.retrySetScoreList,
          lastUpdatedPage: lastUpdatedPage,
          currentPage: currentPage,
          isLastPage: currentPage >= maxPage
        }
      }
    }

    case RETRY_SET_SCORE: {
        return {
          ...state,
          retrySetScoreList: {
            ...state.retrySetScoreList,
            data
          }
        }
      }

    case LOGOUT_ADMIN:
      return initialState

    case INIT: {
      return {
        ...state,
        ...init
      }
    }

    default:
      return state
  }
}