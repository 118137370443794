import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Table } from '@framework'
import { getSliceData } from '@helpers/table-data-manipulator'
import { listAdmin, refreshAdminTable } from '@store/actions/auth.action'

class AdminsTable extends Component {

  componentDidMount () {
    this.props.listAdmin();
  }
  
  columns () {
    return [
      {
        Header: 'Username',
        accessor: 'Username',
      }
    ]
  }

  handlePageChange (newPage) {
    this.props.listAdmin({ requestPage: newPage })
  }

  
  handleInputChange (state, event) {
    this.setState({ [state]: event.target.value })
  }

  render () {
    const { data, currentPage, pageSize, isLastPage } = this.props
    return(
      <Table data={data}
        centeredContent
        disableNextButton={isLastPage}
        currentPage={currentPage}
        defaultPageSize={pageSize}
        showPageSizeOptions={false}
        minRows={pageSize}
        onRefresh={this.props.refreshAdminTable.bind(this)}
        onPageChange={(newPage) => this.handlePageChange(newPage)}
        columns={this.columns()} />
    ) 
   
  }
}

export default connect(
  ({ 
    auth: {
      admins: {
        list: {
          data, currentPage, pageSize, isLastPage
        }
      }
    }
  }) => ({
    currentPage,
    pageSize,
    isLastPage,
    data: getSliceData({ data, currentPage, pageSize })
  }), 
  dispatch => bindActionCreators({
    listAdmin,
    refreshAdminTable
  }, dispatch)
)(AdminsTable)
