import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Page, Button } from '@framework'
import { DepositHistoryTable } from '@components/Tables'
import { history } from '@store'


class DepositHistoryDetailpage extends Component {

  render () {
    return (
      <Page fluid paperWhite className='scope-withdrawal-deposit-history'>
        <Helmet title='OPS Admin: Withdrawal Deposit History' />
        <Button onClick={() =>  history.goBack()} 
          color='primary' className='refresh-button' ><i className="fa fa-arrow-left" />
        </Button>
        <DepositHistoryTable/>
      </Page>
    )
  }
}

export default DepositHistoryDetailpage

