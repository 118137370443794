import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Page, Breadcrumb, Crumb } from '@framework'
import { PlayersTable } from '@components/Tables'

class PlayerOverviewPage extends Component {
  render () {
    return (
      <Page fluid paperWhite className='scope-user-overview'>
        <Helmet title='OPS Admin: User Overview' />
        <Breadcrumb>
          <Crumb to='/manage/workspace'>Home</Crumb>
          <Crumb>Player Overview</Crumb>
        </Breadcrumb>
        <PlayersTable />
      </Page>
    )
  }
}

export default PlayerOverviewPage
