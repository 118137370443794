import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Flex, Submit, Button, Form, Input, Table, H2 } from '@framework'
import moment from 'moment'
import { listBalanceReport } from '@store/actions/report.action'
import { ADMIN_BANKS } from '@constants/banks'
import { roundDecimal } from '@helpers/utils';


const BANKS = ADMIN_BANKS.map(bank => bank.code);
const PRODUCTS = ['mega']

class BankBalanceReportTable extends Component {

  constructor(props){
    super(props)
    this.state = {
      banks: BANKS,
      products: PRODUCTS,
      startDate: moment(new Date()).startOf('day').toDate(),
      endDate: moment(new Date()).endOf('day').toDate(),
    }
  }

  componentDidMount() {
    this.props.listBalanceReport(this.state);
  }
  
  handleInputChange (state, event) {
    this.setState({ [state]: event.target.value})
  }

  bankColumns () {
    return [
      {
        Header: "Bank",
        accessor: "bank",
        Footer: 'Total Balance:',
      },
      {
        Header: "Deposit",
        accessor: "totalDeposit",
        Cell: row => <div>{roundDecimal(row.value)}</div>,
        Footer: info => (<span>{info.data.reduce((total, { totalDeposit }) => total += totalDeposit, 0).toFixed(2)}</span>)
      },
      {
        Header: "Withdrawal",
        accessor: "totalWithdrawal",
        Cell: row => <div>{roundDecimal(row.value)}</div>,
        Footer: info => (<span>{info.data.reduce((total, { totalWithdrawal }) => total += totalWithdrawal, 0).toFixed(2)}</span>)
      },
      {
        Header: "Transfer In",
        accessor: "totalTransferIn",
        Cell: row => <div>{roundDecimal(row.value)}</div>,
        Footer: info => (<span>{info.data.reduce((total, { totalTransferIn }) => total += totalTransferIn, 0).toFixed(2)}</span>)
      },
      {
        Header: "Transfer Out",
        accessor: "totalTransferOut",
        Cell: row => <div>{roundDecimal(row.value)}</div>,
        Footer: info => (<span>{info.data.reduce((total, { totalTransferOut }) => total += totalTransferOut, 0).toFixed(2)}</span>)
      },
      {
        Header: "Bank Charge",
        accessor: "totalBankCharge",
        Cell: row => <div>{roundDecimal(row.value)}</div>,
        Footer: info => (<span>{info.data.reduce((total, { totalBankCharge }) => total += totalBankCharge, 0).toFixed(2)}</span>)
      },
      {
        Header: "Balance",
        accessor: "balance",
        Cell: row => <div>{roundDecimal(row.value)}</div>,
        Footer: info => (<span>{info.data.reduce((total, { balance }) => total += balance, 0).toFixed(2)}</span>)
      }
    ]
  }

  productColumns () {
    return [
      {
        Header: "Product",
        accessor: "product",
        Footer: 'Total Balance:',
      },
      {
        Header: "Deposit",
        accessor: "totalDeposit",
        Cell: row => <div>{roundDecimal(row.value)}</div>,
        Footer: info => (<span>{info.data.reduce((total, { totalDeposit }) => total += totalDeposit, 0).toFixed(2)}</span>)
      },
      {
        Header: "Withdrawal",
        accessor: "totalWithdrawal",
        Cell: row => <div>{roundDecimal(row.value)}</div>,
        Footer: info => (<span>{info.data.reduce((total, { totalWithdrawal }) => total += totalWithdrawal, 0).toFixed(2)}</span>)
      },
      {
        Header: "Bonus",
        accessor: "totalBonus",
        Cell: row => <div>{roundDecimal(row.value)}</div>,
        Footer: info => (<span>{info.data.reduce((total, { totalBonus }) => total += totalBonus, 0).toFixed(2)}</span>)
      },
      {
        Header: "Transfer In",
        accessor: "totalTransferIn",
        Cell: row => <div>{roundDecimal(row.value)}</div>,
        Footer: info => (<span>{info.data.reduce((total, { totalTransferIn }) => total += totalTransferIn, 0).toFixed(2)}</span>)
      },
      {
        Header: "Transfer Out",
        accessor: "totalTransferOut",
        Cell: row => <div>{roundDecimal(row.value)}</div>,
        Footer: info => (<span>{info.data.reduce((total, { totalTransferOut }) => total += totalTransferOut, 0).toFixed(2)}</span>)
      },
      {
        Header: "Balance",
        accessor: "balance",
        Cell: row => <div>{roundDecimal(row.value)}</div>,
        Footer: info => (<span>{info.data.reduce((total, { balance }) => total += balance, 0).toFixed(2)}</span>)
      }
    ]
  }

  renderBalanceReport(){
    const { bankData, productData } = this.props
    return (
      <Flex column>
        <Button onClick={this.props.listBalanceReport.bind(this, this.state)} 
          color='primary' className='refresh-button balance-report' ><i className="fas fa-sync" />
        </Button>

        <H2> Bank balance</H2>
        <Table data={bankData}
          centeredContent
          noDataText=''
          minRows={3}
          showNavigation={false}
          columns={this.bankColumns()} />
      
        <H2> Mega balance</H2>
        <Table data={productData}
            centeredContent
            noDataText=''
            showNavigation={false}
            minRows={2}
            columns={this.productColumns()} />
      </Flex>
    );
  }

  render () {
    return (
      <div>
        <Form onSubmit={this.props.listBalanceReport.bind(this, this.state)}>
            <Input 
              label='Start Date'
              type='datepicker'
              value={this.state.startDate}
              onChange={this.handleInputChange.bind(this, 'startDate')}/>
              <Input 
              label='End Date'
              type='datepicker'
              value={this.state.endDate}
              onChange={this.handleInputChange.bind(this, 'endDate')}/>
            <Submit color='primary'>Submit</Submit>
        </Form>
        {this.renderBalanceReport()}
      </div>
    )
  }
}

export default connect(
  ({ report: { 
    bankReport,
    productReport
  }
  }) => ({
    bankData: bankReport.data,
    productData: productReport.data
  }), 
  dispatch => bindActionCreators({
    listBalanceReport
  }, dispatch)
)(BankBalanceReportTable)
