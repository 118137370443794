import Cookies from 'js-cookie'
import { history } from '@store'
import { parseJwtPayload } from '@helpers/parse-jwt-payload'
import * as authService from '../services/auth.api'
import * as agentService from '../services/agent.api'
import {
  SET_USER,
  SET_AGENT_SCORE,
  CHANGE_ADMIN_PASSWORD,
  AUTHENTICATE_TO_CREATE,
  CREATE_ADMIN,
  DELETE_ADMIN,
  LOGOUT_ADMIN,
  INIT_ADMIN_LIST,
  APPEND_ADMIN_LIST,
  PAGE_CHANGE_ADMIN
} from '../reducers/auth.reducer'

import { AUTHENTICATE_PASS } from '@constants/authenticate';
import { socketActionCreators } from '../sockets';

export function login ({ username, password }) {
  return async (dispatch, getstate) => {
    const credentials = { username, password }

    await authService.login(credentials)

    const accessToken = Cookies.get('ops_admin_access_token')

    const decoded = parseJwtPayload(accessToken)
    const isConnect = getstate().ws.isConnect;

    dispatch({
      type: SET_USER,
      payload: { ...decoded }
    })

    if(!isConnect) {
      dispatch(socketActionCreators.socketConnect())
    }
    history.push('/manage/workspace/')

  }
}

export function setUser() {
  return (dispatch, getstate) => {
    const accessToken = Cookies.get('ops_admin_access_token')
    const decoded = parseJwtPayload(accessToken)
    dispatch({
      type: SET_USER,
      payload: { ...decoded }
    })

  }
}

export function getAgentScore () {
  return async dispatch => {
    const { agentScoreBalance } = await agentService.getAgentScoreBalance()
    dispatch({
      type: SET_AGENT_SCORE,
      payload: {
        agentScoreBalance: Number(agentScoreBalance)
      }
    })
  }
}

export function changePassword ({ oldPassword, newPassword}) {
  return async dispatch => {
    await authService.changePassword({ oldPassword, newPassword })
    dispatch({
      type: CHANGE_ADMIN_PASSWORD
    })
  }
}

export function authenticate({ password }){
  return async dispatch => {
    const isPermit = (password === AUTHENTICATE_PASS);
    dispatch({
      type: AUTHENTICATE_TO_CREATE,
      payload: {
        isPermit
      }
    })
  }
}

export function createAdmin ({ username, password}) {
  return async (dispatch, getState) => {
    await authService.createAdmin({ username, password })
    initAdminTable(dispatch, getState)
    dispatch({
      type: CREATE_ADMIN
    })
  }
}

export function deleteAdmin ({ username }) {
  return async (dispatch, getState) => {
    await authService.deleteAdmin({ username })
    initAdminTable(dispatch, getState)
    initAdminTable()
    dispatch({
      type: DELETE_ADMIN,
      payload: {
        username
      }
    })
  }
}

export function listAdmin ({ requestPage = 1 } = {}) {
  return async (dispatch, getState ) => {
    const {
      nextKey: stateNextKey,
      lastUpdatedPage: stateLastUpdatedPage,
      pageSize: statePageSize
    } = getState().auth.admins.list

    if(requestPage && requestPage > stateLastUpdatedPage) {
      const { data, nextKey } = await authService.listAdmin({
        pageSize: statePageSize,
        nextKey: stateNextKey
      });
    
      if(requestPage === 1) {
        dispatch({
          type: INIT_ADMIN_LIST,
          payload: {
            data: data,
            nextKey,
            currentPage: requestPage,
            lastUpdatedPage: requestPage,
          }
        })
      } else {
        dispatch({
          type: APPEND_ADMIN_LIST,
          payload: {
            data: data,
            nextKey,
            currentPage: requestPage,
            lastUpdatedPage: requestPage,
          }
        })
      }
    
    }else if (requestPage > 0 && requestPage <= stateLastUpdatedPage) {
      dispatch({
        type: PAGE_CHANGE_ADMIN,
        payload: {
          currentPage: requestPage,
          nextKey: stateNextKey
        }
      })
    }
    
  }
}

export function refreshAdminTable() {
  return (dispatch, getState) => {
    initAdminTable(dispatch, getState)
  }
}

export async function initAdminTable (dispatch, getState) {
    const { pageSize: statePageSize } = getState().auth.admins.list
    const { data, nextKey } = await authService.listAdmin({ pageSize: statePageSize })

    dispatch({
      type: INIT_ADMIN_LIST,
      payload: {
        data: data,
        nextKey,
        currentPage: 1,
        lastUpdatedPage: 1,
      }
    })
}



export function logoutAdmin () {
  return async dispatch => {
    await authService.logoutAdmin();
    history.push('/');
    dispatch({
      type: LOGOUT_ADMIN
    })
  }
}