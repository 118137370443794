import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Page, Breadcrumb, Crumb } from '@framework'
import { RetrySetScoreTable } from '@components/Tables'

class RetryOverviewPage extends Component {

  render () {
    return (
      <Page fluid paperWhite className='scope-retry-overview'>
        <Helmet title='OPS Admin: Retry Overview' />
        <Breadcrumb>
          <Crumb to='/manage/workspace'>Home</Crumb>
          <Crumb>Retry Order Overview</Crumb>
        </Breadcrumb>
        <RetrySetScoreTable/>
      </Page>
    )
  }
}

export default RetryOverviewPage