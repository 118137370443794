import { INIT } from './app.reducer'
import { LOGOUT_ADMIN } from './auth.reducer'
export const SEND_PLAYER_SMS = '[Messaging] Send Players Sms'
export const SEND_ALL_PLAYER_SMS = '[Messaging] Send All Player Sms'
export const SEND_MANUAL_SMS = '[Messaging] Send Manual Sms'
export const PUBLISH_ANNOUNCEMENT = '[Messaging] Publish announcement'
export const DELETE_ANNOUNCEMENT = '[Messaging] Delete announcement'
export const GET_ANNOUNCEMENT = '[Messaging] Get announcement'
export const SEND_BIRTHDATE_PLAYER_SMS = '[Messaging] Send Birhtdate Players Sms'
export const PUBLISH_DOWNLOAD_LINK = '[Messaging] Publish download link'

export const TRIGGER_BATCH_SET_SCORE = '[Messaging] Trigger Batch Set Score'
export const GET_BATCH_SET_SCORE_STATUS = '[Messaging] Get Batch Set Score Status'
export const RESET_BATCH_SET_SCORE_STATUS = '[Messaging] Reset Batch Set Score Status'


const initialState = {
  phoneNumbers: [],
  phoneNumber: '',
  content: '',
  announcement: '',
  downloadLink: '',
  batchSetScoreProcess: {
    success: false,
    total: 0,
    receive: 0,
    results: []
  }
}

export default function (state = initialState, { payload = {}, type , message: init }) {
  const {
    phoneNumbers = [],
    phoneNumber,
    content,
    announcement,
    downloadLink,
    batchSetScoreProcess = initialState.batchSetScoreProcess
  } = payload

  switch (type) {

    case SEND_ALL_PLAYER_SMS: {
      return {
        ...state,
        phoneNumbers,
        content
      }
    }

    case SEND_PLAYER_SMS: {
      return {
        ...state,
        phoneNumber,
        content
      }
    }

    case SEND_MANUAL_SMS: {
      return {
        ...state,
        phoneNumbers,
        content,
      }
    }

    case PUBLISH_ANNOUNCEMENT: {
      return {
        ...state,
        announcement
      }
    }

    case DELETE_ANNOUNCEMENT: {
      return {
        ...state,
      }
    }

    case GET_ANNOUNCEMENT: {
      return {
        ...state,
        announcement
      }
    }

    case PUBLISH_DOWNLOAD_LINK: {
      return {
        ...state,
        downloadLink
      }
    }

    case SEND_BIRTHDATE_PLAYER_SMS: {
      return {
        ...state,
        content
      }
    }

    case GET_BATCH_SET_SCORE_STATUS: {
      return {
        ...state,
        batchSetScoreProcess
      }
    }

    case TRIGGER_BATCH_SET_SCORE: 
    case RESET_BATCH_SET_SCORE_STATUS:
      return {
        ...state,
      }

    case LOGOUT_ADMIN:
        return initialState

    case INIT: {
      return {
        ...state,
        ...init
      }
    }

    default:
      return state
  }
}