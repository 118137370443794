import { authInterceptedAxios } from './interceptors'

export function listPlayers ({ phoneNumber, pageSize, nextKey }) {
  return authInterceptedAxios({
    method: 'get',
    url: '/auth/admin-list-users',
    params: {
      phoneNumberFilter: phoneNumber,
      limit: pageSize,
      nextKey
    },
    loadKey: 'Listing players',
    isInterruptive: false
  })
}

export function updatePlayer () {
  return authInterceptedAxios({
    method: 'post',
    url: '/auth/update-user',
    data: { },
    loadKey: 'Updating user',
    isInterruptive: true
  })
}