import { INIT } from './app.reducer'
export const SET_USER = '[AUTH] Set User'
export const SET_AGENT_SCORE = '[AUTH] Set Agent Score'
export const CHANGE_ADMIN_PASSWORD = '[AUTH] Change Admin Password'

export const AUTHENTICATE_TO_CREATE = '[AUTH] Authenticate To Create'
export const CREATE_ADMIN = '[AUTH] Create Admin'
export const DELETE_ADMIN = '[AUTH] Delete Admin'
export const INIT_ADMIN_LIST = '[AUTH] List Admin'
export const APPEND_ADMIN_LIST = '[AUTH] Append Admin'
export const PAGE_CHANGE_ADMIN = '[AUTH] Page Change Admin'

export const LOGOUT_ADMIN = '[AUTH] Lougout Admin'


const initialState = {
  isMaster: false,
  username: null,
  agentScoreBalance: 0,
  isPermit: true,
  admins:{
    list: {
      currentPage: 1,
      pageSize: 10,
      lastUpdatedPage: 0,
      nextKey: null,
      isLastPage: false,
      data: [],
    },
  } 
}

export default function (state = initialState, { payload = {}, type, auth: init }) {
  const {
    data = [],
    nextKey,
    lastUpdatedPage,
    currentPage,
    username
  } = payload
  switch (type) {
    case SET_USER:
      const isMaster = username === 'master';
      return {
        ...state,
        username,
        isMaster
      }

    case SET_AGENT_SCORE:
      const { agentScoreBalance } = payload
      return { ...state,
        agentScoreBalance }

    case CHANGE_ADMIN_PASSWORD:
        return { ...state }

    case CREATE_ADMIN:
        return { ...state }

    case DELETE_ADMIN:
        return { ...state }

    case INIT_ADMIN_LIST:
      const {
        data: initialData,
        lastUpdatedPage: initialLastUpdatedPage,
        currentPage: initialCurrentPage
      } = initialState.admins.list
   
      return {
        ...state,
        admins: {
          list: {
            ...state.admins.list,
            data: data ? data : initialData,
            lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : initialLastUpdatedPage,
            currentPage: currentPage ? currentPage : initialCurrentPage,
            isLastPage: !nextKey,
            nextKey
          }
        }
      }

    case APPEND_ADMIN_LIST: 
      const {
        data: stateData,
        lastUpdatedPage: stateLastUpdatedPage,
        currentPage: stateCurrentPage
      } = state.admins.list
      const CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED
        = !nextKey && (currentPage || stateCurrentPage) === (lastUpdatedPage || stateLastUpdatedPage)
      const newData = [ ...stateData, ...data ];
      return {
        ...state,
        admins: {
          list: {
            ...state.admins.list,
            data: newData,
            lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : stateLastUpdatedPage,
            currentPage: currentPage ? currentPage : stateCurrentPage,
            isLastPage: CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED,
            nextKey
          }
        }
      }

    
    case PAGE_CHANGE_ADMIN: {
      const {
        lastUpdatedPage: stateLastUpdatedPage,
      } = state.admins.list
      const isLastPage = currentPage === stateLastUpdatedPage

      return {
        ...state,
        admins: {
          list: {
            ...state.admins.list,
            currentPage: currentPage,
            isLastPage
          }
        }
      }
    }

    case LOGOUT_ADMIN:
        return initialState

    case AUTHENTICATE_TO_CREATE:
        const { isPermit } = payload;
        return { 
          ...state,
          isPermit
        }

    case INIT:
      return { ...state,
        ...init }

    default:
      return state
  }
}