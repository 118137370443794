import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Table, Button } from '@framework'
import { getSliceData } from '@helpers/table-data-manipulator'
import moment from 'moment'
import { openModal } from '@store/actions/app.action'
import { REJECT_CASH_DEPOSIT_MODAL } from '@components/ModalWrapper'
import {
  listPendingCashDepositRequests,
  approveCashDeposit,
  setRejectCashDepositId,
  refreshPendingCashDeposit
} from '@store/actions/transaction.action'

import Viewer from 'react-viewer';
import 'react-viewer/dist/index.css';

class PendingCashDepositRequestsTable extends Component {

  constructor(props){
    super(props);

    this.state ={
      imgVisibleList: { }
    }
  }

  async componentDidMount () {
    const { data } = this.props;
    // if(!data.length > 0) {
    //   await this.props.listPendingCashDepositRequests()
    // }

    data.forEach(item => {
      this.setState({ imgVisibleList: {
        ...this.state.imgVisibleList,
        [item.imageLink]: false
      }});
    })

  }

  openRejectModal(requestId){
    this.props.setRejectCashDepositId({ requestId })
    this.props.openModal({ modal: REJECT_CASH_DEPOSIT_MODAL });
  }

  columns () {
    const { approveCashDeposit, isLoading } = this.props
    return [
      {
        Header: "Deposit",
        columns: [
          {
            Header: "Reference",
            accessor: "referenceId" 
          },
          {
            Header: "Bank",
            accessor: "bank"
          },
          {
            Header: "Date",
            accessor: "depositedDate",
            Cell: row => (
              <div>{moment(new Date(row.value)).format('DD/MM/YYYY h:mm:ss a')}</div>
            )
          },
          {
            Header: "Image",
            accessor: "imageLink",
            Cell: row => (
              <div>
                  <img width={100} height={100} mode='fit' src={row.value} alt="receipt"
                  onClick={() => { 
                    this.setState({ 
                      imgVisibleList: {
                        ...this.state.imgVisibleList,
                        [row.value]: true 
                      }
                    });
                  }}/>
              
                <Viewer
                  visible={this.state.imgVisibleList[row.value]}
                  noToolbar
                  onClose={() => {
                    this.setState({ 
                      imgVisibleList: {
                        ...this.state.imgVisibleList,
                        [row.value]: false 
                      }
                    });
                  }}
                  images={[{src: row.value, alt: 'receipt'}]}
                />
              </div>
            )
          },

        ]
      },
      {
        Header: "Amount",
        columns: [
          {
            Header: "Amount",
            accessor: "score"
          },
          {
            Header: "Bonus",
            accessor: "bonus"
          },
          {
            Header: "Limit",
            accessor: "withdrawalLimit"
          }
        ]
      },
      {
        Header: "Action",
        columns: [
          {
            id: 'approval',
            accessor: acc => ({
              id: acc.id,
              status: acc.status
            }),
            Cell: row => row.value.status === 'approved' ? (
              <div>
                <span>Approved </span>
                <i className="fas fa-check-circle" />
              </div>
            ):(
              <Button disabled={isLoading} color='primary' onClick={() => approveCashDeposit({ id: row.value.id })}>Approve</Button>
            )
          },
          {
            id: 'reject',
            accessor: acc => ({
              id: acc.id,
              status: acc.status
            }),
            Cell: row => row.value.status === 'rejected' ? (
              <div>
                <span>Rejected </span>
                <i className="fas fa-check-circle" />
              </div>
            ):(
                <Button disabled={isLoading} color="danger" onClick={this.openRejectModal.bind(this, row.value.id)}>Reject</Button>
            )
          }
        ]
      }
    ]
  }

  handlePageChange (newPage) {
    this.props.listPendingCashDepositRequests({ requestPage: newPage })
  }

  render () {
    const { data, currentPage, pageSize, isLastPage } = this.props
    return <Table data={data}
      centeredContent
      disableNextButton={isLastPage}
      currentPage={currentPage}
      defaultPageSize={pageSize}
      showPageSizeOptions={false}
      minRows={pageSize}
      onRefresh={this.props.refreshPendingCashDeposit.bind(this)}
      onPageChange={(newPage) => this.handlePageChange(newPage)}
      columns={this.columns()} />
  }
}

export default connect(
  ({
    app,
    transaction:
    { pendingCashDepositRequests: {
      data, currentPage, pageSize, isLastPage
    } }
  }) => ({
    currentPage,
    pageSize,
    isLastPage,
    isLoading: app.intLoadStack.length > 0,
    data: getSliceData({ data, currentPage, pageSize })
  }), 
  dispatch => bindActionCreators({
    listPendingCashDepositRequests,
    approveCashDeposit,
    setRejectCashDepositId,
    refreshPendingCashDeposit,
    openModal
  }, dispatch)
)(PendingCashDepositRequestsTable)
