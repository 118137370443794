import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Modal } from '@framework'
import { closeModal } from '@store/actions/app.action'

import { ErrorModal,
  SetPointModal,
  SetScoreModal,
  SetNoteModal,
  SuccessModal,
  RejectWithdrawalModal,
  RejectCashDepositModal,
  SmsModal } from './Modals'

export const ERROR_MODAL = 'ERROR'
export const SET_NOTE_MODAL = 'SET_NOTE'
export const SET_POINT_MODAL = 'SET_POINT'
export const SET_SCORE_MODAL = 'SET_SCORE'
export const SUCCESS_MODAL = 'SUCCESS_MODAL'
export const SEND_SMS_MODAL = 'SEND_SMS_MODAL'
export const REJECT_WITHDRAWAL_MODAL = 'REJECT_WITHDRAWAL'
export const REJECT_CASH_DEPOSIT_MODAL = 'REJECT_CASH_DEPOSIT'

class ModalWrapper extends Component {
  constructor (props) {
    super(props)
    this.hideModal = this.hideModal.bind(this)
  }

  componentDidUpdate () {
    if (this.props.modal) this.modal.show()
  }

  hideModal () {
    setTimeout(() => {
      this.props.closeModal(null)
    }, 400)
  }

  renderModal () {
    switch (this.props.modal) {
      case ERROR_MODAL:
        return <ErrorModal modal={this.modal} />
      case SET_NOTE_MODAL:
        return <SetNoteModal modal={this.modal}/>
      case SET_POINT_MODAL:
        return <SetPointModal modal={this.modal} />
      case SET_SCORE_MODAL:
        return <SetScoreModal modal={this.modal} />
      case SUCCESS_MODAL:
        return <SuccessModal modal={this.modal} />
      case SEND_SMS_MODAL:
        return <SmsModal modal={this.modal} />
      case REJECT_WITHDRAWAL_MODAL:
        return <RejectWithdrawalModal modal ={this.modal}/>
      case REJECT_CASH_DEPOSIT_MODAL:
        return <RejectCashDepositModal modal ={this.modal}/>
      default:
    }
  }

  render () {
    const { config } = this.props

    return (
      <Modal onMount={ref => (this.modal = ref)}
        nonDismissable={config.nonDismissable}
        onHide={this.hideModal}
        forceNormalLayout>
        {this.renderModal()}
      </Modal>
    )
  }
}

function mapStatetoProps ({ app }) {
  return {
    modal: app.modal.current,
    config: app.modal.config
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ closeModal }, dispatch)
}

export default connect(mapStatetoProps, mapDispatchToProps)(ModalWrapper)
