import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from '@framework'

import './Submit.scss'

class ConnectedButton extends Component {
  render () {
    const { isLoading, disabled, children, ...attr } = this.props
    return (
      <Button {...attr}
        disabled={isLoading || disabled}
        type='submit'>
        {children}
      </Button>
    )
  }
}

function mapStateToProps ({ app }) {
  return {
    isLoading: app.loadStack.length > 0
  }
}

export const Submit = connect(mapStateToProps)(ConnectedButton)