import {
  OPEN_MODAL,
  CLOSE_MODAL,
  SET_ENABLE_GAME_TIP_VALUE,
  SET_ENABLE_LUCKY_WHEEL_VALUE
} from '../reducers/app.reducer'
import * as agentService from '../services/agent.api'


export function openModal ({ modal, config }) {
  return dispatch => {

    dispatch({
      type: OPEN_MODAL,
      payload: { modal, config }
    })
  }
}

export function closeModal() {
  return dispatch => {
    dispatch({
      type: CLOSE_MODAL
    })
  }
}

export function toggleGameTip() {
  return async dispatch => {
    const { data: { isEnableGameTip } } = await agentService.toggleGameTips({ type: 'toggle' })

    dispatch({
      type: SET_ENABLE_GAME_TIP_VALUE,
      payload: { isEnableGameTip }
    })
  }
}

export function getGameTipVisibility() {
  return async dispatch => {
    const { data: { isEnableGameTip } } = await agentService.toggleGameTips({ type: 'show' })
    dispatch({
      type: SET_ENABLE_GAME_TIP_VALUE,
      payload: { isEnableGameTip }
    })
  }
}

export function toggleLuckyWheel() {
  return async dispatch => {
    const { data: { isEnableLuckyWheel } } = await agentService.toggleLuckyWheel({ type: 'toggle' })

    dispatch({
      type: SET_ENABLE_LUCKY_WHEEL_VALUE,
      payload: { isEnableLuckyWheel }
    })
  }
}

export function getLuckyWheelVisibility() {
  return async dispatch => {
    const { data: { isEnableLuckyWheel } } = await agentService.toggleLuckyWheel({ type: 'show' })
    dispatch({
      type: SET_ENABLE_LUCKY_WHEEL_VALUE,
      payload: { isEnableLuckyWheel }
    })
  }
}