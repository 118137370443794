import { SUCCESS_MODAL, ERROR_MODAL } from '@components/ModalWrapper'
// ActionTypes
export const START_INT_LOADING = '[APP] Start Interruptive Loading'
export const END_INT_LOADING = '[APP] End Interruptive Loading'
export const START_STACK_LOADING = '[APP] Start Stack Loading'
export const END_STACK_LOADING = '[APP] End Stack Loading'
export const OPEN_MODAL = '[APP] Open Modal'
export const CLOSE_MODAL = '[APP] Close Modal'
export const UPDATE_SUCCESS = '[APP] Update Success'
export const UPDATE_ERROR = '[APP] Update Error'
export const DO_NOTHING = '[APP] Do Nothing'

export const SET_ENABLE_GAME_TIP_VALUE = '[APP] Set Enable Game Tip Value'
export const SET_ENABLE_LUCKY_WHEEL_VALUE = '[APP] Set Enable Lucky Wheel Value'

// For testing
export const INIT = '[APP] Init State'

const initialState = {
  loadStack: [],
  intLoadStack: [],
  modal: {
    current: null,
    config: {
      nonDismissable: false
    }
  },
  error: null,
  success: {
    header: 'This is great!',
    message: 'Succesfully updated!',
    closeButtonText: 'Got it'
  },
  gameTipVisibility: true,
  luckyWheelVisibility: true
}

export default function (state = initialState, { payload, type, app: init }) {
  let updatedStack
  switch (type) {
    case START_INT_LOADING:
      return { ...state,
        intLoadStack: [...state.intLoadStack, payload] }
    case END_INT_LOADING:
      updatedStack = state.intLoadStack.filter(item => item !== payload)
      return { ...state,
        intLoadStack: updatedStack }
    case START_STACK_LOADING:
      return { ...state,
        loadStack: [...state.loadStack, payload] }
    case END_STACK_LOADING:
      updatedStack = state.loadStack.filter(item => item !== payload)
      return { ...state,
        loadStack: updatedStack }

    case OPEN_MODAL: {
      const { modal, config } = payload
      return {
        ...state,
        modal: {
          ...state.modal,
          current: modal,
          config: typeof config === 'object' ? { ...config } : initialState.modal.config
        }
      }
    }

    case CLOSE_MODAL: {
      return {
          ...state,
        modal: initialState.modal
      }
    }

    case UPDATE_SUCCESS: {
      return {
        ...state,
        modal: {
          current: SUCCESS_MODAL,
          config: initialState.modal.config
        },
        success: {
          ...state.success,
          ...payload
        }
      }
    }

    case UPDATE_ERROR: {
      return {
        ...state,
        modal: {
          current: ERROR_MODAL,
          config: initialState.modal.config
        },
        error: payload
      }
    }

    case SET_ENABLE_GAME_TIP_VALUE: {
      const { isEnableGameTip } = payload
      return {
        ...state,
        gameTipVisibility: isEnableGameTip
      }
    }

    case SET_ENABLE_LUCKY_WHEEL_VALUE: {
      const { isEnableLuckyWheel } = payload
      return {
        ...state,
        luckyWheelVisibility: isEnableLuckyWheel
      }
    }

    case INIT:
      return { ...state,
        ...init }
    case DO_NOTHING:
    default:
      return state
  }
}
