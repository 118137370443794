export const randomRangeProb = (min, max) => Math.random() * (max - min ) + min;

export const randomRangeWhole = (min, max) => Math.floor(Math.random() * (max - min + 1 ) + min);

export const maskPhoneNumber = (phoneNumber) => {
  if(!phoneNumber) {
    return ''
  }
  const COUNTRY_CODE = '+60';
  const centerKeyword = phoneNumber.substring(5, 9);
  const maskedPhoneNumber = phoneNumber.replace(centerKeyword, '****');
  const splitCountryCode = maskedPhoneNumber.split(COUNTRY_CODE);
  const splitedPhoneNumber = splitCountryCode[splitCountryCode.length - 1]
    ? splitCountryCode[splitCountryCode.length - 1]
    : '15****194';

  return `0${splitedPhoneNumber}`;
};

export const justifyChar = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const mapFilterObjectToArray = (object) => Object.keys(object).map(key => {
  return {
    key,
    value: object[key],
    type: 'contains'
  }
});


export const roundDecimal = (val) => val.toFixed(2);