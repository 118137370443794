import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Page, Input, Breadcrumb, Crumb, Submit, Button, Form, ToggleGroup, Tab, Select, Flex, P } from '@framework'
import { createAdmin, authenticate, deleteAdmin } from '@store/actions/auth.action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ADMIN_BANKS } from '@constants/banks'
import { updateProductBalance, updateBankBalance } from '@store/actions/report.action';
import { batchSetScore, getBatchSetscorestatus, resetBatchSetscorestatus } from '@store/actions/messaging.action';

import { AdminsTable } from '@components/Tables'

import './Overview.scss'

const TAB_CREATE = 'create'
const TAB_DELETE = 'delete'
const TAB_UPDATE_BALANCE = 'update-balance'
const TAB_BATCH_SET_SCORE = 'batch-set-score'

const INPUT_BANK = 'bankCode'
const INPUT_REMARK = 'remark'
const INPUT_BALANCE = 'balance'
const INPUT_SCORE = 'score'

const BANK = 'bank';
const PRODUCT = 'product';
const MAYBANK = 'MBB';
const TRANSFER_IN = 'transfer-in';
const TRANSFER_OUT = 'transfer-out';
const BANK_CHARGE = 'bank-charge';

class CredentialPage extends Component {

  constructor(props){
    super(props)
    this.state = {
      page: TAB_CREATE,
      adminUserName: '',
      adminPassword: '',
      password: '',
      [BANK]: {
        [INPUT_BANK]: MAYBANK,
        [INPUT_REMARK]: TRANSFER_IN,
        balance: null
      },
      [PRODUCT]: {
        productCode: 'mega',
        [INPUT_REMARK]: TRANSFER_IN,
        balance: null
      },
      [TAB_BATCH_SET_SCORE]: {
        [INPUT_SCORE]: 0,
        [INPUT_REMARK]: '',
      }
    }
  }

  componentDidMount() {
    this.props.getBatchSetscorestatus();
  }

  handleInputChange (state, event) {
    this.setState({ [state]: event.target.value })
  }

  handleNestedInputChange(state, nestedState, event) {
    const currentState = this.state[state];
    currentState[nestedState] = event.target.value
    this.setState({ [state]: currentState })
  }

  handleToggle (selected) {
    this.setState({
      page: selected
    })
  }

  authenticate() {
    const { password } = this.state;
    this.props.authenticate({ password });
    this.setState({ password: '' })
  }

  createAdmin() {
    const { adminUserName, adminPassword } = this.state;
    this.props.createAdmin({ 
      username: adminUserName,
      password: adminPassword
     });
    this.setState({ 
      adminUserName: '',
      adminPassword: ''
    })
  }

  deleteAdmin() {
    const { adminUserName } = this.state;
    this.props.deleteAdmin({ username: adminUserName });
    this.setState({ adminUserName: '' })
  }

  updateBankBalance() {
    this.props.updateBankBalance(this.state.bank);
  }

  updateProductBalance() {
    this.props.updateProductBalance(this.state.product);
  }

  batchSetScore() {
    this.props.batchSetScore(this.state[TAB_BATCH_SET_SCORE]);
  }

  renderSelectedPage () {
    const { adminUserName, adminPassword, bank, product } = this.state;
    switch(this.state.page) {
      case TAB_CREATE:
        return (
          <Form onSubmit={this.createAdmin.bind(this)}>
            <Input label='User Name'
              value={adminUserName}
              type='text'
              onChange={this.handleInputChange.bind(this, 'adminUserName')} />
            <Input label='Password'
              value={adminPassword}
              type='password'
              onChange={this.handleInputChange.bind(this, 'adminPassword')} />
            <Submit color='primary'>Create</Submit>
          </Form> 
        )
      case TAB_DELETE:
        return (
          <Form onSubmit={this.deleteAdmin.bind(this)}>
            <Input label='User Name'
              value={adminUserName}
              type='text'
              onChange={this.handleInputChange.bind(this, 'adminUserName')} />
            <Submit color='danger'>Delete</Submit>
          </Form> 
        )

      case TAB_UPDATE_BALANCE:
        return (
          <Flex>
            <Form onSubmit={this.updateBankBalance.bind(this)}>
              <Input label='Bank Amount'
                value={bank.balance}
                type='number'
                step='0.01'
                onChange={this.handleNestedInputChange.bind(this, BANK, INPUT_BALANCE )} />

              <Select value={bank[INPUT_BANK]} label='Bank'
                onChange={this.handleNestedInputChange.bind(this, BANK, INPUT_BANK)}>
                {ADMIN_BANKS.map(({code, label}) => (<option key={code} value={code}>{label}</option>))}
              </Select>
     
              <Select label='Remark' value={bank[INPUT_REMARK]} 
                onChange={this.handleNestedInputChange.bind(this, BANK, INPUT_REMARK)}>
                <option value={TRANSFER_IN}>Transfer In</option>
                <option value={TRANSFER_OUT}>Transfer Out</option>
                <option value={BANK_CHARGE}>Bank Charge</option>
              </Select>
              <Submit disabled={!bank.balance} color='primary'>Update</Submit>
            </Form> 

            <Form onSubmit={this.updateProductBalance.bind(this)}>
              <Input label='Mega Amount'
                value={product.balance}
                type='number'
                step='0.01'
                onChange={this.handleNestedInputChange.bind(this, PRODUCT, INPUT_BALANCE)} />

              <Select label='Remark' value={product[INPUT_REMARK]} 
                onChange={this.handleNestedInputChange.bind(this, PRODUCT, INPUT_REMARK)}>
                <option value={TRANSFER_IN}>Transfer In</option>
                <option value={TRANSFER_OUT}>Transfer Out</option>
              </Select>
              <Submit disabled={!product.balance} color='primary'>Update</Submit>
            </Form> 
          </Flex>
        )

      case TAB_BATCH_SET_SCORE:
          const { processStatus: { success, total, receive } } = this.props;
        return (
          <Flex>
            <Form onSubmit={this.batchSetScore.bind(this)}>
              <Input label='Score'
                value={this.state[TAB_BATCH_SET_SCORE][INPUT_SCORE]}
                type='number'
                step='0.01'
                onChange={this.handleNestedInputChange.bind(this, TAB_BATCH_SET_SCORE, INPUT_SCORE)} />
              <Input label='Remark'
                value={this.state[TAB_BATCH_SET_SCORE][INPUT_REMARK]}
                type='text'
                onChange={this.handleNestedInputChange.bind(this,  TAB_BATCH_SET_SCORE, INPUT_REMARK)} />

              <Submit color='success'>Send</Submit>
              <Button onClick={this.props.getBatchSetscorestatus.bind(this)} color='primary'>Get Status</Button>
              <Button onClick={this.props.resetBatchSetscorestatus.bind(this)} color='secondary'>Reset Batch</Button>
        
              <Flex className='batch-set-info' column>
                <P> Total User: {total}</P>
                <P> User Received: {receive}</P>
                <Flex>
                  <P className='status-text'>
                    Status: {receive} / {total}
                  </P>
                  { success ? <i className="fas fa-check-circle success" /> : <i class="fas fa-exclamation-circle fail"></i>}
                </Flex>
              </Flex>
           
            
            </Form>
            
          </Flex>
         
        )
      default:
    }
  }

  renderAdminTable() {
    switch(this.state.page) {
      case TAB_CREATE:
      case TAB_DELETE:
        return <AdminsTable />

      default:
        return
    }
  }


  renderForm() {
    const { isPermit } = this.props;
    const { password } = this.state;

    return (
      <div>
        {isPermit ? 
        <div>
          <ToggleGroup
            selected={this.state.page}
            onChange={(selected) => this.handleToggle(selected)}>
            <Tab value={TAB_CREATE}>Create Admin</Tab>
            <Tab value={TAB_DELETE}>Delete Admin</Tab>
            <Tab value={TAB_UPDATE_BALANCE}>Update Balance</Tab>
            <Tab value={TAB_BATCH_SET_SCORE}>Batch Send</Tab>

          </ToggleGroup>
          {this.renderSelectedPage()}
          {this.renderAdminTable()}

        </div>
        :
          <Form onSubmit={this.authenticate.bind(this)}>
            <Input label='Password'
              value={password}
              type='password'
              onChange={this.handleInputChange.bind(this, 'password')} />
            <Submit color='primary'>Submit</Submit>
          </Form>
        }
      </div>
    )
  }

  render () {
    return (
      <Page fluid paperWhite className='scope-user-overview'>
        <Helmet title='OPS Admin: Credential Overview' />
        <Breadcrumb>
          <Crumb to='/manage/workspace'>Home</Crumb>
          <Crumb>Credential</Crumb>
        </Breadcrumb>
        {this.renderForm()}
      </Page>
    )
  }
}

export default connect(
  ({
    auth: { isPermit },
    message: { batchSetScoreProcess }
  }) => ({
    isPermit,
    processStatus: batchSetScoreProcess
  }), 
  dispatch => bindActionCreators({
    createAdmin,
    authenticate,
    deleteAdmin,
    updateBankBalance,
    updateProductBalance,
    batchSetScore,
    getBatchSetscorestatus,
    resetBatchSetscorestatus
  }, dispatch)
)(CredentialPage)