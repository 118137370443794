import * as messagingService from '../services/messaging.api';
import * as agentService from '../services/agent.api';

import {
  SEND_PLAYER_SMS,
  SEND_ALL_PLAYER_SMS,
  SEND_MANUAL_SMS,
  PUBLISH_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  GET_ANNOUNCEMENT,
  SEND_BIRTHDATE_PLAYER_SMS,
  PUBLISH_DOWNLOAD_LINK,
  TRIGGER_BATCH_SET_SCORE,
  GET_BATCH_SET_SCORE_STATUS,
  RESET_BATCH_SET_SCORE_STATUS
} from '../reducers/messaging.reducer'

export function sendAllPlayerSms({ content }) {
    return async dispatch => {
      await messagingService.smsAllPlayer({ content })
      dispatch({
        type: SEND_ALL_PLAYER_SMS,
        payload: {
          content
        }
      })
    }
}

export function sendPlayerSms({ phoneNumber, phoneNumberList, content }) {
  return async dispatch => {
    if (phoneNumber) {
      await messagingService.smsToPlayers({ phoneNumber, content })

      dispatch({
        type: SEND_PLAYER_SMS,
        payload: {
          phoneNumber,
          content
        }
      })
    } else if(phoneNumberList){
      const phoneNumbers = phoneNumberList.split(',')
      await messagingService.smsToPlayers({ phoneNumbers, content })
      dispatch({
        type: SEND_MANUAL_SMS,
        payload: {
          phoneNumbers,
          content
        }
      })
    }
    
  }
}

export function publishAnnouncement({ announcement }) {
  return async dispatch => {
    await messagingService.publishAnnouncement({ announcement })
    dispatch({
      type: PUBLISH_ANNOUNCEMENT,
      payload: {
        announcement
      }
    })
  }
}

export function deleteAnnouncement() {
  return async dispatch => {
    await messagingService.deleteAnnouncement()
    dispatch({
      type: DELETE_ANNOUNCEMENT,
    })
  }
}

export function getAnnouncement() {
  return async dispatch => {
    const { data: { message } } = await messagingService.getAnnouncement()
    dispatch({
      type: GET_ANNOUNCEMENT,
      payload: {
        announcement: message
      }
    })
  }
}

export function publishDownloadLink({ downloadLink }) {
  return async dispatch => {
    await messagingService.publishDownloadLink({ downloadLink })
    dispatch({
      type: PUBLISH_DOWNLOAD_LINK,
      payload: {
        downloadLink
      }
    })
  }
}


export function sendBirthdatePlayerSms({ content }) {
  return async dispatch => {
    await messagingService.smsToBirthdatePlayers({ content })
    dispatch({
      type: SEND_BIRTHDATE_PLAYER_SMS,
      payload: {
        content
      }
    })
  }
}

export function batchSetScore({ score, remark }) {
  return async dispatch => {

    await agentService.batchSetScore({ score, remark })
    dispatch({ type: TRIGGER_BATCH_SET_SCORE })
  }
}

export function getBatchSetscorestatus() {
  return async dispatch => {
    const { data } = await agentService.getBatchSetScoreStatus();
    dispatch({
      type: GET_BATCH_SET_SCORE_STATUS,
      payload: { batchSetScoreProcess: data }
    })
  }
}

export function resetBatchSetscorestatus() {
  return async dispatch => {
    await agentService.resetBatchSetScoreStatus();
    const { data } = await agentService.getBatchSetScoreStatus();

    dispatch({
      type: RESET_BATCH_SET_SCORE_STATUS,
    })

    dispatch({
      type: GET_BATCH_SET_SCORE_STATUS,
      payload: { batchSetScoreProcess: data }
    })


  }
}