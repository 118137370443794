import * as paymentService from '../services/payment.api'
import * as agentService from '../services/agent.api'
import * as syncService from '../services/sync.api'
import {
  INIT_PENDING_WITHDRAWAL,
  APPEND_PENDING_WITHDRAWAL,
  INIT_PROCESSING_WITHDRAWAL,
  APPEND_PROCESSING_WITHDRAWAL,
  APPEND_APPROVED_WITHDRAWAL,
  INIT_APPROVED_WITHDRAWAL,
  APPEND_REJECTED_WITHDRAWAL,
  INIT_REJECTED_WITHDRAWAL,
  APPEND_TRANSACTION_HISTORY,
  INIT_TRANSACTION_HISTORY,
  APPROVE_WITHDRAWAL,
  REVERSE_WITHDRAWAL,
  REJECT_WITHDRAWAL,
  SUCCESS_WITHDRAWAL,
  SET_REJECT_WITHDRAWAL_ID,
  INIT_PENDING_CASH_DEPOSIT,
  APPEND_PENDING_CASH_DEPOSIT,
  INIT_APPROVED_CASH_DEPOSIT,
  APPEND_APPROVED_CASH_DEPOSIT,
  INIT_REJECTED_CASH_DEPOSIT,
  APPEND_REJECTED_CASH_DEPOSIT,
  APPROVE_CASH_DEPOSIT,
  REJECT_CASH_DEPOSIT,
  SET_REJECT_CASH_DEPOSIT_ID,
  INIT_ALL_USER_TRANSACTION_HISTORY,
  APPEND_ALL_USER_TRANSACTION_HISTORY,
  INIT_USERS_WIN_REPORT,
  PAGE_CHANGE_USERS_WIN_REPORT,
  APPEND_ORDER_HISTORY,
  INIT_ORDER_HISTORY,
  SET_DEPOSIT_HISTORY
} from '../reducers/transaction.reducer'
import { openModal } from './app.action'
import { SUCCESS_MODAL } from '@components/ModalWrapper'

export function approveWithdrawal ({ requestId }) {
  return async (dispatch, getState) => {
    const { data: stateData } = getState().transaction.pendingWithdrawalRequests
    await paymentService.approveWithdrawal({ requestId })
    const approveIndex = stateData.findIndex(withdrawal => withdrawal.requestId === requestId)
    const approvedWithdrawal = stateData.find(withdrawal => withdrawal.requestId === requestId)

    stateData[approveIndex] = {
      ...approvedWithdrawal,
      status: 'approved'
    }

    dispatch({
      type: APPROVE_WITHDRAWAL,
      payload: {
        data: stateData
      }
    })


    refereshPendingWithdrawal(dispatch, getState)

  }
}

export function reverseWithdrawal ({ requestId }) {
  return async (dispatch, getState) => {
    const { data: stateData } = getState().transaction.processingWithdrawalRequests
    await paymentService.reversePendingWithdrawal({ requestId })
    const reverseIndex = stateData.findIndex(withdrawal => withdrawal.requestId === requestId)
    const reversedWithdrawal = stateData.find(withdrawal => withdrawal.requestId === requestId)

    stateData[reverseIndex] = {
      ...reversedWithdrawal,
      status: 'reversed'
    }

    dispatch({
      type: REVERSE_WITHDRAWAL,
      payload: {
        data: stateData
      }
    })


    refereshProcessingWithdrawal(dispatch, getState)

  }
}

export function payoutSuccessWithdrawal ({ requestId, selectedBank }) {
  return async (dispatch, getState) => {
    const { data: stateData } = getState().transaction.processingWithdrawalRequests;
    await paymentService.payoutSucessWithdrawal({ requestId, selectedBank })
    const successIndex = stateData.findIndex(withdrawal => withdrawal.requestId === requestId)
    const successWithdrawal = stateData.find(withdrawal => withdrawal.requestId === requestId)

    stateData[successIndex] = {
      ...successWithdrawal,
      status: 'success'
    }

    dispatch({
      type: SUCCESS_WITHDRAWAL,
      payload: {
        data: stateData
      }
    })


    refereshProcessingWithdrawal(dispatch, getState)

  }
}

export function rejectWithdrawal ({ requestId, reason }) {
  return async (dispatch, getState) => {
    const { data: stateData } = getState().transaction.pendingWithdrawalRequests
    await paymentService.rejectWithdrawal({ requestId, reason })
    
    const rejectIndex = stateData.findIndex(withdrawal => withdrawal.requestId === requestId)
    const rejectedWithdrawal = stateData.find(withdrawal => withdrawal.requestId === requestId)

    stateData[rejectIndex] = {
      ...rejectedWithdrawal,
      status: 'rejected'
    }

    dispatch(openModal({ modal: SUCCESS_MODAL }));
    dispatch({
      type: REJECT_WITHDRAWAL,
      payload: {
        data: stateData
      }
    })
    refereshPendingWithdrawal(dispatch, getState)

  }
}

export function refreshPendingWithdrawal() {
  return (dispatch, getState) => {
    refereshPendingWithdrawal(dispatch, getState)
  }
}

export async function refereshPendingWithdrawal(dispatch, getState) {
    const { pageSize: statePageSize } = getState().transaction.pendingWithdrawalRequests
    const { data, nextKey } = await paymentService.listPendingWithdrawalRequests({ pageSize: statePageSize })

    const playerIds = data.map(player => player.cognitoId)
    const turnOvers = await syncService.listTurnOvers({ playerIds })
  
    const mappedData = data.map(player => ({
      ...player,
      ...turnOvers[player.cognitoId]
    }))
    dispatch({
      type: INIT_PENDING_WITHDRAWAL,
      payload: {
        nextKey,
        data: mappedData,
        currentPage: 1,
        lastUpdatedPage: 1,
      }
    })
}

export function refreshProcessingWithdrawal() {
  return (dispatch, getState) => {
    refereshProcessingWithdrawal(dispatch, getState)
  }
}

async function refereshProcessingWithdrawal(dispatch, getState){
  const { pageSize: statePageSize } = getState().transaction.pendingWithdrawalRequests
  const { data, nextKey } = await paymentService.listWithdrawalProcessingRequests({ pageSize: statePageSize })

  dispatch({
    type: INIT_PROCESSING_WITHDRAWAL,
    payload: {
      nextKey,
      data: data,
      currentPage: 1,
      lastUpdatedPage: 1,
    }
  })
}


export function setRejectWithdrawalId ({ requestId }) {
  return (dispatch, getState) => {

    dispatch({
      type: SET_REJECT_WITHDRAWAL_ID,
      payload: {
        requestId
      }
    })
  }
}

export function listPendingWithdrawalRequests ({ requestPage = 1 } = {}) {
  return async (dispatch, getState) => {
    const {
      nextKey: stateNextKey,
      lastUpdatedPage: stateLastUpdatedPage,
      pageSize: statePageSize
    } = getState().transaction.pendingWithdrawalRequests

    if (requestPage && requestPage > stateLastUpdatedPage) {
      const { data, nextKey } = await paymentService.listPendingWithdrawalRequests({
        pageSize: statePageSize,
        nextKey: stateNextKey
      })

      const playerIds = data.map(player => player.cognitoId)
      const turnOvers = await syncService.listTurnOvers({ playerIds })

      const mappedData = data.map(player => ({
        ...player,
        ...turnOvers[player.cognitoId]
      }))

      if (data.length > 0) {
        dispatch({
          type: APPEND_PENDING_WITHDRAWAL,
          payload: {
            currentPage: requestPage,
            lastUpdatedPage: requestPage,
            nextKey,
            data: mappedData
          }
        })
      } else {
        dispatch({
          type: APPEND_PENDING_WITHDRAWAL,
          payload: { nextKey: null }
        })
      }

    } else if (requestPage > 0 && requestPage <= stateLastUpdatedPage) {
      dispatch({
        type: APPEND_PENDING_WITHDRAWAL,
        payload: {
          currentPage: requestPage,
          nextKey: stateNextKey
        }
      })
    }
  }
}

export function listProcessingWithdrawalRequest ({ requestPage = 1 } = {}) {
  return async (dispatch, getState) => {
    const {
      nextKey: stateNextKey,
      lastUpdatedPage: stateLastUpdatedPage,
      pageSize: statePageSize
    } = getState().transaction.processingWithdrawalRequests

    if (requestPage && requestPage > stateLastUpdatedPage) {
      const { data, nextKey } = await paymentService.listWithdrawalProcessingRequests({
        pageSize: statePageSize,
        nextKey: stateNextKey
      })

      if (data.length > 0) {
        dispatch({
          type: APPEND_PROCESSING_WITHDRAWAL,
          payload: {
            currentPage: requestPage,
            lastUpdatedPage: requestPage,
            nextKey,
            data: data
          }
        })
      } else {
        dispatch({
          type: APPEND_PROCESSING_WITHDRAWAL,
          payload: { nextKey: null }
        })
      }

    } else if (requestPage > 0 && requestPage <= stateLastUpdatedPage) {
      dispatch({
        type: APPEND_PROCESSING_WITHDRAWAL,
        payload: {
          currentPage: requestPage,
          nextKey: stateNextKey
        }
      })
    }
  }
}


export function listApprovedWithdrawalRequests ({ requestPage = 1, startDate, endDate } = {}) {
  return async (dispatch, getState) => {
    const {
      nextKey: stateNextKey,
      lastUpdatedPage: stateLastUpdatedPage,
      pageSize: statePageSize,
      startDate: stateStartDate,
      endDate: stateEndDate
    } = getState().transaction.approvedWithdrawalRequests

    const DATE_RANGE_CHANGED = startDate !== stateStartDate || endDate !== stateEndDate
    const REQUEST_FOR_NEW_PAGE = requestPage && requestPage > stateLastUpdatedPage
    const NOT_REQUEST_FOR_NEW_PAGE = requestPage > 0 && requestPage <= stateLastUpdatedPage

    if (DATE_RANGE_CHANGED) {
      const { data, nextKey } = await paymentService.listApprovedWithdrawalRequests({
        pageSize: statePageSize,
        nextKey: stateNextKey,
        startDate,
        endDate
      })

      dispatch({
        type: INIT_APPROVED_WITHDRAWAL,
        payload: {
          currentPage: requestPage,
          lastUpdatedPage: requestPage,
          nextKey,
          data,
          startDate,
          endDate
        }
      })

    } else if (REQUEST_FOR_NEW_PAGE) {
      const { data, nextKey } = await paymentService.listApprovedWithdrawalRequests({
        pageSize: statePageSize,
        nextKey: stateNextKey,
        startDate: stateStartDate,
        endDate: stateEndDate
      })

      if (data.length > 0) {
        dispatch({
          type: APPEND_APPROVED_WITHDRAWAL,
          payload: {
            currentPage: requestPage,
            lastUpdatedPage: requestPage,
            nextKey,
            data
          }
        })
      } else {
        dispatch({
          type: APPEND_APPROVED_WITHDRAWAL,
          payload: { nextKey: null }
        })
      }

    } else if (NOT_REQUEST_FOR_NEW_PAGE) {
      dispatch({
        type: APPEND_APPROVED_WITHDRAWAL,
        payload: {
          currentPage: requestPage,
          nextKey: stateNextKey
        }
      })
    }
  }
}

export function listRejectedWithdrawalRequests ({ requestPage = 1, startDate, endDate } = {}) {
  return async (dispatch, getState) => {
    const {
      nextKey: stateNextKey,
      lastUpdatedPage: stateLastUpdatedPage,
      pageSize: statePageSize,
      startDate: stateStartDate,
      endDate: stateEndDate
    } = getState().transaction.rejectedWithdrawalRequests

    const DATE_RANGE_CHANGED = startDate !== stateStartDate || endDate !== stateEndDate
    const REQUEST_FOR_NEW_PAGE = requestPage && requestPage > stateLastUpdatedPage
    const NOT_REQUEST_FOR_NEW_PAGE = requestPage > 0 && requestPage <= stateLastUpdatedPage

    if (DATE_RANGE_CHANGED) {
      const { data, nextKey } = await paymentService.listRejectedWithdrawalRequests({
        pageSize: statePageSize,
        nextKey: stateNextKey,
        startDate,
        endDate
      })

      dispatch({
        type: INIT_REJECTED_WITHDRAWAL,
        payload: {
          currentPage: requestPage,
          lastUpdatedPage: requestPage,
          nextKey,
          data,
          startDate,
          endDate
        }
      })

    } else if (REQUEST_FOR_NEW_PAGE) {
      const { data, nextKey } = await paymentService.listRejectedWithdrawalRequests({
        pageSize: statePageSize,
        nextKey: stateNextKey,
        startDate: stateStartDate,
        endDate: stateEndDate
      })

      if (data.length > 0) {
        dispatch({
          type: APPEND_REJECTED_WITHDRAWAL,
          payload: {
            currentPage: requestPage,
            lastUpdatedPage: requestPage,
            nextKey,
            data
          }
        })
      } else {
        dispatch({
          type: APPEND_REJECTED_WITHDRAWAL,
          payload: { nextKey: null }
        })
      }

    } else if (NOT_REQUEST_FOR_NEW_PAGE) {
      dispatch({
        type: APPEND_REJECTED_WITHDRAWAL,
        payload: {
          currentPage: requestPage,
          nextKey: stateNextKey
        }
      })
    }
  }
}

export function approveCashDeposit ({ id }) {
  return async (dispatch, getState) => {
    const { data: stateData } = getState().transaction.pendingCashDepositRequests
    await paymentService.approveCashDeposit({ id })
    
    const approveIndex = stateData.findIndex(cashDeposit => cashDeposit.id === id)
    const approvedCashDeposit = stateData.find(cashDeposit => cashDeposit.id === id)

    stateData[approveIndex] = {
      ...approvedCashDeposit,
      status: 'approved'
    }

    dispatch({
      type: APPROVE_CASH_DEPOSIT,
      payload: {
        data: stateData
      }
    })
    refereshPendingCashDeposit(dispatch, getState)
    
  }
}

export function rejectCashDeposit ({ id, reason }) {
  return async (dispatch, getState) => {
    const { data: stateData } = getState().transaction.pendingCashDepositRequests
    await paymentService.rejectCashDeposit({ id, reason })
    
    const rejectIndex = stateData.findIndex(cashDeposit => cashDeposit.id === id)
    const rejectedCashDeposit = stateData.find(cashDeposit => cashDeposit.id === id)

    stateData[rejectIndex] = {
      ...rejectedCashDeposit,
      status: 'rejected'
    }

    dispatch(openModal({ modal: SUCCESS_MODAL }));
    dispatch({
      type: REJECT_CASH_DEPOSIT,
      payload: {
        data: stateData
      }
    })
    refereshPendingCashDeposit(dispatch, getState)
  }
}

export function refreshPendingCashDeposit() {
  return (dispatch, getState) => {
    refereshPendingCashDeposit(dispatch, getState)
  }
}

export async function refereshPendingCashDeposit(dispatch, getState){
  const { pageSize: statePageSize } = getState().transaction.pendingCashDepositRequests
  const { data, nextKey } = await paymentService.listCashDeposit({
    status: 'pending',
    pageSize: statePageSize 
  })

  dispatch({
    type: INIT_PENDING_CASH_DEPOSIT,
    payload: {
      nextKey,
      data: data,
      currentPage: 1,
      lastUpdatedPage: 1,
    }
  })
}

export function setRejectCashDepositId ({ requestId }) {
  return (dispatch, getState) => {

    dispatch({
      type: SET_REJECT_CASH_DEPOSIT_ID,
      payload: {
        requestId
      }
    })
  }
}

export function listPendingCashDepositRequests ({ requestPage = 1 } = {}) {
  return async (dispatch, getState) => {
    const {
      nextKey: stateNextKey,
      lastUpdatedPage: stateLastUpdatedPage,
      pageSize: statePageSize
    } = getState().transaction.pendingCashDepositRequests

    if (requestPage && requestPage > stateLastUpdatedPage) {
      const { data, nextKey } = await paymentService.listCashDeposit({
        status: 'pending',
        pageSize: statePageSize,
        nextKey: stateNextKey
      })

      if (data.length > 0) {
        dispatch({
          type: APPEND_PENDING_CASH_DEPOSIT,
          payload: {
            currentPage: requestPage,
            lastUpdatedPage: requestPage,
            nextKey,
            data
          }
        })
      } else {
        dispatch({
          type: APPEND_PENDING_CASH_DEPOSIT,
          payload: { nextKey: null }
        })
      }

    } else if (requestPage > 0 && requestPage <= stateLastUpdatedPage) {
      dispatch({
        type: APPEND_PENDING_CASH_DEPOSIT,
        payload: {
          currentPage: requestPage,
          nextKey: stateNextKey
        }
      })
    }
  }
}

export function listApprovedCashDepositRequests ({ requestPage = 1, startDate, endDate } = {}) {
  return async (dispatch, getState) => {
    const {
      nextKey: stateNextKey,
      lastUpdatedPage: stateLastUpdatedPage,
      pageSize: statePageSize,
      startDate: stateStartDate,
      endDate: stateEndDate
    } = getState().transaction.approvedCashDepositRequests

    const DATE_RANGE_CHANGED = startDate !== stateStartDate || endDate !== stateEndDate
    const REQUEST_FOR_NEW_PAGE = requestPage && requestPage > stateLastUpdatedPage
    const NOT_REQUEST_FOR_NEW_PAGE = requestPage > 0 && requestPage <= stateLastUpdatedPage

    if (DATE_RANGE_CHANGED) {
      const { data, nextKey } = await paymentService.listCashDeposit({
        status: 'success',
        pageSize: statePageSize,
        nextKey: stateNextKey,
        startDate,
        endDate
      })

      dispatch({
        type: INIT_APPROVED_CASH_DEPOSIT,
        payload: {
          currentPage: requestPage,
          lastUpdatedPage: requestPage,
          nextKey,
          data,
          startDate,
          endDate
        }
      })

    } else if (REQUEST_FOR_NEW_PAGE) {
      const { data, nextKey } = await paymentService.listCashDeposit({
        status: 'success',
        pageSize: statePageSize,
        nextKey: stateNextKey,
        startDate: stateStartDate,
        endDate: stateEndDate
      })

      if (data.length > 0) {
        dispatch({
          type: APPEND_APPROVED_CASH_DEPOSIT,
          payload: {
            currentPage: requestPage,
            lastUpdatedPage: requestPage,
            nextKey,
            data
          }
        })
      } else {
        dispatch({
          type: APPEND_APPROVED_CASH_DEPOSIT,
          payload: { nextKey: null }
        })
      }

    } else if (NOT_REQUEST_FOR_NEW_PAGE) {
      dispatch({
        type: APPEND_APPROVED_CASH_DEPOSIT,
        payload: {
          currentPage: requestPage,
          nextKey: stateNextKey
        }
      })
    }
  }
}

export function listRejectedCashDepositRequests ({ requestPage = 1, startDate, endDate } = {}) {
  return async (dispatch, getState) => {
    const {
      nextKey: stateNextKey,
      lastUpdatedPage: stateLastUpdatedPage,
      pageSize: statePageSize,
      startDate: stateStartDate,
      endDate: stateEndDate
    } = getState().transaction.rejectedCashDepositRequests

    const DATE_RANGE_CHANGED = startDate !== stateStartDate || endDate !== stateEndDate
    const REQUEST_FOR_NEW_PAGE = requestPage && requestPage > stateLastUpdatedPage
    const NOT_REQUEST_FOR_NEW_PAGE = requestPage > 0 && requestPage <= stateLastUpdatedPage

    if (DATE_RANGE_CHANGED) {
      const { data, nextKey } = await paymentService.listCashDeposit({
        status: 'fail',
        pageSize: statePageSize,
        nextKey: stateNextKey,
        startDate,
        endDate
      })

      dispatch({
        type: INIT_REJECTED_CASH_DEPOSIT,
        payload: {
          currentPage: requestPage,
          lastUpdatedPage: requestPage,
          nextKey,
          data,
          startDate,
          endDate
        }
      })

    } else if (REQUEST_FOR_NEW_PAGE) {
      const { data, nextKey } = await paymentService.listCashDeposit({
        status: 'fail',
        pageSize: statePageSize,
        nextKey: stateNextKey,
        startDate: stateStartDate,
        endDate: stateEndDate
      })

      if (data.length > 0) {
        dispatch({
          type: APPEND_REJECTED_CASH_DEPOSIT,
          payload: {
            currentPage: requestPage,
            lastUpdatedPage: requestPage,
            nextKey,
            data
          }
        })
      } else {
        dispatch({
          type: APPEND_REJECTED_CASH_DEPOSIT,
          payload: { nextKey: null }
        })
      }

    } else if (NOT_REQUEST_FOR_NEW_PAGE) {
      dispatch({
        type: APPEND_REJECTED_CASH_DEPOSIT,
        payload: {
          currentPage: requestPage,
          nextKey: stateNextKey
        }
      })
    }
  }
}

export function listTransactionHistory ({ requestPage = 1, playerId, startDate, endDate } = {}) {
  return async (dispatch, getState) => {
    const {
      nextKey: stateNextKey,
      lastUpdatedPage: stateLastUpdatedPage,
      pageSize: statePageSize,
      startDate: stateStartDate,
      endDate: stateEndDate,
      playerId: statePlayerId,
    } = getState().transaction.history

    const DATE_RANGE_CHANGED = startDate !== stateStartDate || endDate !== stateEndDate
    const PLAYER_CHANGED = playerId !== statePlayerId
    const REQUEST_FOR_NEW_PAGE = requestPage && requestPage > stateLastUpdatedPage
    const NOT_REQUEST_FOR_NEW_PAGE = requestPage > 0 && requestPage <= stateLastUpdatedPage

    if (DATE_RANGE_CHANGED || PLAYER_CHANGED) {
      const { data, nextKey } = await agentService.listTransactions({
        pageSize: statePageSize,
        playerId,
        startDate,
        endDate
      })

      dispatch({
        type: INIT_TRANSACTION_HISTORY,
        payload: {
          currentPage: requestPage,
          lastUpdatedPage: requestPage,
          nextKey,
          data,
          playerId,
          startDate,
          endDate
        }
      })

    } else if (REQUEST_FOR_NEW_PAGE) {
      const { data, nextKey } = await agentService.listTransactions({
        pageSize: statePageSize,
        startDate: stateStartDate,
        endDate: stateEndDate,
        playerId: playerId,
        nextKey: stateNextKey
      })

      if (data.length > 0) {
        dispatch({
          type: APPEND_TRANSACTION_HISTORY,
          payload: {
            currentPage: requestPage,
            lastUpdatedPage: requestPage,
            playerId: statePlayerId,
            nextKey,
            data
          }
        })
      } else {
        dispatch({
          type: APPEND_TRANSACTION_HISTORY,
          payload: { nextKey: null }
        })
      }

    } else if (NOT_REQUEST_FOR_NEW_PAGE) {
      dispatch({
        type: APPEND_TRANSACTION_HISTORY,
        payload: {
          currentPage: requestPage,
          nextKey: stateNextKey
        }
      })
    }
  }
}

export function listOrderHistory ({ requestPage = 1, playerId, startDate, endDate } = {}) {
  return async (dispatch, getState) => {
    const {
      nextKey: stateNextKey,
      lastUpdatedPage: stateLastUpdatedPage,
      pageSize: statePageSize,
      startDate: stateStartDate,
      endDate: stateEndDate,
      playerId: statePlayerId,
    } = getState().transaction.orderHistory

    const DATE_RANGE_CHANGED = startDate !== stateStartDate || endDate !== stateEndDate
    const PLAYER_CHANGED = playerId !== statePlayerId
    const REQUEST_FOR_NEW_PAGE = requestPage && requestPage > stateLastUpdatedPage
    const NOT_REQUEST_FOR_NEW_PAGE = requestPage > 0 && requestPage <= stateLastUpdatedPage

    if (DATE_RANGE_CHANGED || PLAYER_CHANGED) {
      const { data, nextKey } = await paymentService.listOrderHistory({
        pageSize: statePageSize,
        playerId,
        startDate,
        endDate
      })

      dispatch({
        type: INIT_ORDER_HISTORY,
        payload: {
          currentPage: requestPage,
          lastUpdatedPage: requestPage,
          nextKey,
          data,
          playerId,
          startDate,
          endDate
        }
      })

    } else if (REQUEST_FOR_NEW_PAGE) {
      const { data, nextKey } = await paymentService.listOrderHistory({
        pageSize: statePageSize,
        startDate: stateStartDate,
        endDate: stateEndDate,
        playerId: playerId,
        nextKey: stateNextKey
      })

      if (data.length > 0) {
        dispatch({
          type: APPEND_ORDER_HISTORY,
          payload: {
            currentPage: requestPage,
            lastUpdatedPage: requestPage,
            playerId: statePlayerId,
            nextKey,
            data
          }
        })
      } else {
        dispatch({
          type: APPEND_ORDER_HISTORY,
          payload: { nextKey: null }
        })
      }

    } else if (NOT_REQUEST_FOR_NEW_PAGE) {
      dispatch({
        type: APPEND_ORDER_HISTORY,
        payload: {
          currentPage: requestPage,
          nextKey: stateNextKey
        }
      })
    }
  }
}

export function initAllUserTransaction({ requestPage = 1, startDate, endDate, filter = {} } = {}) {
  return async (dispatch, getState) => {
    const {
      pageSize: statePageSize,
    } = getState().transaction.allUserHistory
    const { data, nextKey } = await agentService.listAllUserTransactions({
      pageSize: statePageSize,
      startDate,
      endDate,
      filter 
    })

    const { data: total} = await agentService.getTotalDepositWithdrawal({
      startDate,
      endDate
    })

    dispatch({
      type: INIT_ALL_USER_TRANSACTION_HISTORY,
      payload: {
        currentPage: requestPage,
        lastUpdatedPage: requestPage,
        nextKey,
        data,
        total,
        startDate,
        endDate,
        filter
      }
    })

  }
}

export function appendAllUserTransactionHistory ({ requestPage = 1 } = {}) {
  return async (dispatch, getState) => {
    const {
      nextKey: stateNextKey,
      lastUpdatedPage: stateLastUpdatedPage,
      pageSize: statePageSize,
      startDate: stateStartDate,
      endDate: stateEndDate,
      filter: stateFilter
    } = getState().transaction.allUserHistory

    const REQUEST_FOR_NEW_PAGE = requestPage && requestPage > stateLastUpdatedPage
    const NOT_REQUEST_FOR_NEW_PAGE = requestPage > 0 && requestPage <= stateLastUpdatedPage


    if (REQUEST_FOR_NEW_PAGE) {
      const { data, nextKey } = await agentService.listAllUserTransactions({
        pageSize: statePageSize,
        nextKey: stateNextKey,
        startDate: stateStartDate,
        endDate: stateEndDate,
        filter: stateFilter
      })
 
      if (data.length > 0) {
        dispatch({
          type: APPEND_ALL_USER_TRANSACTION_HISTORY,
          payload: {
            currentPage: requestPage,
            lastUpdatedPage: requestPage,
            nextKey,
            data,
          }
        })
      } else {
        dispatch({
          type: APPEND_ALL_USER_TRANSACTION_HISTORY,
          payload: { nextKey: null }
        })
      }

    } else if (NOT_REQUEST_FOR_NEW_PAGE) {
      dispatch({
        type: APPEND_ALL_USER_TRANSACTION_HISTORY,
        payload: {
          currentPage: requestPage,
          nextKey: stateNextKey
        }
      })
    }
  }
}

export function listWinReport ({ requestPage = 1 , winAmount, startDate, endDate } = {}) {
  return async (dispatch, getState) => {

    const { data } = await agentService.listUsersWinReport({ winAmount, startDate, endDate})

    dispatch({
      type: INIT_USERS_WIN_REPORT,
      payload: {
        currentPage: requestPage,
        lastUpdatedPage: requestPage,
        data,
        winAmount,
        startDate,
        endDate
      }
    })


  }
}


export function pageChangeUsersWinReport({ requestPage }) {
  return (dispatch, getState) => {
    dispatch({
      type: PAGE_CHANGE_USERS_WIN_REPORT,
      payload: {
        currentPage: requestPage,
        lastUpdatedPage: requestPage,
      }
    })
  }
}

export function viewDepositHistory({ id, type }) {
  return async (dispatch, getState) => {
    let data = [];
    switch (type) {
      case 'pending':
        data  = getState().transaction.pendingWithdrawalRequests.data
        break;
      case 'rejected':
        data  = getState().transaction.rejectedWithdrawalRequests.data
        break;
      case 'approved':
        data  = getState().transaction.approvedWithdrawalRequests.data
        break;
      default:
        break

    }

    dispatch({
      type: SET_DEPOSIT_HISTORY,
      payload: {
        data: data.find(tx => tx.id === id)
      }
    })
    
  }
}

