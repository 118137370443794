export let API_BASEURL;


if(process.env.APP_ENV === 'localdev') {
  API_BASEURL = 'http://localhost:3000/api' 
}else if(process.env.APP_ENV === 'localdevAdmin') {
  API_BASEURL ='http://localhost:3001/api'
} else {
  API_BASEURL = 'https://api-dev.spins2win.com'
}

export const SOCKET_BASEURL = 'wss://wss-dev.spins2win.com/agent'

export const CONTENTFUL_SPACEID = 'iyk78hysyfwo'
export const CONTENTFUL_ACCESS_TOKEN = 'x-LoEf3jst3mZNmapsVpnn5wRP-wyyTdVebnaHWA064'