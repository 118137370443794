import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Page, Breadcrumb, Crumb } from '@framework'
import { AllUserTransactionTable } from '@components/Tables'


class AllUserTransactionOverviewPage extends Component {
  render () {
    return (
      <Page paperWhite className='scope-transaction-overview'>
        <Helmet title='OPS Admin: All User Transaction Overview' />
        <Breadcrumb>
          <Crumb to='/manage/workspace'>Home</Crumb>
          <Crumb>All User Transaction Overview</Crumb>
        </Breadcrumb>
        <AllUserTransactionTable/>
      </Page>
    )
  }
}

export default AllUserTransactionOverviewPage