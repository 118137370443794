import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { Table, Button, Input, Form, Submit } from '@framework'
import { getSliceData } from '@helpers/table-data-manipulator'

import { listPlayers, initPlayerList } from '@store/actions/player.action'
import { history } from '@store'
import { maskPhoneNumber } from '@helpers/utils';

class PlayerTable extends Component {

  constructor(props){
    super(props);
    this.state ={
      phoneNumber: ''
    }
  }

  componentDidMount () {
    this.props.initPlayerList()
  }

  pushRoute (playerId) {
    history.push(`/manage/player/${playerId}/detail`)
  }

  columns () {
    const { isMaster } = this.props;
    return [
      {
        Header: 'Phone',
        accessor: 'phone_number',
        Cell: row => (
          <div>{isMaster ? row.value : maskPhoneNumber(row.value)}</div>
        )
      },
      {
        Header: 'Created at',
        accessor: 'UserCreateDate',
        Cell: row => (
          <div>{moment(new Date(row.value)).format('DD MMM YYYY')}</div>
        )
      }, {
        Header: 'Balance',
        accessor: 'balance'
      }, {
        Header: 'Point',
        accessor: 'point'
      }, {
        Header: 'Total Bet Amount',
        accessor: 'totalBetAmount'
      }, {
        Header: 'Status',
        accessor: 'agentStatus',
        Cell: row => (
          <div>{row.value === 'disabled' ? 'Disabled' : 'Enabled'}</div>
        )
      }, {
        accessor: 'Username',
        Cell: row => (
          <Button color='primary' onClick={this.pushRoute.bind(this, row.value)}>Details</Button>
        )
      }
    ]
  }

  handlePageChange (newPage) {
    this.props.listPlayers({ requestPage: newPage })
  }

  handleInputChange (state, event) {
    this.setState({ [state]: event.target.value })
  }

  render () {
    const { data, currentPage, pageSize, isLastPage, listPlayers } = this.props
    const { phoneNumber } = this.state
    return(
      <div>
      <Form onSubmit={listPlayers.bind(this, this.state)}>
        <Input label='Search Player' placeholder='eg. 012345678' value={phoneNumber}
          onChange={this.handleInputChange.bind(this, 'phoneNumber')} />
        <Submit color='primary' disabled={!phoneNumber}>Submit</Submit>
      </Form>

        <Table data={data}
          centeredContent
          disableNextButton={isLastPage}
          currentPage={currentPage}
          defaultPageSize={pageSize}
          showPageSizeOptions={false}
          minRows={pageSize}
          onRefresh={this.props.initPlayerList.bind(this)}
          onPageChange={(newPage) => this.handlePageChange(newPage)}
          columns={this.columns()} />
      </div>
    ) 
   
  }
}

export default connect(
  ({ 
    auth: {
      isMaster
    },
    player: {
      list: {
        data, currentPage, pageSize, isLastPage
      }
    }
  }) => ({
    isMaster,
    currentPage,
    pageSize,
    isLastPage,
    data: getSliceData({ data, currentPage, pageSize })
  }), 
  dispatch => bindActionCreators({
    listPlayers,
    initPlayerList
  }, dispatch)
)(PlayerTable)
