export const MONTHS = [{
  value: '0',
  shortLabel: 'Jan',
  longLabel: 'January'
}, {
  value: '1',
  shortLabel: 'Feb',
  longLabel: 'February'
}, {
  value: '2',
  shortLabel: 'Mar',
  longLabel: 'March'
}, {
  value: '3',
  shortLabel: 'Apr',
  longLabel: 'April'
}, {
  value: '4',
  shortLabel: 'May',
  longLabel: 'May'
}, {
  value: '5',
  shortLabel: 'Jun',
  longLabel: 'June'
}, {
  value: '6',
  shortLabel: 'Jul',
  longLabel: 'July'
}, {
  value: '7',
  shortLabel: 'Aug',
  longLabel: 'August'
}, {
  value: '8',
  shortLabel: 'Sep',
  longLabel: 'September'
}, {
  value: '9',
  shortLabel: 'Oct',
  longLabel: 'October'
}, {
  value: '10',
  shortLabel: 'Nov',
  longLabel: 'November'
}, {
  value: '11',
  shortLabel: 'Dec',
  longLabel: 'December'
}];

export const DAYS = [
  {
    value: '0',
    label: 'Sunday'
  },
  {
    value: '1',
    label: 'Monday'
  },
  {
    value: '2',
    label: 'Tuesday'
  },
  {
    value: '3',
    label: 'Wednesday'
  },
  {
    value: '4',
    label: 'Thursday'
  },
  {
    value: '5',
    label: 'Friday'
  },
  {
    value: '6',
    label: 'Saturday'
  },
]