import React from 'react'
import './ToggleSwitch.scss'

export const ToggleSwitch = (props) => {
  const {
    checked,
    onSwitch
  } = props

  return (
    <div className="switch-container">
      <label className="switch">
        <input type="checkbox" checked={checked} onChange={onSwitch} />
        <span className="slider round"></span>
      </label>
    </div>
  )
}
