import { authInterceptedAxios } from './interceptors'

export function listRetry () {
  return authInterceptedAxios({
    method: 'get',
    url: '/retry/admin-list-retry-set-score',
    loadKey: 'Fetching retry set score',
    isInterruptive: false
  })
}


export function retrySetScore ({ retryId }) {
    return authInterceptedAxios({
      method: 'post',
      url: '/retry/admin-retry-set-score',
      data: { retryId },
      loadKey: 'Retrying set score',
      isInterruptive: true
    })
  }