import { authInterceptedAxios } from './interceptors'

export function approveWithdrawal ({ requestId }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/payment/admin-withdrawal-approve',
    data: { requestId },
    loadKey: 'Submitting Approval',
    isInterruptive: true
  })
}

export function reversePendingWithdrawal ({ requestId }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/payment/admin-withdrawal-reverse-pending',
    data: { requestId },
    loadKey: 'Submitting Reverse',
    isInterruptive: true
  })
}

export function payoutSucessWithdrawal ({ requestId, selectedBank }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/payment/admin-withdrawal-payout-success',
    data: { requestId, selectedBank },
    loadKey: 'Submitting Approval',
    isInterruptive: true
  })
}

export function rejectWithdrawal ({ requestId, reason }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/payment/admin-withdrawal-reject',
    data: { requestId, reason },
    loadKey: 'Submitting Reject',
    isInterruptive: true
  })
}

export function listWithdrawalProcessingRequests ({ pageSize, nextKey } = {}) {
  return authInterceptedAxios({
    method: 'get',
    url: '/payment/admin-list-withdrawal',
    loadKey: 'Listing Withdrawal Processing Request',
    isInterruptive: true,
    params: {
      status: 'processing',
      limit: pageSize,
      nextKey
    }
  })
}

export function listPendingWithdrawalRequests ({ pageSize, nextKey } = {}) {
  return authInterceptedAxios({
    method: 'get',
    url: '/payment/admin-list-withdrawal',
    loadKey: 'Listing Pending Withdrawal Request',
    isInterruptive: true,
    params: {
      status: 'pending',
      limit: pageSize,
      nextKey
    }
  })
}

export function listApprovedWithdrawalRequests ({ pageSize, nextKey, startDate, endDate } = {}) {
  return authInterceptedAxios({
    method: 'get',
    url: '/payment/admin-list-withdrawal',
    loadKey: 'Listing Approved Withdrawal Request',
    isInterruptive: true,
    params: {
      limit: pageSize,
      status: 'success',
      nextKey,
      startDate,
      endDate
    }
  })
}

export function listRejectedWithdrawalRequests ({ pageSize, nextKey, startDate, endDate } = {}) {
  return authInterceptedAxios({
    method: 'get',
    url: '/payment/admin-list-withdrawal',
    loadKey: 'Listing Rejected Withdrawal Request',
    isInterruptive: true,
    params: {
      limit: pageSize,
      status: 'fail',
      nextKey,
      startDate,
      endDate
    }
  })
}

export function listCashDeposit ({ status, pageSize, nextKey, startDate, endDate  } = {}) {
  return authInterceptedAxios({
    method: 'get',
    url: '/payment/admin-list-cash-deposit',
    loadKey: 'Listing Approved Cash Deposit Request',
    isInterruptive: true,
    params: {
      status: status,
      limit: pageSize,
      nextKey,
      startDate,
      endDate
    }
  })
}

export function approveCashDeposit ({ id }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/payment/admin-cash-deposit-approve',
    data: { id },
    loadKey: 'Submitting Approval',
    isInterruptive: true
  })
}

export function rejectCashDeposit ({ id, reason }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/payment/admin-cash-deposit-reject',
    data: { id, reason },
    loadKey: 'Submitting Reject',
    isInterruptive: true
  })
}

export function listOrderHistory ({ playerId, pageSize, nextKey, startDate, endDate  }) {
  return authInterceptedAxios({
    method: 'get',
    url: `/payment/admin-list-order/${playerId}`,
    params: {
      limit: pageSize,
      nextKey,
      startDate,
      endDate
    },
    loadKey: 'Listing Order',
    isInterruptive: true
  })
}

export function listBankTallyReport ({ banks, startDate, endDate }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/payment-report/admin-bank-balance-report',
    params: { startDate, endDate },
    data: { banks },
    loadKey: 'Listing Bank Report',
    isInterruptive: true
  })
}

export function updateBankBalance ({ bankCode, balance, remark }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/payment-report/admin-update-balance',
    data: { bankCode, balance, remark },
    loadKey: 'Update Bank Balance',
    isInterruptive: true,
    successPrompt: {
      header: 'Success',
      message: 'Update Bank Balance Success'
    }
  })
}
