import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Flex, Button, ToggleSwitch } from '@framework'

import { getAgentScore } from '@store/actions/auth.action'
import {
  toggleGameTip,
  toggleLuckyWheel
} from '@store/actions/app.action';

import './AgentScoreBalance.scss'

class AgentScoreBalance extends Component {

  render () {
    const { score, enableGameTip, enableLuckyWheel } = this.props
    return (
      <Flex noGrow className='component-agent-score-balance'>
        <Flex noGrow >
          <div className='toggle-swithc-label' >Lucky Wheel: </div>
          <ToggleSwitch checked={enableLuckyWheel} onSwitch={this.props.toggleLuckyWheel}/>
        </Flex>
        <Flex noGrow >
          <div className='toggle-swithc-label' >Games TIP: </div>
          <ToggleSwitch checked={enableGameTip} onSwitch={this.props.toggleGameTip}/>
        </Flex>
        <Flex column noGrow>
          <div>Agent Balance: {score ? score.toFixed(2) : '-.--'}</div>
        </Flex>
        <Flex noGrow>
          <div>
            <Button className='refresh-button' onClick={this.props.getAgentScore}><i className="fas fa-sync" /></Button>
          </div>
        </Flex>
      </Flex> 
    )
  }
}

export default connect(
  ({ auth, app }) => ({
    score: auth.agentScoreBalance,
    enableGameTip: app.gameTipVisibility,
    enableLuckyWheel: app.luckyWheelVisibility
  }), 
  dispatch => bindActionCreators({
    getAgentScore,
    toggleGameTip,
    toggleLuckyWheel
  }, dispatch)
)(AgentScoreBalance)
