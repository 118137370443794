import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Table, Button } from '@framework'
import { getSliceData } from '@helpers/table-data-manipulator'
import { REJECT_WITHDRAWAL_MODAL } from '@components/ModalWrapper'
import { openModal } from '@store/actions/app.action'
import { maskPhoneNumber } from '@helpers/utils';
import { history } from '@store'

import {
  listPendingWithdrawalRequests,
  approveWithdrawal,
  setRejectWithdrawalId,
  refreshPendingWithdrawal,
  viewDepositHistory
} from '@store/actions/transaction.action'

class PendingWithdrawalRequestsTable extends Component {


  openRejectModal(requestId){
    this.props.setRejectWithdrawalId({ requestId })
    this.props.openModal({ modal: REJECT_WITHDRAWAL_MODAL });
  }

  columns () {
    const { approveWithdrawal, isMaster } = this.props

    return [
      {
        Header: "User Info",
        columns: [
          {
            Header: "Username",
            accessor: "phoneNumber",
            Cell: row => ( <div>{isMaster ? row.value : maskPhoneNumber(row.value)}</div>)
          },
          {
            Header: "Game ID",
            accessor: "playerUsername"
          }
        ]
      },
      {
        Header: "Bank Info",
        columns: [
          {
            Header: "Name",
            accessor: "bankAccountName"
          },
          {
            Header: "Bank",
            accessor: "bankCode"
          },
          {
            Header: "Account",
            accessor: "bankAccountNumber" 
          }
        ]
      },
      {
        Header: "Turn Over",
        columns: [
          {
            Header: "Current (Slot)",
            accessor: "totalBetAmount"
          },
          {
            Header: "Current (BG)",
            accessor: "liveBGAmount"
          },
          {
            Header: "Current (EG)",
            accessor: "totalLiveBetAmount"
          },
          {
            Header: "Limit",
            accessor: "withdrawalLimit"
          }
        ]
      },
      {
        Header: "Withdraw",
        accessor: "score"
      },
      {
        Header: "Balance",
        accessor: "balance"
      },
      {
        Header: "Action",
        columns: [
          {
            accessor: 'id',
            Cell: row => (
              <Button color='success' onClick={this.pushRoute.bind(this, row.value)}>History</Button>
            )
          },
          {
            id: 'approval',
            accessor: acc => ({
              requestId: acc.id,
              status: acc.status
            }),
            Cell: row => row.value.status === 'approved' ? (
              <div>
                <span>Approved </span>
                <i className="fas fa-check-circle" />
              </div>
            ):(
              <Button color='primary' onClick={() => approveWithdrawal({ requestId: row.value.requestId })}>Approve</Button>
            )
          },
          {
            id: 'reject',
            accessor: acc => ({
              requestId: acc.id,
              status: acc.status
            }),
            Cell: row => row.value.status === 'rejected' ? (
              <div>
                <span>Rejected </span>
                <i className="fas fa-check-circle" />
              </div>
            ):(
                <Button color='danger' onClick={this.openRejectModal.bind(this, row.value.requestId)}>Reject</Button>
            )
          }
        ]
      }
    ]
  }

  handlePageChange (newPage) {
    this.props.listPendingWithdrawalRequests({ requestPage: newPage })
  }

  pushRoute (id) {
    this.props.viewDepositHistory({ id, type: 'pending' });
    history.push(`/manage/withdrawal/${id}`)
  }

  getTrProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      return {
        style: {
          background: rowInfo.original.isWithdrawBonus ? 'orange': '',
          color: 'black'
        }
      }
    }
    return {};
  }

  render () {
    const { data, currentPage, pageSize, isLastPage } = this.props

    return <Table data={data}
            centeredContent
            disableNextButton={isLastPage}
            currentPage={currentPage}
            defaultPageSize={pageSize}
            showPageSizeOptions={false}
            minRows={pageSize}
            getTrProps={this.getTrProps}
            onRefresh={this.props.refreshPendingWithdrawal.bind(this)}
            onPageChange={(newPage) => this.handlePageChange(newPage)}
            columns={this.columns()} />
  }
}

export default connect(
  ({ 
    auth: {
      isMaster
    },
    transaction: {
      pendingWithdrawalRequests: {
        data, currentPage, pageSize, isLastPage
      }
    }
  }) => ({
    isMaster,
    currentPage,
    pageSize,
    isLastPage,
    data: getSliceData({ data, currentPage, pageSize })
  }), 
  dispatch => bindActionCreators({
    listPendingWithdrawalRequests,
    approveWithdrawal,
    setRejectWithdrawalId,
    refreshPendingWithdrawal,
    viewDepositHistory,
    openModal
  }, dispatch)
)(PendingWithdrawalRequestsTable)
