import React from 'react'
import { Route } from 'react-router-dom'
import uniqueId from 'lodash.uniqueid'
import { history } from '@store'

export const renderRoutes = (routes) => {
  return routes.map(route => (
    <Route
      key={uniqueId()}
      exact={route.exact || false}
      path={route.path || null}
      component={route.component}
    />
  ))
}

export const pathContain = (path) => {
  return history.location.pathname.match(path)
}