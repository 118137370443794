import React, { Component } from 'react'
import moment from 'moment'
import { Select } from '@framework'
import { MONTHS } from '@constants/datetime'

const INPUT_MONTH = 'month'
const INPUT_YEAR = 'year'

export class MonthYearPicker extends Component {
  constructor (props) {
    super(props)
    this.state = {
      [INPUT_MONTH]: MONTHS[new Date().getMonth()].value,
      [INPUT_YEAR]: new Date().getFullYear()
    }
  }

  componentDidMount () {
    const { onChange } = this.props
    const { month, year } = this.state
    
    onChange && onChange({
      startDate: moment(new Date(year, month)).startOf('month').toDate(),
      endDate: moment(new Date(year, month)).endOf('month').toDate()
    })
  }

  async handleInputChange (state, event) {
    await this.setState({ [state]: event.target.value })
    
    const { onChange } = this.props
    const { month, year } = this.state

    onChange && onChange({
      startDate: moment(new Date(year, month)).startOf('month').toDate(),
      endDate: moment(new Date(year, month)).endOf('month').toDate()
    })
  }

  renderYears () {
    const result = [];
    for (let i = 0; i < 3; i++) {
      const year = new Date().getFullYear() - i;
      result.push(<option key={year} value={year}>{year}</option>)
    }

    return result
  }

  render () {
    return (
      <div className='af-month-year-picker'>
        <Select value={this.state.month} onChange={this.handleInputChange.bind(this, INPUT_MONTH)} inline>
          {MONTHS.map(({value, shortLabel}) => (<option key={value} value={value}>{shortLabel}</option>))}
        </Select>
        <Select value={this.state.year} onChange={this.handleInputChange.bind(this, INPUT_YEAR)} inline>
          {this.renderYears()}
        </Select>
      </div>
    )
  }
}
