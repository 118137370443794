import React from 'react'

import './Form.scss'

export const Form = (props) => {
  const { children, onSubmit } = props

  const submit = (event) => {
    event.preventDefault()
    onSubmit()
  }

  return (
    <form onSubmit={submit}>
      {children}
    </form>
  )
}
