import { combineReducers } from 'redux'
import appReducer from './app.reducer'
import authReducer from './auth.reducer'
import transactionReducer from './transaction.reducer'
import playerReducer from './player.reducer'
import messagingReducer from './messaging.reducer'
import retryReducer from './retry.reducer'
import reportReducer from './report.reducer'
import websocketReducer from './websocket.reducer'


export default combineReducers({
  app: appReducer,
  auth: authReducer,
  transaction: transactionReducer,
  player: playerReducer,
  message: messagingReducer,
  retry: retryReducer,
  report: reportReducer,
  ws: websocketReducer
})
