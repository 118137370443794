import React from 'react'
import './Select.scss'

export const Select = (props) => {
  const {
    label,
    children,
    inline,
    className,
    ...attr
  } = props


    return (
      <div className={`
        af-select ${className}
        ${inline ? '--inline' : ''}
      `}>
        {label ? <label>{label}</label> : ''}
        <div className='select-wrapper'>
          <select {...attr}>
            {children}
          </select>
        </div>
      </div>
    )
}
