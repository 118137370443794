import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { H3, ModalHeader, ModalBody, ModalFooter, Submit, Button, Form, Input } from '@framework'
import { setPlayerPoint } from '@store/actions/player.action'

class SetPointModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      remark: '',
      point: '',
      playerId: props.playerDetail.Username
    }
  }

  handleInputChange (state, event) {
    this.setState({ [state]: event.target.value })
  }

  render () {
    const { setPlayerPoint, modal } = this.props
    const { point, remark } = this.state

    return (
      <Form onSubmit={setPlayerPoint.bind(this, this.state)}>
        <ModalHeader color='primary'>
          <H3>Update Point</H3>
        </ModalHeader>
        <ModalBody>
          <Input label='Point'
            value={point} type='number'
            onChange={this.handleInputChange.bind(this, 'point')} />
          <Input label='Remark' value={remark}
            onChange={this.handleInputChange.bind(this, 'remark')} />
        </ModalBody>
        <ModalFooter>
          <Submit color='primary' disabled={!point || !remark}>Update</Submit>
          <Button onClick={() => modal.hide()}>Cancel</Button>
        </ModalFooter>
      </Form>
    )
  }
}

export default connect(
  ({ player:
    { 
      list: { data },
      view: { listDataIndex }
    }
  }) => ({
    playerDetail: data[listDataIndex] ? data[listDataIndex] : {}
  }), 
  dispatch => bindActionCreators({
    setPlayerPoint
  }, dispatch)
  )(SetPointModal)
