import React, { Component } from 'react'
import { connect } from 'react-redux'
import { P, H3, ModalHeader, ModalBody, ModalFooter, Button } from '@framework'

class SuccessModal extends Component {
  render () {
    const { modal, success } = this.props
    const { header, message, closeButtonText } = success
    return (
      <div>
        <ModalHeader color='success'>
          <H3>{header}</H3>
        </ModalHeader>
        <ModalBody>
          <P>{message}</P>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => modal.hide()}>{closeButtonText}</Button>
        </ModalFooter>
      </div>
    )
  }
}

function mapStatetoProps ({ app }) {
  return {
    success: app.success
  }
}

export default connect(mapStatetoProps)(SuccessModal)
