import * as retryService from '../services/retry.api'
import * as agentService from '../services/agent.api'
import {
  INIT_RETRY_SET_SCORE,
  PAGE_CHANGE_RETRY,
  RETRY_SET_SCORE
} from '../reducers/retry.reducer'

export function listRetrySetScore({ requestPage = 1 } = {}) {
  return async (dispatch, getState) => {
    const { data } = await retryService.listRetry();

    if(data.length > 0) {
      const playerIds = [...new Set(data.map(player => player.cognitoId))];
      const players = await agentService.listUserAccountInfo({ playerIds })
      const mappedData = data.map(player => (({
        ...player,
        ...players[player.cognitoId],
        createdAt: player.createdAt
      })));

      dispatch({
        type: INIT_RETRY_SET_SCORE,
        payload: {
          data: mappedData, 
          currentPage: requestPage,
          lastUpdatedPage: requestPage
        }
      })
    }
  }
}

export function pageChangeRetry({ requestPage }) {
  return (dispatch, getState) => {
    dispatch({
      type: PAGE_CHANGE_RETRY,
      payload: {
        currentPage: requestPage,
        lastUpdatedPage: requestPage,
      }
    })
  }
}

export function retrySetScore ({ retryId }) {
  return async (dispatch, getState) => {
    const { data: stateData } = getState().retry.retrySetScoreList
    await retryService.retrySetScore({ retryId })
    const retryIndex = stateData.findIndex(retry => retry.retryId === retryId)
    const retrySetScore = stateData.find(retry => retry.retryId === retryId)

    stateData[retryIndex] = {
      ...retrySetScore,
      status: 'retry'
    }

    dispatch({
      type: RETRY_SET_SCORE,
      payload: {
        data: stateData
      }
    })
    refereshRetryList(dispatch, getState)
  }
}

export function refreshRetryList() {
   return (dispatch, getState) => {
      refereshRetryList(dispatch, getState)
   }
}

export async function refereshRetryList(dispatch, getState){
  const { data, nextKey } = await retryService.listRetry()

  if(data.length > 0 ) {
    const playerIds = [...new Set(data.map(player => player.cognitoId))];
    const players = await agentService.listUserAccountInfo({ playerIds })
    const mappedData = data.map(player => (({
      ...player,
      ...players[player.cognitoId],
      createdAt: player.createdAt
    })));
    
    dispatch({
      type: INIT_RETRY_SET_SCORE,
      payload: {
        nextKey,
        data: mappedData,
        currentPage: 1,
        lastUpdatedPage: 1,
      }
    })
  }
 

 
}