import { authInterceptedAxios } from './interceptors'

export function listTurnOvers ({ playerIds }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/sync/statistics/admin-get-players-turnover',
    data: { cognitoIds: playerIds },
    loadKey: 'Fetching turnovers',
    isInterruptive: false
  })
}
