import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Table, Input, Form, Submit, Button } from '@framework'
import { getSliceData } from '@helpers/table-data-manipulator'
import moment from 'moment'
import { listRejectedWithdrawalRequests, viewDepositHistory } from '@store/actions/transaction.action'
import { maskPhoneNumber } from '@helpers/utils';
import { history } from '@store'


class RejectedWithdrawalRequestsTable extends Component {

  constructor(props){
    super(props)
    this.state = {
      startDate: moment(new Date()).startOf('day').toDate(),
      endDate: moment(new Date()).endOf('day').toDate()
    }
  }

  columns () {
    const { isMaster } = this.props
    return [
      {
        Header: "User Info",
        columns: [
          {
            Header: "Username",
            accessor: "phoneNumber",
            Cell: row => ( <div>{isMaster ? row.value : maskPhoneNumber(row.value)}</div>)
          }
        ]
      },
      {
        Header: "Bank Info",
        columns: [
          {
            Header: "Name",
            accessor: "bankAccountName"
          },
          {
            Header: "Bank",
            accessor: "bankCode"
          },
          {
            Header: "Account",
            accessor: "bankAccountNumber" 
          }
        ]
      },
      {
        Header: "Turn Over",
        columns: [
          {
            Header: "Current",
            accessor: "totalBetAmount"
          },
          {
            Header: "Current (EG)",
            accessor: "totalLiveBetAmount"
          },
          {
            Header: "Limit",
            accessor: "withdrawalLimit"
          }
        ]
      },
      {
        Header: "Withdraw Amount",
        accessor: "score"
      },
      {
        Header: "Reason",
        accessor: "reason"
      }, 
      {
        Header: "Admin",
        accessor: "adminUsername"
      },
      {
        Header: 'Action',
        accessor: 'id',
        Cell: row => (
          <Button color='success' onClick={this.pushRoute.bind(this, row.value)}>History</Button>
        )
      },
    ]
  }

  pushRoute (id) {
    this.props.viewDepositHistory({ id, type: 'rejected' });
    history.push(`/manage/withdrawal/${id}`)
  }


  handlePageChange (newPage) {
    const { startDate, endDate } = this.props
    this.props.listRejectedWithdrawalRequests({ requestPage: newPage, startDate, endDate  })
  }

  handleInputChange (state, event) {
    this.setState({ [state]: event.target.value})
  }

  listRejectedWithdrawalRequests() {
    const { startDate, endDate } = this.state;
    this.props.listRejectedWithdrawalRequests({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    })
  }


  render () {
    const { data, currentPage, pageSize, isLastPage } = this.props

    return (
      <div>
        <Form  onSubmit={this.listRejectedWithdrawalRequests.bind(this)}>
         <Input 
            label='Start Date'
            type='datepicker'
            value={this.state.startDate}
            onChange={this.handleInputChange.bind(this, 'startDate')}/>

          <Input 
            label='End Date'
            type='datepicker'
            value={this.state.endDate}
            onChange={this.handleInputChange.bind(this, 'endDate')}/>
          <Submit color='primary'> Submit </Submit>
        </Form>
        <Table data={data}
          centeredContent
          disableNextButton={isLastPage}
          currentPage={currentPage}
          defaultPageSize={pageSize}
          showPageSizeOptions={false}
          minRows={pageSize}
          onPageChange={(newPage) => this.handlePageChange(newPage)}
          columns={this.columns()} />
      </div>
    )
  }
}

export default connect(
  ({
    auth: {
      isMaster
    },
    transaction:{
      rejectedWithdrawalRequests: {
        data, currentPage, pageSize, isLastPage, startDate, endDate
      }
    }
  }) => ({
    isMaster,
    currentPage,
    pageSize,
    isLastPage,
    startDate,
    endDate,
    data: getSliceData({ data, currentPage, pageSize })
  }), 
  dispatch => bindActionCreators({
    listRejectedWithdrawalRequests,
    viewDepositHistory
  }, dispatch)
)(RejectedWithdrawalRequestsTable)
