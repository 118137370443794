import React from 'react'

import './Flex.scss'

export const Flex = (props) => {
  const { className = '', column, noGrow, children, ...attr } = props

  const renderClassName = () => {
    return `component-flex ${className} ${column ? '--column' : ''} ${noGrow ? '--no-grow' : ''}`.trim()
  }

  return (
    <div className={renderClassName()}
      {...attr}>
      {children}
    </div>
  )
}
