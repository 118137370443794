import * as playerService from '../services/player.api'
import * as agentService from '../services/agent.api'
import * as syncService from '../services/sync.api'
import * as authService from '../services/auth.api'

import {
  INIT_PLAYER_LIST,
  SET_PLAYER_LIST,
  SET_DETAIL_PAGE_PLAYER,
  DISABLE_PLAYER,
  ENABLE_PLAYER,
  UPDATE_PLAYER_POINT,
  UPDATE_PLAYER_SCORE,
  QUERY_PLAYER_LIST,
  UPDATE_PLAYER_INFO,
  DISABLE_BONUS_PLAYER,
  ENABLE_BONUS_PLAYER,
} from '../reducers/player.reducer'

import { openModal } from './app.action'
import { SUCCESS_MODAL } from '@components/ModalWrapper'

export function initPlayerList({ requestPage = 1 } = {}) {
  return async (dispatch, getState) => {
    const {
      pageSize: statePageSize
    } = getState().player.list

    const { data, nextKey } = await playerService.listPlayers({
      pageSize: statePageSize,
    })

    const playerIds = data.map(player => player.Username)

    const [ userAccounts, turnOvers ] = await Promise.all([
      agentService.listUserAccountInfo({ playerIds }),
      syncService.listTurnOvers({ playerIds })
    ])

    const mappedData = data.map(player => ({
      ...player,
      ...userAccounts[player.Username],
      ...turnOvers[player.Username]
    }))

    if (data.length > 0) {
      dispatch({
        type: INIT_PLAYER_LIST,
        payload: {
          currentPage: requestPage,
          lastUpdatedPage: requestPage,
          data: mappedData,
          nextKey
        }
      })
    } 
  }
}

export function listPlayers ({ requestPage = 1,  phoneNumber } = {}) {
  return async (dispatch, getState) => {
    const {
      nextKey: stateNextKey,
      lastUpdatedPage: stateLastUpdatedPage,
      pageSize: statePageSize
    } = getState().player.list

    const QUERY_PLAYER = requestPage === 1 && phoneNumber;
    const REQUEST_FOR_NEW_PAGE = requestPage && requestPage > stateLastUpdatedPage; 
    if (REQUEST_FOR_NEW_PAGE) {
      const { data, nextKey } = await playerService.listPlayers({
        pageSize: statePageSize,
        nextKey: stateNextKey
      })

      const playerIds = data.map(player => player.Username)

      const [ userAccounts, turnOvers ] = await Promise.all([
        agentService.listUserAccountInfo({ playerIds }),
        syncService.listTurnOvers({ playerIds })
      ])

      const mappedData = data.map(player => ({
        ...player,
        ...userAccounts[player.Username],
        ...turnOvers[player.Username]
      }))

      if (data.length > 0) {
        dispatch({
          type: SET_PLAYER_LIST,
          payload: {
            currentPage: requestPage,
            lastUpdatedPage: requestPage,
            data: mappedData,
            nextKey
          }
        })
      } else {
        dispatch({
          type: SET_PLAYER_LIST,
          payload: { nextKey: null }
        })
      }

    } else if(QUERY_PLAYER){
      const { data, nextKey } = await playerService.listPlayers({
        phoneNumber,
        pageSize: statePageSize
      })

      const playerIds = data.map(player => player.Username)

      const [ userAccounts, turnOvers ] = await Promise.all([
        agentService.listUserAccountInfo({ playerIds }),
        syncService.listTurnOvers({ playerIds })
      ])

      const mappedData = data.map(player => ({
        ...player,
        ...userAccounts[player.Username],
        ...turnOvers[player.Username]
      }))

      dispatch({
        type: QUERY_PLAYER_LIST,
        payload: {
          currentPage: requestPage,
          lastUpdatedPage: requestPage,
          data: mappedData,
          nextKey
        }
      })
    }
    else if (requestPage > 0 && requestPage <= stateLastUpdatedPage) {
      dispatch({
        type: SET_PLAYER_LIST,
        payload: {
          currentPage: requestPage,
          nextKey: stateNextKey
        }
      })
    }
  }
}

export function toggleDisablePlayer ({ playerId }) {
  return async (dispatch, getState) => {
    const { data } = getState().player.list
    const { listDataIndex } = getState().player.view
    const playerDetail = data[listDataIndex]

    await agentService.toggleDisablePlayer({ playerId })

    dispatch({
      type: playerDetail.agentStatus === 'disabled' ?
        ENABLE_PLAYER : DISABLE_PLAYER,
      playerId
    })
  }
}

export function viewSinglePlayer ({ playerId }) {
  return (dispatch, getState) => {
    const { data } = getState().player.list

    dispatch({
      type: SET_DETAIL_PAGE_PLAYER,
      payload: {
        listDataIndex: data.findIndex(player => player.Username === playerId)
      }
    })
  }
}

export function setPlayerScore ({ playerId, score, remark }) {
  return async dispatch => {
    await agentService.setScore({ playerId, score, remark })

    dispatch(openModal({ modal: SUCCESS_MODAL }))
    dispatch({
      type: UPDATE_PLAYER_SCORE,
      payload: { score }
    })
  }
}

export function setPlayerPoint ({ playerId, point, remark }) {
  return async dispatch => {
    await agentService.setPoint({ playerId, point, remark })

    dispatch(openModal({ modal: SUCCESS_MODAL }))
    dispatch({
      type: UPDATE_PLAYER_POINT,
      payload: { point }
    })
  }
}


export function adminGetUserOtp({ phoneNumber }) {
  return async (dispatch, getState) => {
    const { data }  = await authService.adminGetUserOtp({ phoneNumber })
    dispatch({
      type: UPDATE_PLAYER_INFO,
      payload: { ...data }
    })
  }
} 


export function updateUserInfo ({ playerId, content }) {
  return async dispatch => {
    await agentService.updateUser({ playerId, note: content })
    dispatch({
      type: UPDATE_PLAYER_INFO,
      payload: { note: content }
    })
  }
}

export function toggleBonusPlayer ({ playerId }) {
  return async (dispatch, getState) => {
    const { data } = getState().player.list
    const { listDataIndex } = getState().player.view
    const playerDetail = data[listDataIndex]

    await agentService.togglePlayerBonus({ playerId })

    dispatch({
      type: playerDetail.bonusState === 'disabled' ?
        ENABLE_BONUS_PLAYER : DISABLE_BONUS_PLAYER,
      playerId
    })
  }
}


export function renewPlayerGameId ({ playerId, phoneNumber}) {
  return async (dispatch, getState) => {
    await agentService.renewPlayerGameId({ playerId, phoneNumber })
  }
}