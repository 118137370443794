import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Page, Breadcrumb, Crumb } from '@framework'
import { WinReportTable } from '@components/Tables'


class WinReportOverviewPage extends Component {
  render () {
    return (
      <Page paperWhite className='scope-transaction-overview'>
        <Helmet title='OPS Admin: Win Report Overview' />
        <Breadcrumb>
          <Crumb to='/manage/workspace'>Home</Crumb>
          <Crumb>Win Report Overview</Crumb>
        </Breadcrumb>
        <WinReportTable/>
      </Page>
    )
  }
}

export default WinReportOverviewPage