import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Table, Input, Form, Submit  } from '@framework'
import { getSliceData } from '@helpers/table-data-manipulator'

import { listOrderHistory } from '@store/actions/transaction.action'

class OrderHistoryTable extends Component {
  
  constructor (props) {
    super(props)
    this.state = {
      startDate: moment(new Date()).startOf('month').toDate(),
      endDate: moment(new Date()).endOf('month').toDate()
    }
  }

  componentDidMount () {
    const { listOrderHistory, playerId } = this.props
    const { startDate, endDate } = this.state

    listOrderHistory({
      playerId,
      startDate,
      endDate
    })
  }

  columns () {
    return [
      {
        Header: 'Timestamp',
        accessor: 'createdAt',
        Cell: row => (
          <div>{moment(new Date(row.value)).format('DD/MM/YYYY h:mm:ss a')}</div>
        )
      },
      {
        Header: 'Method',
        accessor: 'method'
      },
      {
        Header: 'Remark',
        accessor: 'remark'
      },
      {
        Header: 'Score',
        accessor: 'score' 
      },
      {
        Header: 'Bonus',
        accessor: 'bonus' 
      },
      {
        Header: 'Status',
        accessor: 'status' 
      }
    ]
  }

  handlePageChange (newPage) {
    const { startDate, endDate, playerId } = this.props
    this.props.listOrderHistory({ requestPage: newPage, playerId, startDate, endDate })
  }


  handleInputChange (state, event) {
    this.setState({ [state]: event.target.value })
  }

  listOrderHistory() {
    const { playerId } = this.props;
    const { startDate, endDate } = this.state;
    this.props.listOrderHistory({
      playerId,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    })
  }

  render () {
    const { data, currentPage, pageSize, isLastPage } = this.props

    return (
      <div>
        <Form onSubmit={this.listOrderHistory.bind(this)}>
        <Input 
          label='Start Date'
          type='datepicker'
          value={this.state.startDate}
          onChange={this.handleInputChange.bind(this, 'startDate')}/>

        <Input 
          label='End Date'
          type='datepicker'
          value={this.state.endDate}
          onChange={this.handleInputChange.bind(this, 'endDate')}/>
          <Submit> Submit </Submit>
        </Form>
        <Table data={data}
          centeredContent
          disableNextButton={isLastPage}
          currentPage={currentPage}
          defaultPageSize={pageSize}
          showPageSizeOptions={false}
          minRows={pageSize}
          onPageChange={(newPage) => this.handlePageChange(newPage)}
          columns={this.columns()} />
      </div>
    )
  }
}

export default connect(
  ({ transaction:
    { orderHistory: {
      data, currentPage, pageSize, isLastPage, startDate, endDate
    } }
  }) => ({
    currentPage,
    pageSize,
    isLastPage,
    startDate,
    endDate,
    data: getSliceData({ data, currentPage, pageSize })
  }), 
  dispatch => bindActionCreators({
    listOrderHistory
  }, dispatch)
)(OrderHistoryTable)
