import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Table, Button, Select } from '@framework'
import { getSliceData } from '@helpers/table-data-manipulator'

import {
  listProcessingWithdrawalRequest,
  payoutSuccessWithdrawal,
  reverseWithdrawal,
  refreshProcessingWithdrawal
} from '@store/actions/transaction.action'
import { maskPhoneNumber } from '@helpers/utils';
import { ADMIN_BANKS } from '@constants/banks'


class ProcessingWithdrawalRequestsTable extends Component {


  componentDidMount () {
    this.props.listProcessingWithdrawalRequest()
  }

  renderOptions() {
    return [
      (<option key={0} value={0}>----</option>),
      ...ADMIN_BANKS.map(({code, label}) => (<option key={code} value={code}>{label}</option>))
    ]
   }

  columns () {
    const { payoutSuccessWithdrawal, reverseWithdrawal, isMaster } = this.props
    return [
      {
        Header: "User Info",
        columns: [
          {
            Header: "Username",
            accessor: "phoneNumber",
            Cell: row => ( <div>{isMaster ? row.value : maskPhoneNumber(row.value)}</div>)
          }
        ]
      },
      {
        Header: "Bank Info",
        columns: [
          {
            Header: "Name",
            accessor: "bankAccountName"
          },
          {
            Header: "Bank",
            accessor: "bankCode"
          },
          {
            Header: "Account",
            accessor: "bankAccountNumber" 
          }
        ]
      },
      {
        Header: "Withdraw Amount",
        accessor: "score"
      },
      {
        Header: "Bank To Transfer",
        accessor: "selectedBank",
        width: 300,
        Cell: (props) => (
          <Select onChange={(x) => props.row.selectedBank = x.target.value}>
            {this.renderOptions()}
          </Select>
        )
      },
      {
        Header: "Action",
        columns: [
          {
            id: 'success',
            accessor: acc => ({
              requestId: acc.id,
              status: acc.status
            }),
            Cell: row => row.value.status === 'success' ? (
              <div>
                <span>Success </span>
                <i className="fas fa-check-circle"/>
              </div>
            ):(
              <Button 
                color='success'
                onClick={() => payoutSuccessWithdrawal({
                  requestId: row.value.requestId,
                  selectedBank: row.row.selectedBank
                })}>
                Success
              </Button>
            )
          },
          {
            id: 'reverse',
            accessor: acc => ({
              requestId: acc.id,
              status: acc.status
            }),
            Cell: row => row.value.status === 'reverse' ? (
              <div>
                <span>Reversed </span>
                <i className="fas fa-check-circle"/>
              </div>
            ):(
              <Button 
                color='danger'
                onClick={() => reverseWithdrawal({ requestId: row.value.requestId }) }>
                Reverse
              </Button>
            )
          }
        ]
      }
    ]
  }

  handlePageChange (newPage) {
    this.props.listProcessingWithdrawalRequest({ requestPage: newPage })
  }

  render () {
    const { data, currentPage, pageSize, isLastPage } = this.props
    return <Table data={data}
      centeredContent
      disableNextButton={isLastPage}
      currentPage={currentPage}
      defaultPageSize={pageSize}
      showPageSizeOptions={false}
      minRows={pageSize}
      onRefresh={this.props.refreshProcessingWithdrawal.bind(this)}
      onPageChange={(newPage) => this.handlePageChange(newPage)}
      columns={this.columns()} />
  }
}

export default connect(
  ({
    auth: {
      isMaster
    },
    transaction:{
      processingWithdrawalRequests: {
        data, currentPage, pageSize, isLastPage, startDate, endDate
      }
    }
  }) => ({
    isMaster,
    currentPage,
    pageSize,
    isLastPage,
    startDate,
    endDate,
    data: getSliceData({ data, currentPage, pageSize })
  }), 
  dispatch => bindActionCreators({
    listProcessingWithdrawalRequest,
    payoutSuccessWithdrawal,
    reverseWithdrawal,
    refreshProcessingWithdrawal
  }, dispatch)
)(ProcessingWithdrawalRequestsTable)
