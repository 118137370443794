import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { H3, ModalHeader, ModalBody, ModalFooter, Submit, Button, Form, Input } from '@framework'
import { setPlayerScore } from '@store/actions/player.action'

class SetScoreModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      score: '',
      remark: '',
      playerId: props.playerDetail.Username
    }
  }

  handleInputChange (state, event) {
    this.setState({ [state]: event.target.value })
  }

  render () {
    const { setPlayerScore, modal } = this.props
    const { score, remark } = this.state

    return (
      <Form onSubmit={setPlayerScore.bind(this, this.state)}>
        <ModalHeader color='primary'>
          <H3>Update Score</H3>
        </ModalHeader>
        <ModalBody>
          <Input label='Score'
            value={score} type='number'
            onChange={this.handleInputChange.bind(this, 'score')} />
          <Input label='Remark' value={remark}
            onChange={this.handleInputChange.bind(this, 'remark')} />
        </ModalBody>
        <ModalFooter>
          <Submit color='primary' disabled={!score || !remark}>Update</Submit>
          <Button onClick={() => modal.hide()}>Cancel</Button>
        </ModalFooter>
      </Form>
    )
  }
}

export default connect(
  ({ player:
    { 
      list: { data },
      view: { listDataIndex }
    }
  }) => ({
    playerDetail: data[listDataIndex] ? data[listDataIndex] : {}
  }), 
  dispatch => bindActionCreators({
    setPlayerScore
  }, dispatch)
  )(SetScoreModal)
