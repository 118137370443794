import React, { Component } from 'react'
import moment from 'moment'
import { MONTHS } from '@constants/datetime'
import { Select } from '@framework'

const startYears = 2019
const INPUT_MONTH = 'month'
const INPUT_YEAR = 'year'
const INPUT_DAY = 'day'

export class WeekPicker extends Component {
  
  constructor (props) {
    super(props)
    this.state = {
      [INPUT_MONTH]: MONTHS[new Date().getMonth()].longLabel,
      [INPUT_YEAR]: new Date().getFullYear(),
      [INPUT_DAY]: '',
      daysSelection: []

    }
  }

  componentDidMount() {
    const { firstDay = 0 } = this.props;
    this.populateDate({ ...this.state, firstDay });
  }

  removeOrdinal(day) {
    return day.replace('st', '').replace('nd', '').replace('rd', '').replace('th', '')
  }

  getRangeISODate({ year, month, day }) {
    const absDay = this.removeOrdinal(day);
    const chosenDate = new Date(`${absDay} ${month} ${year}`).toISOString();
    const startDate = moment(chosenDate).subtract(7, 'days').startOf('day').toISOString();
    const endDate = moment(chosenDate).subtract(1, 'days').endOf('day').toISOString();
    return { startDate, endDate }
  }

  async populateDate ({ year, month, firstDay }) {
    let days = [];
    const startDate= moment().month(month).year(year).startOf('month').day(firstDay);
    const endDate= moment().month(month).year(year).endOf('month');
    const startMonth =  moment().month(month).year(year).startOf('month');
  
  
    for (var m = moment(startDate); m.isBefore(endDate); m.add(7, 'days')) {
      if(m.isSameOrAfter(startMonth)) {
        days.push(m.format('Do'))
      }
    }

    await this.setState({
      [INPUT_DAY]: days[0],
      daysSelection: days
    })
    const { onChange } = this.props;

    onChange && onChange(this.getRangeISODate({ ...this.state }))

  }

  async handleDayChange (event) {
    await this.setState({
      [INPUT_DAY]: event.target.value
     })

     const { onChange } = this.props;
     onChange && onChange(this.getRangeISODate({ ...this.state }))
  }
  
  async handleMonthYearChange (state, event) {
    await this.setState({ [state]: event.target.value })

    const { firstDay = 0 } = this.props;
    this.populateDate({ ...this.state, firstDay });
  }

  renderYears() {
    const years = [];

    for(var year = startYears; year <= moment().year(); year += 1) {
      years.push(<option key={year} value={year}>{year}</option>)
    }

    return years
  }

  renderDays() {
    const { daysSelection } = this.state;
    return daysSelection.map((day) => (<option key={day} value={day}>{day}</option>))
  }

  render() {
    return(
      <div>
        <Select value={this.state.day} onChange={this.handleDayChange.bind(this)} inline>
          {this.renderDays()}
        </Select>
        <Select value={this.state.month} onChange={this.handleMonthYearChange.bind(this, INPUT_MONTH)} inline>
          {MONTHS.map(({longLabel, shortLabel}) => (<option key={longLabel} value={longLabel}>{shortLabel}</option>))}
        </Select>
        <Select value={this.state.year} onChange={this.handleMonthYearChange.bind(this, INPUT_YEAR)} inline>
          {this.renderYears()}
        </Select>
      </div>
    )
  }

}
