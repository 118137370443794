import { bindActionCreators } from 'redux'
import {
  refereshPendingWithdrawal,
  refereshPendingCashDeposit,
} from './actions/transaction.action'; 
import { refereshRetryList } from './actions/retry.action'
import { APPPEND_LIST, KEEP_ALIVE_RESPONSE, KEEP_ALIVE_REQUEST } from './reducers/websocket.reducer';
import { store } from './index';

const despoitSfx = new Audio('/sfx/cash_deposit_sfx.mp3');
const retrySfx = new Audio('/sfx/retry_sfx.mp3');
const withdrawSfx = new Audio('/sfx/withdraw_sfx.mp3');
let ws;

export const socketActionTypes = {
  SOCKET_OPEN: 'SOCKET_OPEN',
  SOCKET_CLOSE: 'SOCKET_CLOSE',
  SOCKET_ERROR: 'SOCKET_ERROR',
  SOCKET_MESSAGE: 'SOCKET_MESSAGE',
  SOCKET_CONNECT: 'SOCKET_CONNECT'
}
export const socketActionCreators = {
  socketOpen: e => ({ type: socketActionTypes.SOCKET_OPEN, payload: { ws }}),
  socketClose: e => ({ type: socketActionTypes.SOCKET_CLOSE, payload: e }),
  socketError: err => ({ type: socketActionTypes.SOCKET_ERROR, payload: err }),
  socketMessage: e => {
    if (e && e.data && JSON.parse(e.data).message !== 'Forbidden') {

      const data = JSON.parse(e.data);
      
      let dispatchResult;
      switch(data.type) {
        case 'withdrawal':
          withdrawSfx.play();
          refereshPendingWithdrawal(store.dispatch, store.getState)
          dispatchResult = { type: APPPEND_LIST, payload: { data }};
          break;

        case 'cash-deposit':
          despoitSfx.play();
          refereshPendingCashDeposit(store.dispatch, store.getState)
          dispatchResult = { type: APPPEND_LIST,  payload: { data }};
          break;

        case 'retry':
          retrySfx.play();
          refereshRetryList(store.dispatch, store.getState)
          dispatchResult = { type: APPPEND_LIST,  payload: { data }};
          break;

        case 'keepalive':
          dispatchResult = { type: KEEP_ALIVE_RESPONSE };
          break;

        default:
          dispatchResult = { type: socketActionTypes.SOCKET_MESSAGE };
          break;
      }

      return dispatchResult;
     
    }
  },
  socketConnect: e => ({ type: socketActionTypes.SOCKET_CONNECT })
}

export const createSocketMiddleware = ({
  socketURL,
  shouldInstantiate,
  eventHandlers
}) => store => next => action => {

  if (shouldInstantiate(action)) {

    ws = new WebSocket(socketURL)
    const boundEventHandlers = bindActionCreators(eventHandlers, store.dispatch)
    ws.onopen = boundEventHandlers.onopen;
    ws.onclose = boundEventHandlers.onclose
    ws.onerror = boundEventHandlers.onerror
    ws.onmessage = boundEventHandlers.onmessage
    
    setInterval(() => {
      ws.send(JSON.stringify({ action: 'keepalive' }));
      store.dispatch({ type: KEEP_ALIVE_REQUEST })
    }, 15000)
  } else {
    return next(action)
  }
}