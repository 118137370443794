import WorkspaceOverviewPage from '@pages/Workspace/Overview'
import PlayerOverviewPage from '@pages/Player/Overview'
import PlayerDetailPage from '@pages/Player/Detail'
import TransactionOverviewPage from '@pages/Withdrawal/Overview'
import DepositHistoryDetailpage from '@pages/Withdrawal/Detail'
import SmsPage from '@pages/Sms'
import CashDepositOverviewPage from '@pages/CashDeposit/Overview'
import AllUserTransactionOverviewPage from '@pages/Transactions/Overview'
import WinReportOverviewPage from '@pages/Win/Overview'
import AnnouncementOverviewPage from '@pages/Announcement/Overview'
import RetryOverviewPage from '@pages/Retry/Overview'
import ProfilePage from '@pages/Profile/Overview'
import CredentialPage from '@pages/Credential/Overview'
import ReferralOverviewPage from '@pages/Referral/Overview'
import PlayerReferralDetailPage from '@pages/Referral/Detail'
import RebateOverviewPage from '@pages/Rebate/Overview'
import PlayerRebateDetailPage from '@pages/Rebate/Detail'

import Error404 from '@pages/Error404'

export const masterWorkSpaceRoutes = [
  {
    path: '/manage/workspace',
    component: WorkspaceOverviewPage
  }, {
    path: '/manage/player/:id/detail',
    component: PlayerDetailPage
  }, {
    path: '/manage/player',
    component: PlayerOverviewPage
  }, {
    path: '/manage/withdrawal/:id',
    component: DepositHistoryDetailpage
  }, {
    path: '/manage/withdrawal',
    component: TransactionOverviewPage
  }, {
    path: '/manage/sms',
    component: SmsPage
  }, {
    path: '/manage/cash-deposit',
    component: CashDepositOverviewPage
  }, {
    path: '/manage/all-user-transactions',
    component: AllUserTransactionOverviewPage
  }, {
    path: '/manage/win-report',
    component: WinReportOverviewPage
  },{
    path: '/manage/announcement',
    component: AnnouncementOverviewPage
  }, {
    path: '/manage/retry',
    component: RetryOverviewPage
  }, {
    path: '/manage/profile',
    component: ProfilePage
  }, {
    path: '/manage/credential',
    component: CredentialPage
  }, {
    path: '/manage/referral-report/:id/detail',
    component: PlayerReferralDetailPage
  }, {
    path: '/manage/referral-report',
    component: ReferralOverviewPage
  },{
    path: '/manage/rebate-report/:id/detail',
    component: PlayerRebateDetailPage
  }, {
    path: '/manage/rebate-report',
    component: RebateOverviewPage
  }, {
    component: Error404
  }
]

export const agentWorkspaceRoutes = [
  {
    path: '/manage/workspace',
    component: WorkspaceOverviewPage
  }, {
    path: '/manage/player/:id/detail',
    component: PlayerDetailPage
  }, {
    path: '/manage/player',
    component: PlayerOverviewPage
  }, {
    path: '/manage/withdrawal',
    component: TransactionOverviewPage
  }, {
    path: '/manage/sms',
    component: SmsPage
  }, {
    path: '/manage/cash-deposit',
    component: CashDepositOverviewPage
  }, {
    path: '/manage/all-user-transactions',
    component: AllUserTransactionOverviewPage
  }, {
    path: '/manage/win-report',
    component: WinReportOverviewPage
  },{
    path: '/manage/announcement',
    component: AnnouncementOverviewPage
  }, {
    path: '/manage/retry',
    component: RetryOverviewPage
  }, {
    path: '/manage/referral-report/:id/detail',
    component: PlayerReferralDetailPage
  }, {
    path: '/manage/referral-report',
    component: ReferralOverviewPage
  },{
    path: '/manage/rebate-report/:id/detail',
    component: PlayerRebateDetailPage
  }, {
    path: '/manage/rebate-report',
    component: RebateOverviewPage
  }, {
    component: Error404
  }
]
