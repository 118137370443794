import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

class Provider extends Component {
  render () {
    const { locale, messages, children } = this.props;

    return (
      <IntlProvider key={locale} locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    );
  }
}

export const ConnectedIntlProvider = connect(
  ({ locale }) => ({
    ...locale.language
  })
)(Provider)