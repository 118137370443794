import axios from 'axios'
import { store } from '@store'
import {
  START_INT_LOADING,
  END_INT_LOADING,
  START_STACK_LOADING,
  END_STACK_LOADING,
  UPDATE_SUCCESS,
  UPDATE_ERROR
} from '@store/reducers/app.reducer'
import { API_BASEURL } from '@configs/app.config';


export default async function appRequestInterceptor ({
  isInterruptive,
  loadKey,
  headers,
  method,
  successPrompt,
  ...axiosPayload
}) {
  const payload = { method, ...axiosPayload }
  const http = axios.create({
    baseURL: API_BASEURL,
    withCredentials: true
  })

  try {
    startLoading(loadKey, isInterruptive)
    if (headers) {
      http.defaults.headers.common = headers;
      http.defaults.headers[method] = headers;
    }
    const result = await http.request(payload)

    if (typeof successPrompt === 'object') {
      updateSuccess(successPrompt)
    } else {
      console.warn('successPrompt must be an object, eg. { header: String, message: String, closeButtonText: String }')
    }

    return Promise.resolve(result.data)
  } catch (err) {
    console.error(err)
    updateError(err)
    return Promise.reject(err.response)
  } finally {
    endLoading(loadKey, isInterruptive)
  }
}

function startLoading (key, isInterruptive) {
  store.dispatch({
    type: isInterruptive ? START_INT_LOADING : START_STACK_LOADING,
    payload: key
  })
}

function endLoading (key, isInterruptive) {
  store.dispatch({
    type: isInterruptive ? END_INT_LOADING : END_STACK_LOADING,
    payload: key
  })
}

function updateSuccess (success) {
  store.dispatch({
    type: UPDATE_SUCCESS,
    payload: success
  })
}

function updateError (err) {
  store.dispatch({
    type: UPDATE_ERROR,
    payload: err
  })
}
