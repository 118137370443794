import { createBrowserHistory, createMemoryHistory } from 'history'
import { routerMiddleware } from 'react-router-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import axios from 'axios'
import rootReducer from './reducers'
import { socketActionTypes, socketActionCreators, createSocketMiddleware } from './sockets'
import { SOCKET_BASEURL } from '@configs/app.config';

const socketURL = SOCKET_BASEURL
const socketPredicate = action => action.type === socketActionTypes.SOCKET_CONNECT
const eventHandlers = {
  onopen: socketActionCreators.socketOpen,
  onclose: socketActionCreators.socketClose,
  onerror: socketActionCreators.socketError,
  onmessage: socketActionCreators.socketMessage
}


function configureStore (history, initialState = {}) {
  const enhancers = []
  const middleware = [
    thunk.withExtraArgument(axios),
    routerMiddleware(history),
    createSocketMiddleware({
      socketURL,
      shouldInstantiate: socketPredicate,
      eventHandlers
    })
  ]

  if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.devToolsExtension

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension())
    }
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  )

  const store = createStore(
    rootReducer,
    initialState,
    composedEnhancers
  )

  return store
}

export const history = process.env.NODE_ENV === 'test'
  ? createMemoryHistory()
  : createBrowserHistory()

export const store = configureStore(history)