import { INIT } from './app.reducer'
import { LOGOUT_ADMIN } from './auth.reducer'
export const INIT_PLAYER_LIST = '[Player] init Player List'
export const SET_PLAYER_LIST = '[Player] Set Player List'
export const QUERY_PLAYER_LIST = '[Player] Query Player List'
export const SET_DETAIL_PAGE_PLAYER = '[Player] Set Detail Page Player'
export const DISABLE_PLAYER = '[Player] Disable Player'
export const ENABLE_PLAYER = '[Player] Enable Player'
export const UPDATE_PLAYER_POINT = '[Player] Update Player Point'
export const UPDATE_PLAYER_SCORE = '[Player] Update Player Score'
export const UPDATE_PLAYER_INFO = '[Player] Update Player Info'

export const DISABLE_BONUS_PLAYER = '[Player] Disable Bonus Player'
export const ENABLE_BONUS_PLAYER = '[Player] Enable Bonus Player'

const initialState = {
  list: {
    currentPage: 1,
    pageSize: 10,
    lastUpdatedPage: 0,
    nextKey: null,
    isLastPage: false,
    phoneNumber: '',
    data: [],
  },
  view: {
    listDataIndex: null
  }
}

export default function (state = initialState, { payload = {}, type , transaction: init }) {
  const {
    data = [],
    nextKey,
    lastUpdatedPage,
    currentPage,
    phoneNumber
  } = payload

  switch (type) {
    case INIT_PLAYER_LIST: {
      const {
        data: stateData,
        lastUpdatedPage: stateLastUpdatedPage,
        currentPage: stateCurrentPage
      } = state.list
      const CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED
        = !nextKey && (currentPage || stateCurrentPage) === (lastUpdatedPage || stateLastUpdatedPage)
      return {
        ...state,
        list: {
          ...state.list,
          data: data ? data : stateData,
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : stateLastUpdatedPage,
          currentPage: currentPage ? currentPage : stateCurrentPage,
          isLastPage: CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED,
          nextKey
        }
      }
    }

    case SET_PLAYER_LIST: {
      const {
        data: stateData,
        lastUpdatedPage: stateLastUpdatedPage,
        currentPage: stateCurrentPage
      } = state.list
      const CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED
        = !nextKey && (currentPage || stateCurrentPage) === (lastUpdatedPage || stateLastUpdatedPage)
      return {
        ...state,
        list: {
          ...state.list,
          data: [ ...stateData, ...data ],
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : stateLastUpdatedPage,
          currentPage: currentPage ? currentPage : stateCurrentPage,
          isLastPage: CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED,
          nextKey
        }
      }
    }

    case QUERY_PLAYER_LIST: {
      const {
        data: initialData,
        lastUpdatedPage: initialLastUpdatedPage,
        currentPage: initialCurrentPage,
        nextKey: initialNextKey,
        phoneNumber: initialPhoneNumber
      } = initialState.list

      return {
        ...state,
        list: {
          ...initialState.list,
          data: data ? data : initialData,
          nextKey: nextKey ? nextKey : initialNextKey,
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : initialLastUpdatedPage,
          currentPage: currentPage ? currentPage : initialCurrentPage,
          phoneNumber: phoneNumber ? phoneNumber : initialPhoneNumber,
          isLastPage: !nextKey
        }
      }
    }

    case SET_DETAIL_PAGE_PLAYER: {
      const { listDataIndex } = payload

      return {
        ...state,
        view: {
          ...state.view,
          listDataIndex
        }
      }
    }

    case DISABLE_PLAYER : {
      const { data } = state.list
      const { listDataIndex } = state.view
      const newData = [ ...data ];

      newData[listDataIndex].agentStatus = 'disabled';

      return {
        ...state,
        list: {
          ...state.list,
          data: [ ...newData ]
        }
      }
    }

    case ENABLE_PLAYER : {
      const { data } = state.list
      const { listDataIndex } = state.view
      const newData = [ ...data ];

      newData[listDataIndex].agentStatus = 'enabled';

      return {
        ...state,
        list: {
          ...state.list,
          data: [ ...newData ]
        }
      }
    }

    case DISABLE_BONUS_PLAYER : {
      const { data } = state.list
      const { listDataIndex } = state.view
      const newData = [ ...data ];

      newData[listDataIndex].bonusState = 'disabled';

      return {
        ...state,
        list: {
          ...state.list,
          data: [ ...newData ]
        }
      }
    }

    case ENABLE_BONUS_PLAYER : {
      const { data } = state.list
      const { listDataIndex } = state.view
      const newData = [ ...data ];

      newData[listDataIndex].bonusState = 'enabled';

      return {
        ...state,
        list: {
          ...state.list,
          data: [ ...newData ]
        }
      }
    }

    case UPDATE_PLAYER_POINT: {
      const { point } = payload
      const { data } = state.list
      const { listDataIndex } = state.view
      const newData = [ ...data ];

      if (newData[listDataIndex].point) {
        newData[listDataIndex].point += Number(point)
      } else {
        newData[listDataIndex].point = Number(point)
      }

      return {
        ...state,
        list: {
          ...state.list,
          data: [ ...newData ]
        }
      }
    }
    case UPDATE_PLAYER_SCORE: {
      const { score } = payload
      const { data } = state.list
      const { listDataIndex } = state.view
      const newData = [ ...data ];

      if (newData[listDataIndex].balance) {
        newData[listDataIndex].balance += Number(score)
      } else {
        newData[listDataIndex].balance = Number(score)
      }

      return {
        ...state,
        list: {
          ...state.list,
          data: [ ...newData ]
        }
      }
    }

    case UPDATE_PLAYER_INFO: {
      const { data } = state.list
      const { listDataIndex } = state.view
      const newData = [ ...data ];
      newData[listDataIndex] = {
        ...newData[listDataIndex],
        ...payload
      };

      return {
        ...state,
        list: {
          ...state.list,
          data: [ ...newData ]
        }
      }
    }

    case LOGOUT_ADMIN:
      return initialState

    case INIT: {
      return {
        ...state,
        ...init
      }
    }

    default:
      return state
  }
}