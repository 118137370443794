import React from 'react'
import { DateTimePicker } from '@progress/kendo-react-dateinputs'

import '@progress/kendo-theme-default/dist/all.css';
import './Input.scss'

export const Input = (props) => {
  const {
    label,
    type = 'text',
    children,
    format,
    ...attr
  } = props

  switch (type) {
    case 'datepicker':
      return (
        <div className='component-input-element'>
          {label ? <label>{label}</label> : ''}
          <DateTimePicker {...attr}
              format={`${format ? format : 'dd/MM/yyyy HH:mm:ss'}`}
              width="auto"
          />
        </div>
      )
    case 'radio':
    case 'checkbox':
      return (
        <div className='component-input-element --inline'>
          <label>
            <input type={type} {...attr} />
            <span className='checkable-label'>{label}</span>
          </label>
        </div>
      )
    case 'file':
      return (
        <div className='component-input-element --inline'>
          <label>
            <input type={type} {...attr} />
          </label>
        </div>
      )
    default:
      return (
        <div className='component-input-element'>
          {label ? <label>{label}</label> : ''}
          {type === 'textarea'
            ? <textarea {...attr} />
            : <input type={type} {...attr} />}
          {children}
        </div>
      )
  }
}
