import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import { Table } from '@framework'

class DepositHistoryTable extends Component {

  columns () {
    return [
      {
        Header: "Time Stamp",
        accessor: 'timeStamp',
        Cell: row => (<div>{moment(new Date(row.value)).format('DD/MM/YYYY h:mm:ss a')}</div>)
      },
      {
        Header: "Deposit Amount",
        accessor: 'depositAmount'
      },
      {
        Header: "Bonus",
        accessor: 'bonus'
      },
      {
        Header: "Turn Over",
        accessor: 'withdrawalLimit'
      },
      {
        Header: "Bonus Type",
        accessor: 'bonusType'
      }
    ]
  }


  render () {
    const { data, currentPage } = this.props
    const { turnoverHistory } = data || {};

    if(!turnoverHistory) {
      return '';
    }

    return <Table data={turnoverHistory}
            centeredContent
            currentPage={currentPage}
            showNavigation={false}
            showPageSizeOptions={false}
            minRows={turnoverHistory.length}
            columns={this.columns()} />
  }
}

export default connect(
  ({ 
    transaction: { 
      depositHistory: {
        data,
      }
    }
  }) => ({
    data: data
  }), 
  dispatch => bindActionCreators({
  }, dispatch)
)(DepositHistoryTable)
