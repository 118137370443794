import {
  LoginPage
} from '@pages/Auth'
import Error404 from '@pages/Error404'

export const authRoutes = [
  {
    path: '/auth/login',
    component: LoginPage
  }, {
    component: Error404
  }
]
