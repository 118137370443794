import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Table, Form, Submit, WeekPicker, Button } from '@framework'
import { getSliceData } from '@helpers/table-data-manipulator'
import {
  listRebateReport,
  pageChangeRebateReport
 } from '@store/actions/report.action'
import moment from 'moment'
import { history } from '@store'
import { maskPhoneNumber } from '@helpers/utils';

class RebateReportTable extends Component {

  constructor (props) {
    super(props)
    this.state = {
      startDate: moment(new Date()).startOf('day').toDate(),
      endDate: moment(new Date()).endOf('day').toDate()
    }
  }

  pushRoute (playerId) {
    history.push(`/manage/rebate-report/${playerId}/detail`)
  }

  columns () {
    const { isMaster } = this.props;
    return [
        {
          Header: 'Username',
          accessor: 'phoneNumber',
          Cell: row => (
            <div>{isMaster ? row.value : maskPhoneNumber(row.value)}</div>
          )
        },
        {
            Header: 'Rebate Bonus',
            accessor: 'totalRebate'
        },
        {
          Header: 'Total Deposit',
          accessor: 'totalDeposit'
        },
        {
          Header: 'Action',
          accessor: 'cognitoId',
          Cell: row => (
            <Button color = 'primary' onClick={this.pushRoute.bind(this, row.value)}>Details</Button>
          )
        }
    ]
  }

  handleInputChange (data) {
    this.setState(data)
  }

  handlePageChange (newPage) {
    this.props.pageChangeRebateReport({ requestPage : newPage })
  }

  listRebateReport() {
    const { startDate, endDate } = this.state;
    this.props.listRebateReport({
      startDate,
      endDate
    })
  }

  render () {
    const { data, currentPage, pageSize, isLastPage } = this.props
    return (
      <div>
        <Form onSubmit={this.listRebateReport.bind(this)}>
          <WeekPicker firstDay='Monday' onChange={this.handleInputChange.bind(this)}/>
          <Submit color='primary'> Submit </Submit>
        </Form>
        <Table data={data}
          centeredContent
          disableNextButton={isLastPage}
          currentPage={currentPage}
          defaultPageSize={pageSize}
          showPageSizeOptions={false}
          minRows={pageSize}
          onPageChange={(newPage) => this.handlePageChange(newPage)}
          columns={this.columns()} />
      </div>
    )
  }
}

export default connect(
  ({
    auth: {
      isMaster
    },
    report:{
      rebateReport: { 
        list: {
          data,
          startDate,
          endDate,
          currentPage,
          pageSize,
          isLastPage
        }
      }
    }
  }) => ({ 
    data: getSliceData({ data, currentPage, pageSize }),
    isMaster,
    currentPage,
    pageSize,
    isLastPage,
    startDate,
    endDate
  }), 
    dispatch => bindActionCreators({
      listRebateReport,
      pageChangeRebateReport
    }, dispatch)
)(RebateReportTable)
