import { INIT } from './app.reducer'
import { LOGOUT_ADMIN } from './auth.reducer'

export const INIT_PENDING_WITHDRAWAL = '[TRANSACTION] Init Pending Withdrawal'
export const APPEND_PENDING_WITHDRAWAL = '[TRANSACTION] Append Pending Withdrawal'

export const INIT_PROCESSING_WITHDRAWAL = '[TRANSACTION] Init Processing Withdrawal'
export const APPEND_PROCESSING_WITHDRAWAL = '[TRANSACTION] Append Processing Withdrawal'

export const APPEND_APPROVED_WITHDRAWAL = '[TRANSACTION] Append Approved Withdrawal'
export const INIT_APPROVED_WITHDRAWAL = '[TRANSACTION] Initialize Approved Withdrawal'

export const APPEND_TRANSACTION_HISTORY = '[TRANSACTION] Append Transaction History'
export const INIT_TRANSACTION_HISTORY = '[TRANSACTION] Initialize Transaction History'

export const INIT_REJECTED_WITHDRAWAL = '[TRANSACTION] Initialize Rejected Withdrawal'
export const APPEND_REJECTED_WITHDRAWAL = '[TRANSACTION] Append Rejected Withdrawal'

export const APPROVE_WITHDRAWAL = '[TRANSACTION] Approve Withdrawal'
export const REVERSE_WITHDRAWAL = '[TRANSACTION] Reverse Withdrawal'
export const REJECT_WITHDRAWAL = '[TRANSACTION] Reject Withdrawal'
export const SUCCESS_WITHDRAWAL = '[TRANSACTION] Success Withdrawal'

export const SET_REJECT_WITHDRAWAL_ID = '[TRANSACTION] Set Reject Withdrawal Id'
export const INIT_PENDING_CASH_DEPOSIT = '[TRANSACTION] Init Pending Cash Deposit'
export const APPEND_PENDING_CASH_DEPOSIT = '[TRANSACTION] Append Pending Cash Deposit'
export const INIT_APPROVED_CASH_DEPOSIT = '[TRANSACTION] Initialize Approved Cash Deposit'
export const APPEND_APPROVED_CASH_DEPOSIT = '[TRANSACTION] Append Approved Cash Deposit'
export const INIT_REJECTED_CASH_DEPOSIT = '[TRANSACTION] Initialize Rejected Cash Deposit'
export const APPEND_REJECTED_CASH_DEPOSIT = '[TRANSACTION] Append Rejected Cash Deposit'
export const APPROVE_CASH_DEPOSIT = '[TRANSACTION] Approve Cash Deposit'
export const REJECT_CASH_DEPOSIT = '[TRANSACTION] Reject Cash Deposit'
export const SET_REJECT_CASH_DEPOSIT_ID = '[TRANSACTION] Set Reject Cash Deposit Id'

export const INIT_ALL_USER_TRANSACTION_HISTORY = '[TRANSACTION] Initialize All User Transaction History'
export const APPEND_ALL_USER_TRANSACTION_HISTORY = '[TRANSACTION] Append All User Transaction History'

export const INIT_USERS_WIN_REPORT= '[TRANSACTION] Initialize Users Win Report'
export const PAGE_CHANGE_USERS_WIN_REPORT= '[TRANSACTION] Page Change Users Win Report'

export const APPEND_ORDER_HISTORY = '[TRANSACTION] Append Order History'
export const INIT_ORDER_HISTORY = '[TRANSACTION] Initialize Order History'

export const SET_DEPOSIT_HISTORY = '[TRANSACTION] Set Deposit History'


const initialState = {
  pendingWithdrawalRequests: {
    currentPage: 1,
    pageSize: 10,
    lastUpdatedPage: 0,
    nextKey: null,
    isLastPage: false,
    data: [],
    requestId: '',
    count: 0,
    view: {
      listDataIndex: null
    }
  },
  processingWithdrawalRequests: {
    currentPage: 1,
    pageSize: 10,
    lastUpdatedPage: 0,
    nextKey: null,
    isLastPage: false,
    data: [],
    requestId: ''
  },
  approvedWithdrawalRequests: {
    currentPage: 1,
    pageSize: 10,
    lastUpdatedPage: 0,
    nextKey: null,
    isLastPage: false,
    startDate: new Date(),
    endDate: new Date(),
    data: [],
  },
  rejectedWithdrawalRequests: {
    currentPage: 1,
    pageSize: 10,
    lastUpdatedPage: 0,
    nextKey: null,
    isLastPage: false,
    startDate: new Date(),
    endDate: new Date(),
    data: [],
  },
  pendingCashDepositRequests: {
    currentPage: 1,
    pageSize: 10,
    lastUpdatedPage: 0,
    nextKey: null,
    isLastPage: false,
    data: [],
    requestId: '',
    count: 0
  },
  approvedCashDepositRequests: {
    currentPage: 1,
    pageSize: 10,
    lastUpdatedPage: 0,
    nextKey: null,
    isLastPage: false,
    startDate: new Date(),
    endDate: new Date(),
    data: [],
  },
  rejectedCashDepositRequests: {
    currentPage: 1,
    pageSize: 10,
    lastUpdatedPage: 0,
    nextKey: null,
    isLastPage: false,
    startDate: new Date(),
    endDate: new Date(),
    data: [],
  },
  history: {
    currentPage: 1,
    pageSize: 10,
    lastUpdatedPage: 0,
    nextKey: null,
    isLastPage: false,
    playerId: null,
    startDate: new Date(),
    endDate: new Date(),
    data: [],
  },
  orderHistory: {
    currentPage: 1,
    pageSize: 10,
    lastUpdatedPage: 0,
    nextKey: null,
    isLastPage: false,
    playerId: null,
    startDate: new Date(),
    endDate: new Date(),
    data: [],
  },
  allUserHistory: {
    currentPage: 1,
    pageSize: 20,
    lastUpdatedPage: 0,
    nextKey: null,
    isLastPage: false,
    startDate: new Date(),
    endDate: new Date(),
    data: [],
    filter: {},
    total: {}
  },
  usersWinReport: {
    currentPage: 1,
    pageSize: 10,
    lastUpdatedPage: 0,
    isLastPage: false,
    startDate: new Date(),
    endDate: new Date(),
    winAmount: 0,
    data: [],
    maxPage: 1,
  },
  depositHistory: {
    data: null
  }
}

export default function (state = initialState, { payload = {}, type , transaction: init }) {
  const {
    data = [],
    winAmount,
    total = {},
    nextKey,
    lastUpdatedPage,
    currentPage,
    playerId,
    startDate,
    endDate,
    requestId,
    filter = {}
  } = payload

  switch (type) {

    case SET_DEPOSIT_HISTORY: {
      const { data } = payload

      return {
        ...state,
        depositHistory: {
          data
        }
      }
    }
    case INIT_PENDING_WITHDRAWAL: {
      const {
        data: initialData,
        lastUpdatedPage: initialLastUpdatedPage,
        currentPage: initialCurrentPage,
        nextKey: initialNextKey,
      } = initialState.pendingWithdrawalRequests

      return {
        ...state,
        pendingWithdrawalRequests: {
          ...initialState.pendingWithdrawalRequests,
          data: data ? data : initialData,
          count:  data ? data.length : initialData.length,
          nextKey: nextKey ? nextKey : initialNextKey,
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : initialLastUpdatedPage,
          currentPage: currentPage ? currentPage : initialCurrentPage,
          isLastPage: !nextKey
        }
      }
    }

    case APPEND_PENDING_WITHDRAWAL: {
      const {
        data: stateData,
        lastUpdatedPage: stateLastUpdatedPage,
        currentPage: stateCurrentPage
      } = state.pendingWithdrawalRequests

      const CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED
        = !nextKey && (currentPage || stateCurrentPage) === (lastUpdatedPage || stateLastUpdatedPage)
      const newData = [ ...stateData, ...data ];
      return {
        ...state,
        pendingWithdrawalRequests: {
          ...state.pendingWithdrawalRequests,
          data: newData,
          count: newData.length,
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : stateLastUpdatedPage,
          currentPage: currentPage ? currentPage : stateCurrentPage,
          isLastPage: CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED,
          nextKey
        }
      }
    }

    case INIT_PROCESSING_WITHDRAWAL: {
      const {
        data: initialData,
        lastUpdatedPage: initialLastUpdatedPage,
        currentPage: initialCurrentPage,
        nextKey: initialNextKey,
      } = initialState.processingWithdrawalRequests

      return {
        ...state,
        processingWithdrawalRequests: {
          ...initialState.processingWithdrawalRequests,
          data: data ? data : initialData,
          nextKey: nextKey ? nextKey : initialNextKey,
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : initialLastUpdatedPage,
          currentPage: currentPage ? currentPage : initialCurrentPage,
          isLastPage: !nextKey
        }
      }
    }

    case APPEND_PROCESSING_WITHDRAWAL: {
      const {
        data: stateData,
        lastUpdatedPage: stateLastUpdatedPage,
        currentPage: stateCurrentPage
      } = state.processingWithdrawalRequests

      const CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED
        = !nextKey && (currentPage || stateCurrentPage) === (lastUpdatedPage || stateLastUpdatedPage)

      return {
        ...state,
        processingWithdrawalRequests: {
          ...state.processingWithdrawalRequests,
          data: [ ...stateData, ...data ],
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : stateLastUpdatedPage,
          currentPage: currentPage ? currentPage : stateCurrentPage,
          isLastPage: CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED,
          nextKey
        }
      }
    }

    case APPEND_APPROVED_WITHDRAWAL: {
      const {
        data: stateData,
        lastUpdatedPage: stateLastUpdatedPage,
        currentPage: stateCurrentPage
      } = state.approvedWithdrawalRequests

      const CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED
        = !nextKey && (currentPage || stateCurrentPage) === (lastUpdatedPage || stateLastUpdatedPage)

      return {
        ...state,
        approvedWithdrawalRequests: {
          ...state.approvedWithdrawalRequests,
          data: [ ...stateData, ...data ],
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : stateLastUpdatedPage,
          currentPage: currentPage ? currentPage : stateCurrentPage,
          isLastPage: CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED,
          nextKey
        }
      }
    }

    case INIT_APPROVED_WITHDRAWAL: {
      const {
        data: initialData,
        lastUpdatedPage: initialLastUpdatedPage,
        currentPage: initialCurrentPage,
        nextKey: initialNextKey,
        startDate: initialStartDate,
        endDate: initialEndDate
      } = initialState.approvedWithdrawalRequests

      return {
        ...state,
        approvedWithdrawalRequests: {
          ...initialState.approvedWithdrawalRequests,
          data: data ? data : initialData,
          nextKey: nextKey ? nextKey : initialNextKey,
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : initialLastUpdatedPage,
          currentPage: currentPage ? currentPage : initialCurrentPage,
          startDate: startDate ? startDate : initialStartDate,
          endDate: endDate ? endDate : initialEndDate,
          isLastPage: !nextKey
        }
      }
    }

    case APPEND_REJECTED_WITHDRAWAL: {
      const {
        data: stateData,
        lastUpdatedPage: stateLastUpdatedPage,
        currentPage: stateCurrentPage
      } = state.rejectedWithdrawalRequests

      const CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED
        = !nextKey && (currentPage || stateCurrentPage) === (lastUpdatedPage || stateLastUpdatedPage)

      return {
        ...state,
        rejectedWithdrawalRequests: {
          ...state.rejectedWithdrawalRequests,
          data: [ ...stateData, ...data ],
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : stateLastUpdatedPage,
          currentPage: currentPage ? currentPage : stateCurrentPage,
          isLastPage: CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED,
          nextKey
        }
      }
    }

    case INIT_REJECTED_WITHDRAWAL: {
      const {
        data: initialData,
        lastUpdatedPage: initialLastUpdatedPage,
        currentPage: initialCurrentPage,
        nextKey: initialNextKey,
        startDate: initialStartDate,
        endDate: initialEndDate
      } = initialState.rejectedWithdrawalRequests

      return {
        ...state,
        rejectedWithdrawalRequests: {
          ...initialState.rejectedWithdrawalRequests,
          data: data ? data : initialData,
          nextKey: nextKey ? nextKey : initialNextKey,
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : initialLastUpdatedPage,
          currentPage: currentPage ? currentPage : initialCurrentPage,
          startDate: startDate ? startDate : initialStartDate,
          endDate: endDate ? endDate : initialEndDate,
          isLastPage: !nextKey
        }
      }
    }

    case SET_REJECT_WITHDRAWAL_ID: {
      return {
        ...state,
        pendingWithdrawalRequests:{
          ...state.pendingWithdrawalRequests,
          requestId
        }
      }
    }

    case APPEND_TRANSACTION_HISTORY: {
      const {
        data: stateData,
        lastUpdatedPage: stateLastUpdatedPage,
        currentPage: stateCurrentPage
      } = state.history

      const CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED
        = !nextKey && (currentPage || stateCurrentPage) === (lastUpdatedPage || stateLastUpdatedPage)

      return {
        ...state,
        history: {
          ...state.history,
          data: [ ...stateData, ...data ],
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : stateLastUpdatedPage,
          currentPage: currentPage ? currentPage : stateCurrentPage,
          isLastPage: CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED,
          nextKey
        }
      }
    }

    case INIT_TRANSACTION_HISTORY: {
      const {
        data: initialData,
        lastUpdatedPage: initialLastUpdatedPage,
        currentPage: initialCurrentPage,
        nextKey: initialNextKey,
        playerId: initialPlayerId,
        startDate: initialStartDate,
        endDate: initialEndDate
      } = initialState.history

      return {
        ...state,
        history: {
          ...initialState.history,
          data: data ? data : initialData,
          nextKey: nextKey ? nextKey : initialNextKey,
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : initialLastUpdatedPage,
          currentPage: currentPage ? currentPage :initialCurrentPage,
          playerId: playerId ? playerId : initialPlayerId,
          startDate: startDate ? startDate : initialStartDate,
          endDate: endDate ? endDate : initialEndDate,
          isLastPage: !nextKey
        }
      }
    }

    case APPEND_ORDER_HISTORY: {
      const {
        data: stateData,
        lastUpdatedPage: stateLastUpdatedPage,
        currentPage: stateCurrentPage
      } = state.orderHistory

      const CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED
        = !nextKey && (currentPage || stateCurrentPage) === (lastUpdatedPage || stateLastUpdatedPage)

      return {
        ...state,
        orderHistory: {
          ...state.orderHistory,
          data: [ ...stateData, ...data ],
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : stateLastUpdatedPage,
          currentPage: currentPage ? currentPage : stateCurrentPage,
          isLastPage: CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED,
          nextKey
        }
      }
    }

    case INIT_ORDER_HISTORY: {
      const {
        data: initialData,
        lastUpdatedPage: initialLastUpdatedPage,
        currentPage: initialCurrentPage,
        nextKey: initialNextKey,
        playerId: initialPlayerId,
        startDate: initialStartDate,
        endDate: initialEndDate
      } = initialState.orderHistory

      return {
        ...state,
        orderHistory: {
          ...initialState.orderHistory,
          data: data ? data : initialData,
          nextKey: nextKey ? nextKey : initialNextKey,
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : initialLastUpdatedPage,
          currentPage: currentPage ? currentPage :initialCurrentPage,
          playerId: playerId ? playerId : initialPlayerId,
          startDate: startDate ? startDate : initialStartDate,
          endDate: endDate ? endDate : initialEndDate,
          isLastPage: !nextKey
        }
      }
    }

    case APPROVE_WITHDRAWAL: {
      return {
        ...state,
        pendingWithdrawalRequests: {
          ...state.pendingWithdrawalRequests,
          data
        }
      }
    }

    case REVERSE_WITHDRAWAL: {
      return {
        ...state,
        pendingWithdrawalRequests: {
          ...state.pendingWithdrawalRequests,
          data
        }
      }
    }

    case SUCCESS_WITHDRAWAL: {
      return {
        ...state,
        processingWithdrawalRequests: {
          ...state.processingWithdrawalRequests,
          data
        }
      }
    }

    case REJECT_WITHDRAWAL: {
      return {
        ...state,
        pendingWithdrawalRequests: {
          ...state.pendingWithdrawalRequests,
          data
        }
      }
    }

    case SET_REJECT_CASH_DEPOSIT_ID: {
      return {
        ...state,
        pendingCashDepositRequests:{
          ...state.pendingCashDepositRequests,
          requestId
        }
      }
    }

    case INIT_PENDING_CASH_DEPOSIT: {
      const {
        data: initialData,
        lastUpdatedPage: initialLastUpdatedPage,
        currentPage: initialCurrentPage,
        nextKey: initialNextKey,
      } = initialState.pendingCashDepositRequests

      return {
        ...state,
        pendingCashDepositRequests: {
          ...initialState.pendingCashDepositRequests,
          data: data ? data : initialData,
          count: data ? data.length : initialData.length,
          nextKey: nextKey ? nextKey : initialNextKey,
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : initialLastUpdatedPage,
          currentPage: currentPage ? currentPage : initialCurrentPage,
          isLastPage: !nextKey
        }
      }
    }

    case APPEND_PENDING_CASH_DEPOSIT: {
      const {
        data: stateData,
        lastUpdatedPage: stateLastUpdatedPage,
        currentPage: stateCurrentPage
      } = state.pendingCashDepositRequests

      const CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED
        = !nextKey && (currentPage || stateCurrentPage) === (lastUpdatedPage || stateLastUpdatedPage)
      const newData = [ ...stateData, ...data ];
      return {
        ...state,
        pendingCashDepositRequests: {
          ...state.pendingCashDepositRequests,
          data: newData,
          count: newData.length,
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : stateLastUpdatedPage,
          currentPage: currentPage ? currentPage : stateCurrentPage,
          isLastPage: CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED,
          nextKey
        }
      }
    }

    case INIT_APPROVED_CASH_DEPOSIT: {
      const {
        data: initialData,
        lastUpdatedPage: initialLastUpdatedPage,
        currentPage: initialCurrentPage,
        nextKey: initialNextKey,
        startDate: initialStartDate,
        endDate: initialEndDate
      } = initialState.approvedCashDepositRequests

      return {
        ...state,
        approvedCashDepositRequests: {
          ...initialState.approvedCashDepositRequests,
          data: data ? data : initialData,
          nextKey: nextKey ? nextKey : initialNextKey,
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : initialLastUpdatedPage,
          currentPage: currentPage ? currentPage : initialCurrentPage,
          startDate: startDate ? startDate : initialStartDate,
          endDate: endDate ? endDate : initialEndDate,
          isLastPage: !nextKey
        }
      }
    }

    case APPEND_APPROVED_CASH_DEPOSIT: {
      const {
        data: stateData,
        lastUpdatedPage: stateLastUpdatedPage,
        currentPage: stateCurrentPage
      } = state.approvedCashDepositRequests

      const CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED
        = !nextKey && (currentPage || stateCurrentPage) === (lastUpdatedPage || stateLastUpdatedPage)

      return {
        ...state,
        approvedCashDepositRequests: {
          ...state.approvedCashDepositRequests,
          data: [ ...stateData, ...data ],
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : stateLastUpdatedPage,
          currentPage: currentPage ? currentPage : stateCurrentPage,
          isLastPage: CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED,
          nextKey
        }
      }
    }

    case INIT_REJECTED_CASH_DEPOSIT: {
      const {
        data: initialData,
        lastUpdatedPage: initialLastUpdatedPage,
        currentPage: initialCurrentPage,
        nextKey: initialNextKey,
        startDate: initialStartDate,
        endDate: initialEndDate
      } = initialState.rejectedCashDepositRequests

      return {
        ...state,
        rejectedCashDepositRequests: {
          ...initialState.rejectedCashDepositRequests,
          data: data ? data : initialData,
          nextKey: nextKey ? nextKey : initialNextKey,
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : initialLastUpdatedPage,
          currentPage: currentPage ? currentPage : initialCurrentPage,
          startDate: startDate ? startDate : initialStartDate,
          endDate: endDate ? endDate : initialEndDate,
          isLastPage: !nextKey
        }
      }
    }

    case APPEND_REJECTED_CASH_DEPOSIT: {
      const {
        data: stateData,
        lastUpdatedPage: stateLastUpdatedPage,
        currentPage: stateCurrentPage
      } = state.rejectedCashDepositRequests

      const CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED
        = !nextKey && (currentPage || stateCurrentPage) === (lastUpdatedPage || stateLastUpdatedPage)

      return {
        ...state,
        rejectedCashDepositRequests: {
          ...state.rejectedCashDepositRequests,
          data: [ ...stateData, ...data ],
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : stateLastUpdatedPage,
          currentPage: currentPage ? currentPage : stateCurrentPage,
          isLastPage: CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED,
          nextKey
        }
      }
    }

    case APPROVE_CASH_DEPOSIT: {
      return {
        ...state,
        pendingCashDepositRequests: {
          ...state.pendingCashDepositRequests,
          data
        }
      }
    }

    case REJECT_CASH_DEPOSIT: {
      return {
        ...state,
        pendingCashDepositRequests: {
          ...state.pendingCashDepositRequests,
          data
        }
      }
    }

    case INIT_ALL_USER_TRANSACTION_HISTORY:{
      const {
        data: initialData,
        total: initialTotal,
        lastUpdatedPage: initialLastUpdatedPage,
        currentPage: initialCurrentPage,
        nextKey: initialNextKey,
        startDate: initialStartDate,
        endDate: initialEndDate,
        filter: initialFilter
      } = initialState.allUserHistory

      return {
        ...state,
        allUserHistory: {
          ...initialState.allUserHistory,
          data: data ? data : initialData,
          total: total ? total : initialTotal,
          nextKey: nextKey ? nextKey : initialNextKey,
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : initialLastUpdatedPage,
          currentPage: currentPage ? currentPage : initialCurrentPage,
          startDate: startDate ? startDate : initialStartDate,
          endDate: endDate ? endDate : initialEndDate,
          filter: filter ? filter : initialFilter,
          isLastPage: !nextKey
        }
      }
    }

    case APPEND_ALL_USER_TRANSACTION_HISTORY: {
      const {
        data: stateData,
        lastUpdatedPage: stateLastUpdatedPage,
        currentPage: stateCurrentPage
      } = state.allUserHistory

      const CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED
        = !nextKey && (currentPage || stateCurrentPage) === (lastUpdatedPage || stateLastUpdatedPage)

      return {
        ...state,
        allUserHistory: {
          ...state.allUserHistory,
          data: [ ...stateData, ...data ],
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : stateLastUpdatedPage,
          currentPage: currentPage ? currentPage : stateCurrentPage,
          isLastPage: CURRENT_PAGE_IS_LAST_UPDATED_PAGE_AND_LAST_DATA_LOADED,
          nextKey
        }
      }
    }

    case INIT_USERS_WIN_REPORT:{
      const {
        data: initialData,
        winAmount: initialWinAmount,
        lastUpdatedPage: initialLastUpdatedPage,
        currentPage: initialCurrentPage,
        startDate: initialStartDate,
        endDate: initialEndDate,
        pageSize
      } = initialState.usersWinReport

      const maxPage = Math.ceil(data.length / pageSize);

      return {
        ...state,
        usersWinReport: {
          ...initialState.usersWinReport,
          lastUpdatedPage: lastUpdatedPage ? lastUpdatedPage : initialLastUpdatedPage,
          currentPage: currentPage ? currentPage : initialCurrentPage,
          data: data ? data : initialData,
          winAmount: winAmount? winAmount : initialWinAmount,
          startDate: startDate ? startDate : initialStartDate,
          endDate: endDate ? endDate : initialEndDate,
          maxPage,
          isLastPage: initialCurrentPage >= maxPage
        }
      }
    }

    case PAGE_CHANGE_USERS_WIN_REPORT: {

      const { maxPage } = state.usersWinReport;
      return {
        ...state,
        usersWinReport: {
          ...state.usersWinReport,
          lastUpdatedPage: lastUpdatedPage,
          currentPage: currentPage,
          isLastPage: currentPage >= maxPage
       
        }
      }
    }

    case LOGOUT_ADMIN:
      return initialState

    case INIT: {
      return {
        ...state,
        ...init
      }
    }

    default:
      return state
  }
}