import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Helmet } from 'react-helmet'
import { BankBalanceReportTable } from '@components/Tables'
import { Page, Breadcrumb, Crumb} from '@framework'

import './Overview.scss'

class WorkspaceOverviewPage extends Component {
  render () {
    return (
      <Page fluid paperWhite className='scope-workspace-overview'>
        <Helmet title='OPS: Workspace' />
        <Breadcrumb>
          <Crumb>Home</Crumb>
          <Crumb>Dashboard</Crumb>
        </Breadcrumb>
      <BankBalanceReportTable/>
      </Page>
    )
  }
}

function mapStateToProps (state) {
  return {
    state
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceOverviewPage)
